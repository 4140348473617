import React, { FC } from 'react'
import styles from './OrderPadForm.module.scss'
import { Button, Icon, IconButton } from '@mui/material'
import OrderPadInput from '../../../_orderPad/orderPadInput/OrderPadInput';
import ModalLayout from '../../../../components/ui/modalLayout/ModalLayout';
export interface IOrderPadFormProps {
    //propertys
    onClose?: () => void;
    onSubmit: (tag: string) => void;

}
const OrderPadForm: FC<IOrderPadFormProps> = ({ onClose, onSubmit }) => {
    return (
        <ModalLayout onClose={onClose} title={"Comanda"}>

            <OrderPadInput
                onSubmitHandle={onSubmit}
            />
        </ModalLayout>

    )
}
export default OrderPadForm