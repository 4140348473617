import React, { FC } from 'react'
import styles from './LoadingProvider.module.scss'
import useUiStore from '../../stores/UiStore';
import { CircularProgress } from '@mui/material';
export interface ILoadingProviderProps {
    //propertys
}
const LoadingProvider: FC<ILoadingProviderProps> = () => {
    const { loadCount } = useUiStore();
    return (
        loadCount.length > 0 ? (
            <div className={styles.backdrop}>
                <div className={styles.loadingContainer}>
                    <CircularProgress></CircularProgress>
                    <div>{loadCount[0].message}</div>
                </div>
            </div>
        ) : null
    )
}
export default LoadingProvider