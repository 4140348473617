import React, { FC, useCallback, useState } from 'react';
import styles from './Calculator.module.scss';
import { Button, Icon, IconButton } from '@mui/material';

export interface ICalculatorProps {
    // propriedades
    defaultValue?: number;
    totalValue?: number;
    onSubmitValue: (value: number) => void;
    onClose: () => void;
}

const Calculator: FC<ICalculatorProps> = ({ defaultValue, onSubmitValue, onClose, totalValue }) => {
    const [result, setResult] = useState<number>(0);
    const [input, setInput] = useState<string>(defaultValue?.toFixed(2) ?? '');

    const handleNumberClick = (number: number) => {
        setInput((prevInput) => prevInput + number.toString());
    };

    const handleDecimalClick = () => {
        setInput((prevInput) => prevInput + '.');
    };

    const handleOperatorClick = (operator: string) => {
        setInput((prevInput) => prevInput + operator);
    };

    const handleEqualClick = () => {
        try {
            const calculatedResult = eval(input);
            setResult(calculatedResult);
            setInput(calculatedResult.toFixed(2));
        } catch (error) {
            setResult(0);
            setInput('');
        }
    };

    const handleClearClick = () => {
        setResult(0);
        setInput('');
    };

    const onClickDefaultValue = useCallback((value: number) => {
        setInput(value?.toFixed(2) ?? '');
    }, [defaultValue])
    const onClickShortcutsValue = useCallback((value: number) => {
        if (defaultValue) {
            setInput((defaultValue / value)?.toFixed(2) ?? '');
        }
    }, [defaultValue])

    const onSubmitValueHandle = useCallback(() => {
        onSubmitValue(result);
    }, [onSubmitValue, result])

    return (
        <div id={styles.Calculator}>
            <div className={styles.container}>
                <h2>Calculadora</h2>
                <IconButton className={styles.close} onClick={onClose} ><Icon>close</Icon></IconButton>
                <div className={styles.input}>{input}</div>
                {/* <div className={styles.result}>{result}</div> */}
                <div className={styles.shortcuts}>
                    <Button variant='outlined' color='primary' onClick={() => handleClearClick()}>C</Button>
                    {defaultValue && <Button variant='outlined' color='primary' onClick={() => onClickDefaultValue(defaultValue)}>{defaultValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Button>}
                    {totalValue && totalValue !== defaultValue && <Button variant='outlined' color='primary' onClick={() => onClickDefaultValue(totalValue)}>{totalValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Button>}
                </div>
                {/* <div className={styles.shortcuts}>
                    <Icon>safety_divider</Icon>
                    <Button variant='outlined' color='primary' onClick={() => onClickShortcutsValue(2)}>2</Button>
                    <Button variant='outlined' color='primary' onClick={() => onClickShortcutsValue(3)}>3</Button>
                    <Button variant='outlined' color='primary' onClick={() => onClickShortcutsValue(4)}>4</Button>
                    <Button variant='outlined' color='primary' onClick={() => onClickShortcutsValue(5)}>5</Button>
                </div> */}
                <div className={styles.buttons}>
                    <Button className={styles.number} onClick={() => handleNumberClick(1)}>1</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(2)}>2</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(3)}>3</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleOperatorClick('+')}>+</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(4)}>4</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(5)}>5</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(6)}>6</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleOperatorClick('-')}>-</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(7)}>7</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(8)}>8</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(9)}>9</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleOperatorClick('*')}>*</Button>
                    <Button className={styles.number} onClick={() => handleNumberClick(0)}>0</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleDecimalClick()}>.</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleEqualClick()}>=</Button>
                    <Button variant='outlined' color='primary' onClick={() => handleOperatorClick('/')}>/</Button>
                </div>{
                    defaultValue && result <= defaultValue && result > 0 &&

                    <Button variant='contained' color='primary' onClick={onSubmitValueHandle}>Copiar {result.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Button>
                }
            </div>
        </div>
    );
};

export default Calculator;
