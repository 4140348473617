import { useCallback, useRef, useState } from 'react'
import { IOperator } from '../domains/models/Operator';
import useOperatorStore from '../store/OperatorStore';
import UseDeviceStore from '../../device/store/DeviceStore';
import { OperatorService } from '../../../services/api/operator/OperatorService';


export interface ICreateRefferedPromise<T> {
    promise?: Promise<T>;
    resolver?: ((value: T) => void) | null;
    rejecter?: ((error: any) => void) | null;
}
export interface IValidateOperatorResponse {
    operator: IOperator;
    validated: boolean;
    message: string;
}
const UseValidateOperator = () => {
    const loginOperator = useOperatorStore(state => state.loginOperator);
    const [validateModalOpen, setValidateModalOpen] = useState(false);
    const validateOperatorPromise = useRef<ICreateRefferedPromise<IValidateOperatorResponse>>();
    const [error, setError] = useState<string>();
    const [device] = UseDeviceStore(state => [state.device]);

    const onPressCloseValidateModal = useCallback(() => {
        // validateOperatorPromise.current?.rejecter?.('Closed Operator Modal');
        setError(undefined);
        setValidateModalOpen(false);
    }, []);

    const onOnSubmitPressValidate = useCallback(
        async (password: string) => {
            if (!device)
                return;
            try {
                setError(undefined);
                const service = OperatorService();
                const _operator = await loginOperator(service, device.localId, password);
                if (_operator) {
                    setValidateModalOpen(false);
                    validateOperatorPromise.current?.resolver?.({ operator: _operator, validated: true, message: 'Validado com sucesso' });
                } else {
                    // toast('Operador ou senha inválidos');
                    setError('Operador ou senha inválidos');
                }
            } catch (error) {
                validateOperatorPromise.current?.rejecter?.(error);
                onPressCloseValidateModal();
            }
        },
        [device, loginOperator, onPressCloseValidateModal],
    );


    const validateOperator = useCallback(async () => {
        setValidateModalOpen(true);
        setError(undefined);
        const promise = new Promise<IValidateOperatorResponse>((_resolve, _reject) => {
            validateOperatorPromise.current = { ...validateOperatorPromise.current, rejecter: _reject };
            validateOperatorPromise.current = { ...validateOperatorPromise.current, resolver: _resolve };
        });
        validateOperatorPromise.current = { ...validateOperatorPromise.current, promise: promise };
        return promise;
    }, []);


    return ({ validateOperator, validateModalOpen, onOnSubmitPressValidate, onPressCloseValidateModal, error })
}

export default UseValidateOperator