import { useCallback } from 'react'
import { ICustomerFormValue } from '../../../pages/TableMap/components/customerForm/ICustomerFormValue'
import ConsumeApi from '../../../services/api/consume/ConsumeApi'
import OpenAccountUseCase from '../application/useCases/sharedOrderPad/OpenAccountUseCase'
import { ITable } from '../domain/models/ITables'
import { v4 } from 'uuid'
import { IExtract } from '../domain/models/Extract'
import ValidateAccountPaymentUseCase from '../application/useCases/sharedOrderPad/ValidateAccountPaymentUseCase'
import { useUi } from '../../ui/presentation/context/UiContext'
import { CloseAccountUseCase } from '../application/useCases/sharedOrderPad/CloseAccountUseCase'
import { PaymentType } from '../domain/models/PaymentType'
import PayAccountUseCase from '../application/useCases/sharedOrderPad/PayAccountUseCase'
import useOperatorStore from '../../operator/store/OperatorStore'
import UseDeviceStore from '../../device/store/DeviceStore'
import { ICancelOrderItemSelectable } from '../../../pages/catalog/components/cancelOrderForm/CancelOrderForm'
import CancelAccountItemsUseCase from '../application/useCases/consume/CancelAccountItemsUseCase'
import UseSessionStore from '../../session/store/SessionStore'
import { useShallow } from 'zustand/react/shallow'
import { hideLoading, showLoading } from '../../ui/stores/UiStore'
import OrderLogRepository from '../../../services/db/OrderRepository'
import AddOrderLogUseCase from '../../orderLog/application/AddOrderLogUseCase'
const UseAccount = () => {

    const session = UseSessionStore(useShallow(state => state.session));
    const [device] = UseDeviceStore(state => [state.device]);
    const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));
    const { toast } = useUi();

    const openAccount = useCallback(async (table: ITable, operatorId: string, values?: ICustomerFormValue) => {
        if (session === undefined) return;
        if (device === undefined) return;

        try {
            const service = ConsumeApi();
            showLoading('@account/openAccount', 'Abrindo conta');
            await OpenAccountUseCase(service, {
                user: values,
                table,
                session: session,
                operatorId,
                groupId: v4(),
                device: device
            });
        } catch (error: any) {
            if (error.data.message.includes("conta aberta para este documento")) {
                return toast('Documento já está sendo usado em outra conta', 'error');
            }
            toast('Erro ao abrir conta', 'error');
        } finally {
            hideLoading('@account/openAccount');
        }
    }, [session, device, toast])


    const payAccount = useCallback(async (paymentType: PaymentType, extract: IExtract, amount: number, payServceFee?: boolean) => {
        try {
            showLoading('@onClickPayAccountButtonHandle', 'Pagando conta');
            if (!session) {
                toast('Sessão não encontrada', 'error');
                throw new Error('Sessão não encontrada');
            }
            if (!device) {
                toast('Dispositivo não encontrado', 'error');
                throw new Error('Dispositivo não encontrado');
            }
            if (!currentOperator) {
                toast('Operador não encontrado', 'error');
                throw new Error('Operador não encontrado');

            }
            if (!extract) {
                toast('Extrato não encontrado', 'error');
                throw new Error('Extrato não encontrado');
            }

            const service = ConsumeApi();
            await ValidateAccountPaymentUseCase(service, {
                localId: device.localId,
                operatorId: currentOperator.id,
                amount: amount,
                extract: extract,
                deviceId: device.id,
                serviceFeeEnabled: payServceFee,
            })
            const response = await PayAccountUseCase(service, {
                session: session,
                device: device,
                extract: extract,
                operator: currentOperator,
                paymentId: v4(),
                orderId: v4(),
                paymentType: paymentType,
                amount: amount,
                serviceFeeEnabled: payServceFee,
            })

            await AddOrderLogUseCase(new OrderLogRepository(), {
                id: response.orderId,
                orderItems: [],
            }, currentOperator, session);

            return response;

        } catch (error) {
            throw new Error('Erro ao pagar conta');
        } finally {
            hideLoading('@onClickPayAccountButtonHandle');
        }

    }, [session, device, currentOperator, toast])


    const closeAccount = useCallback(async (extract?: IExtract) => {
        if (!session) {

            throw new Error('Sessão não encontrada');
        }
        if (!device) {
            throw new Error('Dispositivo não encontrado');
        }
        if (!currentOperator) {
            throw new Error('Operador não encontrado');
        }
        if (!extract) {
            throw new Error('Extrato não encontrado');
        }
        if (extract.totalToPayWithoutFee > 0) {
            throw new Error('Não é possível fechar uma conta com valor pendente');
        }
        if (!extract) {
            throw new Error('Extrato não encontrado');
        }
        try {
            const service = ConsumeApi();
            await CloseAccountUseCase(service, {
                session: session,
                device: device,
                extract: extract,
                operator: currentOperator,
            })
            // fullScreenToast('Conta fechada com sucesso!', 'success');
            // onClickBackHandle();
        } catch (error) {
            throw new Error('Erro ao fechar conta');
        }
    }, [currentOperator, session, device])



    const cancelOrderItems = useCallback(async (orders: ICancelOrderItemSelectable[], annotation: string, extract?: IExtract) => {
        try {
            const service = ConsumeApi();
            if (device === undefined) throw new Error('DeviceId não encontrado');
            if (currentOperator === undefined) throw new Error('Operador não encontrado');
            if (extract === undefined) throw new Error('Extrato não encontrado');

            const response = await CancelAccountItemsUseCase(service, {
                operator: currentOperator,
                cancelOrder: orders,
                accountId: extract.accountId,
                device: device,
                annotation: annotation
            })

            return response;
        } catch (error) {
            throw new Error('Erro ao cancelar itens da conta');
        }
    }, [device, currentOperator])

    return ({ openAccount, payAccount, closeAccount, cancelOrderItems })
}

export default UseAccount


