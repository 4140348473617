import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './SplitPaymentOrderForm.module.scss'
import { IExtract, OrderItemExtract } from '../../../../modules/consume/domain/models/Extract';
import SplitPaymentOrderItemForm from './SplitPaymentOrderItemForm';
import ModalLayout from '../../../../components/ui/modalLayout/ModalLayout';
import { Button, FormControl, FormControlLabel, Icon, IconButton, Switch } from '@mui/material';
import { moneyFormater } from '../../../../services/utils/Money';


export interface ISplitPaymenItemSelectable extends OrderItemExtract {
    isChecked: boolean;
    quantityToPay: number;
}

export interface ISplitPaymentOrderFormProps {
    extract: IExtract;
    onClose: () => void;
    onSubmit: (value: number) => void;
    maxAmoutToPay: number;
}

const SplitPaymentOrderForm: FC<ISplitPaymentOrderFormProps> = ({ extract, onClose, onSubmit, maxAmoutToPay }) => {
    const [orderItems, setOrderItems] = useState<ISplitPaymenItemSelectable[]>();
    const [divisor, setDivisor] = useState<number>(1);

    const total = useMemo(() => orderItems?.reduce((acc, item) => acc + (item.quantityToPay * item.totalValue), 0) ?? 0, [orderItems])
    const sortAndUngroup = useCallback((_extract: IExtract) => {
        const items = _extract.orders.filter(x => !x.isPayment && !x.isPayment).flatMap(order => order.items.map(item => ({ ...item, isChecked: false, quantityToPay: 0 }))).filter(x => !x.isCanceled);
        const sortedItems = items.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
        return sortedItems;
    }, [])


    const groupByProduct = useCallback((extract: IExtract): ISplitPaymenItemSelectable[] => {
        const items = sortAndUngroup(extract);
        const groupedItems = items.reduce((acc, item) => {
            const itemExist = acc.find(i => i.productId === item.productId);
            if (itemExist) {
                itemExist.quantity += item.quantity;
                itemExist.isChecked = false;
                return acc;
            }
            acc.push({ ...item, isChecked: false });
            return acc;
        }, [] as ISplitPaymenItemSelectable[]);

        return groupedItems;
    }, [sortAndUngroup]);

    const groupByProductHandle = useCallback(() => {
        if (extract) {
            setOrderItems(groupByProduct(extract).filter(item => !!item.totalValue));
        }
    }, [extract, groupByProduct]);

    const ungroupByProductHandle = useCallback(() => {
        if (extract) {
            setOrderItems(sortAndUngroup(extract).filter(item => !!item.totalValue));
        }
    }, [extract, sortAndUngroup]);

    const onItemChangeHandle = useCallback((item: ISplitPaymenItemSelectable, checked: boolean, valueToPay: number) => {
        const items = orderItems?.map(i => {
            if (i.id === item.id) {
                return { ...i, isChecked: checked, quantityToPay: valueToPay };
            }
            return i;
        });
        setOrderItems(items);
    }, [orderItems]);


    useEffect(() => {
        // ungroupByProductHandle();
        groupByProductHandle();
    }, [groupByProductHandle]);

    const onChangeSwitchHandle = useCallback((_, checked: boolean) => {
        if (!checked) {
            groupByProductHandle();
            return;
        }
        ungroupByProductHandle();
    }, [groupByProductHandle, ungroupByProductHandle]);


    const onDivisorHandle = useCallback((value: number) => {
        setDivisor(prev => prev + value < 1 ? 1 : prev + value);
    }, []);

    const onSubmmitHandle = useCallback(() => {
        if (!total) {
            return;
        }
        onSubmit(total / divisor);
    }, [onSubmit, total, divisor]);


    return (
        <div id={styles.SplitPaymentOrderForm} >
            <ModalLayout title="Dividir Conta" onClose={onClose} >
                <div className={styles.tags}>
                    <FormControlLabel onChange={onChangeSwitchHandle} control={<Switch />} label={"Separar"} />
                </div>
                <div className={styles.container} >
                    {orderItems?.map(item => <SplitPaymentOrderItemForm
                        key={item.id}
                        item={item}
                        onChange={onItemChangeHandle}
                    />)}
                </div>
                {!!total &&
                    <div className={styles.footer}>
                        <div className={styles.resume}>
                            <div className={styles.subtotal}>
                                <label>Valor restante:</label>
                                <div className={styles.divisor}>
                                    <b>{moneyFormater(maxAmoutToPay)}  </b>
                                </div>
                            </div>
                            <div className={styles.subtotal}>
                                <label>Subtotal:</label>
                                <div className={styles.divisor}>
                                    <b>{moneyFormater(total)} / </b>
                                    <IconButton size='small' onClick={() => onDivisorHandle(- 1)}><Icon>remove</Icon></IconButton>
                                    {divisor}
                                    <IconButton size='small' onClick={() => onDivisorHandle(1)}><Icon>add</Icon></IconButton>
                                </div>
                            </div>
                            {/* <div className={styles.total}>
                            <label>Divisor:</label>

                        </div> */}
                            <div className={styles.total}>
                                <label>Total:</label>
                                <b>{moneyFormater(total / divisor)}</b>
                            </div>
                        </div>
                        {
                            maxAmoutToPay <= (total / divisor) &&
                            <div className={styles.alert} >
                                Valor máximo para dividir: <b>{moneyFormater(maxAmoutToPay)}</b>
                            </div>
                        }
                        <div className={styles.buttons}>
                            <Button onClick={onClose} variant="outlined" >Cancelar</Button>
                            <Button onClick={onSubmmitHandle} disabled={maxAmoutToPay < (total / divisor)} variant="contained" >Adicionar ao valor</Button>
                        </div>
                    </div>}
            </ModalLayout>
        </div>
    )
}
export default SplitPaymentOrderForm