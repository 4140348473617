import {ISharedOrderPadRepository} from '../../../domain/interfaces/sharedOrderPad/ISharedOrderPadRepository';
import { ITableRepository } from '../../../domain/interfaces/tables/ITablesRepository';
import { ITable } from '../../../domain/models/ITables';

const GetLocalTableUseCase = async (
  tableRepository: ITableRepository,
): Promise<ITable[] | null> => {
  const response = await tableRepository.getTables();
  return (response);
};
export default GetLocalTableUseCase;
