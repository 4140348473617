import { ISession } from "../domain/ISession";
import { ISessionService } from "../domain/interfaces/ISessionService"

const GetCurrentSessionUseCase = async (service: ISessionService, localId: string, operatorId: string):Promise<ISession> => {
    const response = service.getCurrentSession({
        localId,
        operatorId
    })
    return (response);
}
export default GetCurrentSessionUseCase