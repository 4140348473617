import zustand, { create } from "zustand";
import { ICloseSession, ISession } from "../domain/ISession";
import { StateStorage, createJSONStorage, persist } from "zustand/middleware";
import EncryptedStorage from "../../../services/localStorage/encryptedStorage/EncryptedStorage";
import { ISessionService } from "../domain/interfaces/ISessionService";
import OpenSessionUseCase from "../application/OpenSessionUseCase";
import LogoutCurrentSessionUseCase from "../application/LogoutCurrentSessionUseCase copy";
import GetCurrentSessionUseCase from "../application/GetCurrentSessionUseCase";
import GetSessionStatusUseCase from "../application/GetSessionStatusUseCase";
import CloseCurrentSessionUseCase from "../application/CloseCurrentSessionUseCase";

interface ISessionStore {
    session?: ISession;
    loadingSession: boolean;
    loadingLogoutSession: boolean;
    hasSession: boolean;
    loadingHasSession: boolean;
    loadingCloseSession: boolean;
    setSession: (session?: ISession) => void;
    openSession: (service: ISessionService, localId: string, operatorId: string) => Promise<ISession>;
    closeSession: (service: ISessionService, localId: string, operatorId: string) => Promise<ICloseSession>;
    logoutSession: (service: ISessionService, localId: string, operatorId: string) => Promise<ICloseSession>;
    loginSession: (service: ISessionService, localId: string, operatorId: string) => Promise<ISession>;
    verifyOpenedSession: (service: ISessionService, localId: string) => Promise<boolean>;
    clearSession: () => void;
}
const UseSessionStore = create<ISessionStore>()(persist(
    (set, get) => ({
        hasSession: false,
        session: undefined,
        setSession: (session?: ISession) => set(store => ({ session })),
        loadingSession: false,
        loadingLogoutSession: false,
        loadingHasSession: false,
        loadingCloseSession: false,
        openSession: async (service: ISessionService, localId: string, operatorId: string) => {
            try {
                set({ loadingSession: true });
                const response = await OpenSessionUseCase(service, localId, operatorId);
                set({ session: response });
                return response;

            } finally {
                set({ loadingSession: false });
            }

        },
        closeSession: async (service: ISessionService, localId: string, operatorId: string) => {
            try {
                set({ loadingCloseSession: true });
                const session = get().session;
                if (session) {
                    const response = await CloseCurrentSessionUseCase(service, {
                        sessionId: session.id,
                        localId: localId,
                        operatorId: operatorId,
                    });
                    set({ session: undefined });
                    set({ hasSession: false });
                    return response
                } else {
                    set({ session: undefined });
                    throw new Error('Sessão não encontrada');
                }
            } finally {
                set({ loadingCloseSession: false, session: undefined});
            }
        },
        verifyOpenedSession: async (service: ISessionService, localId: string) => {
            try {
                set({ loadingHasSession: true });
                const response = await GetSessionStatusUseCase(service, localId);
                set({ hasSession: response });
                return response;
            } finally {
                set({ loadingHasSession: false });
            }
        },
        logoutSession: async (service: ISessionService, localId: string, operatorId: string) => {
            try {
                set({ loadingLogoutSession: true });
                const session = get().session;
                if (session) {
                  const response =  await LogoutCurrentSessionUseCase(service, {
                        sessionId: session.id,
                        operatorId: operatorId,
                        localId: localId,
                        registerSessionId: session.registerSessionId
                    });
                    set({ session: undefined });
                    await get().verifyOpenedSession(service, localId);
                    return response;
                } else {
                    set({ session: undefined });
                    throw new Error('Sessão não encontrada');
                }
            } finally {
                set({ loadingLogoutSession: false });
            }
        },
        loginSession: async (service: ISessionService, localId: string, operatorId: string, disabledOpen?: boolean) => {
            try {
                const hasOpenedSession = await get().verifyOpenedSession(service, localId);
                if (!hasOpenedSession) {
                    if (disabledOpen) {
                        throw new Error('Sessão não encontrada');
                    } else {
                        const response = await get().openSession(service, localId, operatorId);
                        set({ session: response });
                        return response;
                    }
                }
                set({ loadingSession: true });
                const response = await GetCurrentSessionUseCase(service, localId, operatorId);
                set({ session: response });
                return response;
            } finally {
                set({ loadingSession: false });
            }
        },
        clearSession: () => {
            set({ session: undefined });
        }
    }),
    {
        name: "@session-store",
        storage: createJSONStorage<StateStorage>(EncryptedStorage),
    }
));

export default UseSessionStore;