import { IOnBoardService } from "../../../modules/onBoarding/domain/Interfaces/IOnBoardService"
import { IOnBoardingItem, IOnBoardingItemRequest } from "../../../modules/onBoarding/domain/models/IOnBoardingItem";
import { Api } from "../bases/Api";
import { PLANS_API } from "../bases/Constants";

const api = Api();


const OnBoardingService = (): IOnBoardService => {
    const getOnBoarding = async (localId: string): Promise<IOnBoardingItem[]> => {
        const response = await api.get(`${PLANS_API}/api/onboardings/`, { params: { localId } })
        return response.data
    }
    const setOnBoarding = async (onBoarding: IOnBoardingItemRequest): Promise<void> => {
        await api.post(`${PLANS_API}/api/onboardings`, onBoarding)
    }
    const resetOnBoarding = async (localId: string): Promise<void> => {
        await api.delete(`${PLANS_API}/api/onboardings/restart/${localId}`)
    }
    return ({
        getOnBoarding,
        setOnBoarding,
        resetOnBoarding
    })
}
export default OnBoardingService


// GET
//     / api / onboardings

// POST
//     / api / onboardings

// GET
//     / api / onboardings / { onboardingId }

// DELETE
//     / api / onboardings / restart / { localId }