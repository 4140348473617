
import { v4 } from "uuid"
import { ICustomerFormValue as IUserOpenAccountFormValue } from "../../../../../pages/TableMap/components/customerForm/ICustomerFormValue"
import { IDevice } from "../../../../device/interface/Device"
import { IOperator } from "../../../../operator/domains/models/Operator"
import { ISession } from "../../../../session/domain/ISession"
import { OpenAccountRequest, UserAccountRequest } from "../../../domain/dtos/OpenAccountRequest"
import { IConsumeService } from "../../../domain/interfaces/IConsumeService"
import { ITable } from "../../../domain/models/ITables"
import { IOrderPad } from "../../../domain/models/OrderPad"
import { ConsumptionItemRequest, OrderRequest } from "../../../domain/dtos/ConsumeOrderRequest"

const OpenAccountUseCase = async (service: IConsumeService, values: { table: ITable, groupId?: string, user?: IUserOpenAccountFormValue, operatorId: string, session: ISession, device: IDevice }) => {
    const [day, month, year]: number[] = values.user?.birthDate ? values.user.birthDate.split('/').map(Number) : [];
    const birthDate = new Date(year, month - 1, day);

    const user: UserAccountRequest = {
        name: values.user?.name,
        document: values.user?.document,
        // documentType: 1,
        birthDate: birthDate,
        phoneNumber: values.user?.phoneNumber,
        email: values.user?.email,
        identifier: values.user?.identifier
    };
    const request: OpenAccountRequest = {
        localId: values.device.localId,
        currentSessionId: values.session.id,
        equipamentId: values.device.id,
        operatorId: values.operatorId,
        // operatorName: values.operator.name
        cardId: values.table?.id ?? '',
        cardTag: values.table?.number ?? '',
        groupId: values.groupId ?? '',
        userAccount: user,
        order: {
            createdAt: new Date().toISOString(),
            operatorId: values.operatorId,
            orderId: v4(),
            orderNumber: '',
            deliveryTableId: values.table?.id,
            destinyId: values.table?.id,
            items: [] as ConsumptionItemRequest[]
        },
        minimumConsumption: 0,//number,
        // consumptionLimit: 0,//number,
        // equipmentName: 'String',
    }


    await service.openAccount(request);

    return
}
export default OpenAccountUseCase


// {
//     "localId": "c70c2fe8-0675-1f4f-3854-1d3e1d948730",
//     "equipamentId": "3b83eb8e-57d2-4518-a575-1098ef285e94",
//     "equipmentName": "PAG15904",
//     "operatorId": "4e417656-223a-46bc-96cb-66b1a36210b6",
//     "operatorName": "ADM",
//     "cardId": "49430f17-92a0-459a-bedd-6d65e14e9983",
//     "cardTag": "01",
//     "groupId": "",
//     "minimumConsumption": "",
//     "userAccount": {
//         "name": "Deivid Augusto Pereira Castilho",
//         "document": "70402572629",
//         "birthDate": "",
//         "phoneNumber": "",
//         "email": ""
//     },
//     "order": {
//         "orderId": "6eb17f17-9950-4a1d-b3a8-4bb2759da348",
//         "operatorId": "4e417656-223a-46bc-96cb-66b1a36210b6",
//         "createdAt": "2023-12-20T15:45:47.518Z",
//         "deliveryTableId": "49430f17-92a0-459a-bedd-6d65e14e9983",
//         "destinyId": "49430f17-92a0-459a-bedd-6d65e14e9983",
//         "items": [],
//         "orderNumber": "573"
//     },
//     "ignoreSameDocument": true
// }