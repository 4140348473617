import React, { createContext, FC, ReactNode, useContext, useState } from "react";
import { themeMUI } from "../interface/Meep";
import { ThemeProvider as MuiThemeProvider, Theme } from "@mui/material";

interface IThemeContext {
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(themeMUI);
  return (
    <ThemeContext.Provider value={{ theme }}>
      <MuiThemeProvider theme={theme}>
        <>{children}</>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ThemeConsumer = ThemeContext.Consumer;

export const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};
