import React, { FC } from 'react'
import styles from './TransferTable.module.scss'
import TransferTableForm from './components/TransferTableForm'
import UseTransferTable from './UseTransferTable'
import Toolbar from '../../components/ui/toolbar/Toolbar'
export interface ITransferTableProps {
    //propertys
}
const TransferTable: FC<ITransferTableProps> = () => {
    const { tableList } = UseTransferTable();

    return (
        <div id={styles.TransferTable} >
            <Toolbar />
            <div className={styles.container} >
                {
                    // !!tableList?.length &&
                    // <TransferTableForm tableList={tableList} isLoadingTables={false} />
                }
            </div>
        </div>
    )
}
export default TransferTable