import { create } from 'zustand';
import { ICartContext } from '../../../consume/domain/interfaces/IUseCaseCart';
import { IOrderComposition } from '../../domain/models/IOrderComposition';
import { addCartItem, addProductCart, clearCart, clearCartItem, removeCartItem, removeCartItemByProductId } from './CartActions';
import { IProductCart } from '../../domain/models/IProductCart';

export const useCartStore = create<ICartContext>((set) => ({
    cartItems: [],
    totals: {
        totalQuantity: 0,
        totalPrice: 0
    },
    lastProductAdded: undefined,
    addProductCart: (product: IProductCart, quantity: number, orderCompositions?: IOrderComposition[], notes?: string) => {
        set(state => (
            addProductCart(state.cartItems, product, quantity, orderCompositions, notes)
        ))
    },
    removeCartItemByProductId: (productId: string, quantity: number) => {
        set(state => removeCartItemByProductId(state.cartItems, productId, quantity))
    },
    clearCartItem: (cartItemId: string) => {
        set(state => clearCartItem(state.cartItems, cartItemId))
    },
    removeCartItem: (cartItemId: string, quantity: number) => {
        set(state => removeCartItem(state.cartItems, cartItemId, quantity))
    },
    addCartItem: (cartItemId: string, quantity: number) => {
        set(state => addCartItem(state.cartItems, cartItemId, quantity))
    },
    clearCart: () => {
        set(() => clearCart())
    },
}
));



