import { GetCodeResponse } from "../../../domain/dtos/GetCodeResponse";
import { ICodePanelService } from "../../../domain/interfaces/ICodePanelService";

export const GetCodePanelUseCase = async (
    service: ICodePanelService,
    localId: string,
    sessionId?: string,
  ): Promise<GetCodeResponse | undefined> => {
    try {
      const response = await service.getCodePanel(localId, sessionId);
      return response;      
    } catch (error) {
      console.error(error);
    }
};