import { create } from "zustand";
import { IOnBoardingItem, IOnBoardingItemRequest } from "../domain/models/IOnBoardingItem";
import { GetOnboardingUseCase } from "../applicaiton/OnboardingUseCases";
import { OnBoardingSteps } from "../domain/enums/OnBoardingSteps";
import { IOnBoardService } from "../domain/Interfaces/IOnBoardService";




export interface IOnboardingStore {
    onBoarding: IOnBoardingItem[] | undefined;
    isLoading: boolean;
    onFinishStep: (onBoardingService: IOnBoardService, onBoarding: IOnBoardingItemRequest) => Promise<void>;
    getOnBoarding: (onBoardingService: IOnBoardService, localId: string) => Promise<IOnBoardingItem[]>;
    resetSteps: (onBoardingService: IOnBoardService, localId: string) => Promise<void>;
    hasOnboardingStep: (onBoardingService: IOnBoardService, localId: string, step: OnBoardingSteps) => Promise<boolean>;
}

const UseOnboardingStore = create<IOnboardingStore>((set, get) => ({
    onBoarding: undefined,
    isLoading: false,
    onFinishStep: async (onBoardingService: IOnBoardService, onBoarding: IOnBoardingItemRequest) => {
        try {
            set({ isLoading: true });
            if (get().onBoarding?.some(item => item.step === onBoarding.step)) {
                return;
            }
            await onBoardingService.setOnBoarding(onBoarding);
            const response = [...get().onBoarding ?? [], onBoarding];
            // const response = await GetOnboardingUseCase(onBoardingService, onBoarding.localId);
            set({ onBoarding: response });
        } finally {
            set({ isLoading: false });
        }
    },
    getOnBoarding: async (onBoardingService: IOnBoardService, localId: string) => {
        try {
            set({ isLoading: true });
            const response = await GetOnboardingUseCase(onBoardingService, localId);
            set({ onBoarding: response });
            return response;
        } finally {
            set({ isLoading: false });
        }
    },
    resetSteps: async (onBoardingService: IOnBoardService, localId: string) => {
        try {
            set({ isLoading: true });
            await onBoardingService.resetOnBoarding(localId);
            const response = await GetOnboardingUseCase(onBoardingService, localId);
            set({ onBoarding: response });
        } finally {
            set({ isLoading: false });
        }
    },
    hasOnboardingStep: async (onBoardingService: IOnBoardService, localId: string, step: OnBoardingSteps) => {
        set({ isLoading: true });
        try {
            if (get().onBoarding === undefined) {
                await get().getOnBoarding(onBoardingService, localId);
            }
            const response = get().onBoarding;
            return response?.some(item => item.step === step) ?? false;
        } finally {
            set({ isLoading: false });
        }
    }
}));

export default UseOnboardingStore;