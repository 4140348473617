import React, { FC, forwardRef, useCallback, useImperativeHandle } from 'react'
import styles from './OpenSessionAlert.module.scss'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer } from '@mui/material';
import { promises } from 'dns';

export interface IOpenSessionAlertProps {
    //propertys
}
export interface IOpenSessionAlertRef {
    open: () => Promise<void>;
}

const OpenSessionAlert = forwardRef<IOpenSessionAlertRef, IOpenSessionAlertProps>((props, ref) => {

    const [visible, setVisible] = React.useState(false);
    const openSessionPromise = React.useRef<Promise<void>>();
    const resolveRef = React.useRef<() => void>();
    const rejectRef = React.useRef<() => void>();


    const open = useCallback(() => {
        setVisible(true);
        return openSessionPromise.current = new Promise((resolve, reject) => {
            resolveRef.current = resolve;
            rejectRef.current = reject;
        });
    }, []);


    const close = useCallback(() => {
        setVisible(false);
        rejectRef.current?.();
    }, []);

    const confirm = useCallback(() => {
        setVisible(false);
        resolveRef.current?.();
    }, []);

    useImperativeHandle(ref, () => ({
        open
    }), [open]);

    return (
        <Dialog id={styles.OpenSessionAlert} open={visible} >
            <DialogTitle>
                Abrir Caixa
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>Parece que não há um caixa aberto. Deseja abrir um novo caixa?</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="outlined" color="primary">Não abrir</Button>
                <Button onClick={confirm} variant="contained" color="primary">Abrir Caixa</Button>
            </DialogActions>
        </Dialog>
    )

})
export default OpenSessionAlert



export const OpenSessionAlertRef = React.createRef<IOpenSessionAlertRef>();
export const OpenSessionAlertProvider = () => {
    return (
        <OpenSessionAlert ref={OpenSessionAlertRef} />
    )
}