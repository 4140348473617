import { useCallback, useState } from 'react';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import { ITable } from '../domain/models/ITables';
import GetTableListUseCase from '../application/useCases/sharedOrderPad/GetTableListUseCase';
import TableLocalStorage from '../../../services/localStorage/table/TableLocalStorage';
import GetLocalTableUseCase from '../application/useCases/sharedOrderPad/GetLocalTablesUseCase';
import UseDeviceStore from '../../device/store/DeviceStore';
import TransferTableUseCase from '../application/useCases/sharedOrderPad/TransferTableUseCase';
import useOperatorStore from '../../operator/store/OperatorStore';
import UseSessionStore from '../../session/store/SessionStore';
import { useShallow } from 'zustand/react/shallow';
import GetCommandsUseCase from '../application/useCases/sharedOrderPad/GetCommandsUseCase';

const consumptionService = ConsumeApi();
const tableLocalService = TableLocalStorage();

const UseTable = () => {
  const [tableList, setTableList] = useState<ITable[]>();
  const [device] = UseDeviceStore(state => [state.device]);
  const [currentOperator] = useOperatorStore(state => [state.currentOperator]);
  const session = UseSessionStore(useShallow(state => state.session));
  const [isLoading, setIsLoading] = useState(false);

  const getTableList = useCallback(async () => {
    try {
      setIsLoading(true);
      if (device && session) {
        const response = await GetTableListUseCase(consumptionService, tableLocalService, session, device.localId);
        setTableList(response);
        return response;
      }
    } finally {
      setIsLoading(false)
    }
  }, [session, device]);

  const getLocalTableList = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await GetLocalTableUseCase(tableLocalService);
      if (response) {
        setTableList(response);
      }
      return response;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const transferTable = useCallback(async (from: ITable[], to: ITable) => {
    try {
      if (!device) {
        throw new Error('Equipamento não encontrado');
      }
      if (!currentOperator) {
        throw new Error('Operador não encontrado');
      }
      setIsLoading(true)
      const response = await TransferTableUseCase(consumptionService, device, currentOperator, from, to);
      return response;
    } finally {
      setIsLoading(false);
    }
  }, [currentOperator, device]);

  const getCommandsList = useCallback(async () => {
    try {
      setIsLoading(true);
      if (device && session) {
        const response = await GetCommandsUseCase(consumptionService, tableLocalService, device.localId);
        setTableList(response);
        return response;
      }
    } finally {
      setIsLoading(false)
    }
  }, [session, device]);

  return { getTableList, tableList, getLocalTableList, isLoading, transferTable, getCommandsList };
};

export default UseTable;

