import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './TransferTableForm.module.scss'
import { ITable } from '../../../modules/consume/domain/models/ITables'
import SelectTableList from './SelectTableList'
import { Button, Icon } from '@mui/material'
import ModalLayout from '../../../components/ui/modalLayout/ModalLayout'


export interface ITransferTableFormProps {
    //propertys
    tableList?: ITable[]
    isLoadingTables: boolean
    onClose?: () => void
    onSubmit: (from: ITable[], to: ITable) => Promise<void>
}
const TransferTableForm: FC<ITransferTableFormProps> = ({ tableList, onClose, onSubmit }) => {

    const [openedTables, setOpenedTables] = React.useState<ITable[]>();
    const [closedTables, setClosedTables] = React.useState<ITable[]>();
    const [selectedFromTables, setSelectedFromTables] = React.useState<ITable[]>();
    const [selectedToTable, setSelectedToTable] = React.useState<ITable>();

    useEffect(() => {
        if (tableList?.length) {
            const openedTables = tableList.filter(table => table.openedAt && table.startClosingAt === null)
            const closedTable = tableList.filter(table => table.openedAt === null)
            setOpenedTables(openedTables)
            setClosedTables(closedTable)
        }
    }, [tableList])

    const ontoChangeHandle = useCallback((table: ITable[]) => {
        setSelectedToTable(table[0])
    }, [])

    const onChangeFromHandle = useCallback((tables: ITable[]) => {
        setSelectedFromTables(tables)
    }, [])

    const onSubmmitHandle = useCallback(() => {
        try {
            console.log(selectedFromTables, selectedToTable);
            if (!selectedFromTables?.length || !selectedToTable) return
            onSubmit(selectedFromTables!, selectedToTable!)
        } catch (error) {
            console.log(error);
        }
    }, [onSubmit, selectedFromTables, selectedToTable]);

    return (
        <div id={styles.TransferTableForm} >
            <ModalLayout title='Transferir Mesa' onClose={onClose} >
                <div className={styles.container} >
                    {openedTables && <SelectTableList title="De" showArrow multiple tableList={openedTables} onChange={onChangeFromHandle} />}
                    <div className={styles.divisor} />
                    {closedTables && <SelectTableList title="Para" tableList={closedTables} onChange={ontoChangeHandle} />}
                </div>
                <div className={styles.buttons} >
                    <Button onClick={onClose} className={styles.button} variant='outlined' >Voltar</Button>
                    <Button onClick={onSubmmitHandle} disabled={!selectedFromTables?.length || !selectedToTable} className={styles.button} variant='contained' >Transferir</Button>
                </div>
            </ModalLayout>
        </div>
    )
}
export default TransferTableForm


