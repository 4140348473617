import React, { FC, useRef, useState, useEffect } from 'react';
import styles from './CartToast.module.scss';
import { useCart } from '../../../../modules/cart/presentation/contexts/CartContext';
import { Transition } from 'react-transition-group';
import { useCartStore } from '../../../../modules/cart/infra/store/CartStore';

export interface ICartToastProps {
    // properties
}

const CartToast: FC<ICartToastProps> = () => {
    const lastProductAdded = useCartStore(state => state.lastProductAdded);
    const divRef = useRef<HTMLDivElement>(null);
    const [showToast, setShowToast] = useState(false);
    const [counter, setCounter] = useState(1);
    const refLastProductAdded = useRef(lastProductAdded);

    const handleToastExited = () => {
        setShowToast(false);
        setCounter(1);
        refLastProductAdded.current = undefined;
    };

    // console.log("render")

    useEffect(() => {
        if (!lastProductAdded) return;
        let interval: NodeJS.Timer | null = setTimeout(() => {
            handleToastExited();
        }, 1000);
        if (lastProductAdded?.id !== refLastProductAdded.current?.id) {
            setCounter((prevCounter) => prevCounter + 1);
            handleToastExited();
            setShowToast(true);

        } else {
            setCounter((prevCounter) => prevCounter + 1);
        }
        refLastProductAdded.current = lastProductAdded;
        return () => {
            console.log('clearInterval');
            if (interval) clearInterval(interval);
        };
    }, [lastProductAdded]);


    const transitionStyles = {
        entering: { transform: 'translateY(0)', opacity: 1 },
        entered: { transform: 'translateY(0)', opacity: 1 },
        exiting: { transform: 'translateY(200%)', opacity: 0 },
        exited: { transform: 'translateY(200%)', opacity: 0 },
    };

    return (
        <Transition
            nodeRef={divRef}
            in={!!showToast}
            timeout={1000}
        >
            {(state) =>
                <div style={{ ...transitionStyles[state], transition: 'all 0.3s' }} id={styles.CartToast} ref={divRef}>
                    <div className={styles.container}>
                        <div className={styles.image} style={{ backgroundImage: `url("${lastProductAdded?.thumbnailUrl}")` }} />
                        <div className={styles.description}>

                            <span> foram adicionados <b>{counter}</b></span>
                            <span> {lastProductAdded?.name}</span>
                        </div>
                    </div>
                </div>}
        </Transition>
    );
};

export default CartToast;
