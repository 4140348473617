import { useCallback } from 'react';
import { v4 } from 'uuid';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import { IOrderItem } from '../../cart/domain/models/IOrderItem';
import { useUi } from '../../ui/presentation/context/UiContext';
import SendConsumeOrderUseCase from '../application/useCases/consume/SendConsumeOrderUseCase';
import UseDeviceStore from '../../device/store/DeviceStore';
import { hideLoading, showLoading } from '../../ui/stores/UiStore';
import { ProductType } from '../../catalog/domain/models/ProductType';
const UseComsumeOrder = () => {
  const [device] = UseDeviceStore(state => [state.device]);

  const consumeOrder = useCallback(
    async (
      orderPadId: string,
      accountId: string,
      orderItems: IOrderItem[],
      sharedOrderPadNumber: string,
      orderId: string = v4(),
      operatorId: string
    ) => {
      try {
        const _cartItems = orderItems.map<IOrderItem>(item => ({
          ...item,
          ticketIds: Array.from({ length: item.productType === ProductType.Kilo ? 1 : item.quantity }).map(() => v4())
        }));
        showLoading('@consumeOrder', 'Enviando pedido');
        const consumeService = ConsumeApi();
        if (device) {
          const res = await SendConsumeOrderUseCase(
            consumeService,
            device?.localId,
            device?.id,
            orderPadId,
            accountId,
            _cartItems,
            sharedOrderPadNumber,
            orderId,
            operatorId
          );
          return {
            id: orderId,
            orderItems: _cartItems
          };
        } else {
          console.error('No Device');
          throw new Error('[Consume Order]no Device');
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        hideLoading('@consumeOrder');
      }
    },
    [device],
  );


  // const consumeTicketOrder = useCallback(
  //   async (
  //     orderPadId: string,
  //     accountId: string,
  //     orderItems: IOrderItem[],
  //     sharedOrderPadNumber: string,
  //     orderId: string = v4(),
  //     operatorId: string
  //   ) => {
  //     try {
  //       showLoading('@consumeOrder', 'Enviando pedido');
  //       const consumeService = ConsumeApi();
  //       if (device) {
  //         await SendConsumeOrderUseCase(
  //           consumeService,
  //           device?.localId,
  //           device?.id,
  //           orderPadId,
  //           accountId,
  //           orderItems,
  //           sharedOrderPadNumber,
  //           orderId,
  //           operatorId
  //         );
  //         return {
  //           orderId: orderId,
  //         };
  //       } else {
  //         console.error('No Device');
  //         throw new Error('[Consume Order]no Device');
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     } finally {
  //       hideLoading('@consumeOrder');
  //     }
  //   },
  //   [device],
  // );




  return { consumeOrder };
};

export default UseComsumeOrder;


