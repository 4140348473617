import { useCallback } from 'react';
import PrePaidTicketsApi from '../../../services/api/prePaidTickets/PrePaidTicketsApi';
import SendTicketConsumeOrderUseCase from '../application/useCases/SendTicketConsumeOrderUseCase';
import { ICustomerFormValue } from '../../../pages/TableMap/components/customerForm/ICustomerFormValue';
import { v4 } from 'uuid';
import { IOrderItem } from '../../cart/domain/models/IOrderItem';
import { PaymentType } from '../../consume/domain/models/PaymentType';
import useOperatorStore from '../../operator/store/OperatorStore';
import UseDeviceStore from '../../device/store/DeviceStore';
import { useShallow } from 'zustand/react/shallow';
import UseSessionStore from '../../session/store/SessionStore';
import { hideLoading, showLoading } from '../../ui/stores/UiStore';
import OrderLogRepository from '../../../services/db/OrderRepository';
import AddOrderLogUseCase from '../../orderLog/application/AddOrderLogUseCase';
import { IOrder } from '../../cart/domain/models/IOrder';
import { ProductType } from '../../catalog/domain/models/ProductType';
const UsePrePaidTickets = () => {
  const [device] = UseDeviceStore(state => [state.device]);
  const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));
  const session = UseSessionStore(useShallow(state => state.session))

  const consumeTicketOrder = useCallback(
    async (
      cartItems: IOrderItem[],
      totalAmount: number,
      paymentType: PaymentType,
      customer?: ICustomerFormValue,
      transactionData?: string,
      codePanel?: string
    ) => {
      try {
        if (!device) throw new Error('Dispositivo não encontrado');
        if (!currentOperator) throw new Error('Operador não encontrado');
        if (!session) throw new Error('Sessão não encontrada');
        const prepaidTicketService = PrePaidTicketsApi();
        showLoading('@consumeOrder', 'Enviando pedido');

        const _cartItems = cartItems.map<IOrderItem>(item => ({
          ...item,
          ticketIds: Array.from({ length: item.productType === ProductType.Kilo ? 1 : item.quantity }).map(() => v4())
        }));
        const newOrder: IOrder = {
          id: v4(),
          orderItems: _cartItems,
        }
        const response = await SendTicketConsumeOrderUseCase(
          prepaidTicketService,
          {
            localId: device.localId,
            order: newOrder,
            registerSessionId: session.registerSessionId,
            totalAmount: totalAmount,
            operator: currentOperator,
            customer: customer,
            paymentType: paymentType, //TODO: implementar pagamento
            transactionData: transactionData,
            status: 3, //revisar statusp
            codePanel
          }
        );

        await AddOrderLogUseCase(new OrderLogRepository(), newOrder, currentOperator, session);

        return newOrder;

      } catch (error) {
        throw error;
      } finally {
        hideLoading('@consumeOrder');
      }
    },
    [currentOperator, session, device],
  );

  return { consumeTicketOrder };
};

export default UsePrePaidTickets;


