import { create } from "zustand";
import { StateStorage, createJSONStorage, persist } from "zustand/middleware";
import EncryptedStorage from "../../../services/localStorage/encryptedStorage/EncryptedStorage";
import { v4 } from "uuid";
import { IDeviceNavigator } from "../domain/interfaces/IDeviceNavigator";
import VerifyTerminalNavigatorSessionUseCase from "../application/DeviceNavigator/VerifyTerminalNavigatorSessionUseCase";
import { IVerifyTerminalNavigatorSessionResponse } from "../domain/dtos/VerifyTerminalNavigatorSessionResponse";


export interface DeviceNavigatorStore {
    deviceId?: string | null;
    localId?: string | null;
    terminalId?: string | null;
    solicitationId?: string | null;
    isLoadingVerify?: boolean;
    setTerminalId: (terminalId?: string) => void;
    setSolicitationId: (solicitationId?: string) => void;
    verifyDevice: (service: IDeviceNavigator, localId: string, deviceId: string, navigatorSessionId: string) => Promise<IVerifyTerminalNavigatorSessionResponse>;
    // verifyCurrentDevice: (service: IDeviceNavigator, navigatorSessionId: string) => Promise<IVerifyTerminalNavigatorSessionResponse | null>;
}


const UseDeviceNavigatorStore = create<DeviceNavigatorStore>()(persist(
    (set, get) => ({
        deviceId: null,
        localId: null,
        terminalId: null,
        solicitationId: null,
        setTerminalId: (terminalId?: string) => set(store => ({ terminalId })),
        setSolicitationId: (solicitationId?: string) => set(store => ({ solicitationId })),
        verifyDevice: async (service: IDeviceNavigator, localId: string, deviceId: string, navigatorSessionId: string) => {
            try {
                const store = get();
                set({ isLoadingVerify: true });
                if (deviceId !== store.deviceId || localId !== store.localId) {
                    set({
                        localId,
                        deviceId,
                        terminalId: undefined,
                        solicitationId: undefined,
                    })
                }
                const response = await VerifyTerminalNavigatorSessionUseCase(service, localId, deviceId, navigatorSessionId);

                set({
                    localId,
                    deviceId,
                    terminalId: response.terminalId,
                    solicitationId: response.navigatorSolicitationId,
                })
                return response;
            }
            finally {
                set({ isLoadingVerify: false });

            }
        },
        // verifyCurrentDevice: async (service: IDeviceNavigator, navigatorSessionId: string) => {
        //     const store = get();
        //     if (store.deviceId && store.localId) {
        //         return await get().verifyDevice(service, store.localId, store.deviceId, navigatorSessionId);
        //     }
        //     return null;
        // }
    }),
    {
        name: "device-navigator",
        storage: createJSONStorage<StateStorage>(EncryptedStorage),
    }
));

export default UseDeviceNavigatorStore;