import React, { FC, useCallback } from 'react';
import styles from './SimplePosSelector.module.scss';
import { Button } from '@mui/material';
import ISimplePos from '../../../../modules/simplePos/model/ISimplePos';

export interface ISimplePosSelectorProps {
    onChangeSimplePos: (paymentType: ISimplePos | null) => void,
    selectedSimplePos?: ISimplePos | null,
    simplePosList?: ISimplePos[]
}

export const SimplePosSelector: FC<ISimplePosSelectorProps> = ({
    onChangeSimplePos,
    selectedSimplePos,
    simplePosList
}) => {

    const onChangeSimplePosHandle = useCallback((simplePos: ISimplePos | null) => {
        onChangeSimplePos(simplePos);
    }, [onChangeSimplePos]);

    return <div className={styles.paymentType}>
        <h3>Selecione a maquininha</h3>
        <div className={styles.buttonContainer}>
            {
                simplePosList?.map((item) => <Button
                    size='large'
                    classes={{ outlined: styles.outlined, text: styles.text }}
                    variant={selectedSimplePos?.id === item.id ? "outlined" : "text"}
                    color='primary'
                    className={styles.button}
                    onClick={() => onChangeSimplePosHandle(item)}>
                    <img alt={'icone Dinheiro'} src='/assets/img/icon-pos.svg' />
                    <span>{item.name}</span>
                    <div style={{ color: "#000", opacity: 0.4, fontSize: 12, margin: 0 }}>{item.posId}</div>
                </Button>)
            }
            <Button
                size='large'
                classes={{ outlined: styles.outlined, text: styles.text }}
                variant={selectedSimplePos === null ? "outlined" : "text"}
                color='primary'
                className={styles.button}
                onClick={() => onChangeSimplePosHandle(null)}>
                <img alt={'icone Dinheiro'} src='/assets/img/icon-pos.svg' />
                <span>Nova POS</span>
                <div style={{ color: "#000", opacity: 0.4, fontSize: 12, margin: 0 }}></div>
            </Button>
        </div>
    </div>;
};
