import React, { FC } from 'react'
import styles from './ModalLayout.module.scss'
import { Icon, IconButton } from '@mui/material'
export interface IModalLayoutProps {
    //propertys
    children?: React.ReactNode
    onClose?: () => void
    title: string
}
const ModalLayout: FC<IModalLayoutProps> = ({ children, onClose, title }) => {
    return (
        <div id={styles.ModalLayout} >
            <div className={styles.header} >
                <h2>{title}</h2>
                <IconButton onClick={onClose} className={styles.closeButton}><Icon >close</Icon></IconButton>
            </div>
            <div className={styles.container} >
                {children}
            </div>
            {/* <div className={styles.footer}>
                <Button variant={'outlined'} size='large'><Icon>login</Icon>Confirmar</Button>
            </div> */}
        </div>
    )
}
export default ModalLayout