import { ISession } from '../../../../session/domain/ISession';
import { IConsumeService } from '../../../domain/interfaces/IConsumeService';
import { ITableRepository } from '../../../domain/interfaces/tables/ITablesRepository';
import { ITable } from '../../../domain/models/ITables';
import InsertCardIdFromSessionToTable from './InsertCardIdFromSessionToTable';

const GetTableListUseCase = async (
  consumeServer: IConsumeService,
  tableRepository: ITableRepository,
  session: ISession,
  localId: string,
): Promise<ITable[]> => {
  const response = await consumeServer.getTables(localId);
  tableRepository.saveTables(response);
  return response;
};
export default GetTableListUseCase;
