import React, { FC } from 'react'
import styles from './NofFoundDevicePage.module.scss'
import { PORTAL_URL } from "../../services/api/bases/Constants"
export interface INofFoundDevicePageProps {
    //propertys
}
const NofFoundDevicePage: FC<INofFoundDevicePageProps> = () => {
    return (
        <div id={styles.NofFoundDevicePage} >
            <div className={styles.container} >
                <img alt="" src={'/assets/img/logo-black.svg'}></img>
                <img src='/assets/img/session-alert.svg' alt="Imagem Caixa" />
                <h1>{`Pagina não encontrada ou dispositvo não cadastrado.`}</h1>
                <h3><a href={PORTAL_URL+ "/private/terminal"} >Click aqui</a>{` e acesse o portal para acessar seu terminal.`}</h3>
            </div>
        </div>
    )
}
export default NofFoundDevicePage