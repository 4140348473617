import { IInvoice } from "../../../../invoice/application/dtos/IInvoiceData";

interface Props {
    invoice: IInvoice;
    width?: number;
    onLoad?: () => void;
    qrCode?: string;
}

const InvoicePrinterLayout: React.FC<Props> = ({ invoice, width, onLoad, qrCode }) => {
    return (
        <div
            // id={styles.InvoicePrinterLayout}
            style={{
                //maxWidth: '800px',
                //minWidth: '800px',
                padding: '12px',
                overflow: 'visible',
                gap: '0',
                textAlign: 'center',
                zIndex: '1',
                maxWidth: '200px',
                //fontSize: '40px',
            }}
        >
            <b>{invoice.companyName}</b>
            <div>CNPJ:  <b>{invoice.cnpj}</b></div>
            <div>Razão Social: <b> {invoice.companyName}</b></div>
            <div>DANFE NFC-e - Documento Auxiliar da Nota Fiscal do Consumidor Eletrônica</div>
            {/* <div>VALOR: R$ {invoice.totalValue?.toFixed(2)}</div> */}
            <div style={{ margin: '10px 0' }}>
                <div><b>Produtos:</b></div>
                {invoice.items.map(item => (
                    <div key={item.id}>{item.quantity} x {item.productName} {item.unitPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                ))}
            </div>
            <div>Consumidor:<b>{invoice.customer}</b> </div>
            <div>NFC-e: {invoice.number} Serie: {invoice.series}</div>
            <div>Data: {invoice.sefaz.emissionDate?.toLocaleString()}</div>
            <div>Consulte pela chave de acesso no SEFAZ do seu estado ou QRCode</div>
            <div style={{ marginBottom: "16px" }}>{invoice.sefaz.key}</div>
            <img src={qrCode} alt="QRCode" width={'90px'} style={{ marginTop: "16px" }} />
            {/* <QRCode onLoad={onLoad} renderAs="canvas" value={'http://teste.com'} size={200} style={{ position: 'relative', background: '#1ac' }} /> */}
            <div>NOTA EM CONTIGÊNCIA</div>
            <div>Protocolo de autorização: {invoice.sefaz.protocol}</div>
            <div style={{ width: `${invoice.sefaz.code}px`, height: '1px', margin: '20px auto', background: '#000' }} />
        </div>
    );
};

export default InvoicePrinterLayout;