import { useCallback, useEffect, useRef, useState } from 'react';

const UseIsTabActive = (onVisible?: () => void) => {

  const isVisible = useRef(true);
  const onVisibleRef = useRef(onVisible);
  // const handleVisibilityChange = useCallback(() => {
  //   isVisible.current = (document.visibilityState === 'visible');

  //   console.log("isVisible", isVisible.current);
  // }, []);

  const handleFocusChange = useCallback(() => {
    isVisible.current = true;
    if (isVisible.current && onVisibleRef.current) {
      onVisibleRef?.current();
    }
    console.log("isVisible", isVisible.current);
  }, []);

  const handleBlurChange = useCallback(() => {
    isVisible.current = false;
    console.log("isVisible", isVisible.current);
  }, []);

  useEffect(() => {
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocusChange);
    window.addEventListener('blur', handleBlurChange);
    return () => {
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocusChange);
      window.removeEventListener('blur', handleBlurChange);
    };
  }, [handleBlurChange, handleFocusChange]);

  return { isVisible };
};

export default UseIsTabActive;