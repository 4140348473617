import {IAuthenticationRepository} from '../../domains/interfaces/IAuthenticationRepository';
import {IAuthenticationService} from '../../domains/interfaces/IAuthenticationService';
import {IAuthentication} from '../../presentation/interface/IAuthentication';


export const CheckAuthenticationUseCase = async (
  authenticationService: IAuthenticationService,
  mac: string,
): Promise<IAuthentication> => {
  const response = await authenticationService.authenticate(mac);
  return response;
};
