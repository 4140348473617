import React, { FC, RefObject, useCallback, useEffect, useMemo } from 'react'
import UseOnboardingStore from '../../../modules/onBoarding/store/OnBoardingStore'
import OnBoardingService from '../../../services/api/onBoarding/OnBoardingService';
import UseDeviceStore from '../../../modules/device/store/DeviceStore';
import { OnBoardingSteps } from '../../../modules/onBoarding/domain/enums/OnBoardingSteps';
import { closeTutorialCard, openTutorialCard } from '../../../modules/onBoarding/store/TutorialCardStore';
import { SystemType } from '../../../modules/device/domain/enums/SystemType';
import { toast } from 'react-toastify';


const UseCatalogTutorials = (ticket?: boolean) => {
    const { onFinishStep, hasOnboardingStep } = UseOnboardingStore();
    const onBoardingService = useMemo(() => OnBoardingService(), [])
    const device = UseDeviceStore(state => state.device)

    const showProductItemTutorialHandle = useCallback(async (productItemRef: RefObject<HTMLDivElement>) => {

        const hasStep = await hasOnboardingStep(onBoardingService, device?.localId ?? '', ticket ? OnBoardingSteps.SelectItemsTicket : OnBoardingSteps.SelectItemsTable,);
        if (!hasStep) {
            openTutorialCard({
                element: productItemRef?.current,
                options: {
                    title: `Selecione os itens`,
                    description: 'Agora, selecione os itens para serem enviados ao carrinho.',
                    arrowPosition: 'top-center',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: device?.localId ?? '',
                            step: ticket ? OnBoardingSteps.SelectItemsTicket : OnBoardingSteps.SelectItemsTable,
                        })
                        closeTutorialCard();
                    },
                }
            })
        }
    }, [device?.localId, hasOnboardingStep, onBoardingService, onFinishStep, ticket])

    useEffect(() => {
        return () => {
            closeTutorialCard();
        }
    }, [])





    const showSendOrderTutorialHandle = useCallback(async (type: string, productItemRef: RefObject<HTMLDivElement>) => {

        const hasStep = await hasOnboardingStep(onBoardingService, device?.localId ?? '', ticket?OnBoardingSteps.SendOrderToKitchenTicket : OnBoardingSteps.SendOrderToKitchenTable);
        if (!hasStep) {
            openTutorialCard({
                element: productItemRef?.current,
                options: {
                    title: `Envie o pedido para
                    cozinha`,
                    description: 'Ao enviar, ele cairá no Gestor de Pedidos para começar a ser a preparado.',
                    arrowPosition: 'bottom-center',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: device?.localId ?? '',
                            step: ticket?OnBoardingSteps.SendOrderToKitchenTicket : OnBoardingSteps.SendOrderToKitchenTable,
                        })
                        closeTutorialCard();
                    },
                }
            })
        }
    }, [device?.localId, hasOnboardingStep, onBoardingService, onFinishStep, ticket])




    return ({ showProductItemTutorialHandle, showSendOrderTutorialHandle })
}

export default UseCatalogTutorials  