import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './TableMapPage.module.scss'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import TablePicker from './components/tablePicker/TablePicker'
import { ITableItem } from './components/tablePicker/ITableItem'
import UseTable from '../../modules/consume/presentation/UseTable'
import SearchInput from '../../components/searchInput/SearchInput'
import { useNavigate } from 'react-router-dom'
import { Button, Drawer, Icon } from '@mui/material'
import CustomerForm from './components/customerForm/CustomerForm'
import { ICustomerFormValue } from './components/customerForm/ICustomerFormValue'
import UseAccount from '../../modules/consume/presentation/UseAccount'
import useOperatorStore from '../../modules/operator/store/OperatorStore'
import UseDeviceStore from '../../modules/device/store/DeviceStore'
import { SystemType } from '../../modules/device/domain/enums/SystemType'
import TransferTableForm from '../transferTable/components/TransferTableForm'
import { ITable } from '../../modules/consume/domain/models/ITables'
import { useUi } from '../../modules/ui/presentation/context/UiContext'
import UseTableMapTutorials from './hooks/UseTableMapTutorials'
import { hideLoading, showLoading } from '../../modules/ui/stores/UiStore'
export interface ITableMapPageProps {
    //propertys
}
const TableMapPage: FC<ITableMapPageProps> = () => {

    const { tableList, getLocalTableList, getTableList, isLoading, getCommandsList } = UseTable();
    const [filter, setFilter] = useState<{ keyword: string, status: 'busy' | 'free' | '' }>({ keyword: '', status: '' });
    const [device, isSystemType, deviceConfig] = UseDeviceStore(state => [state.device, state.isSystemType, state.deviceConfig]);
    const [openTransferTable, setOpenTransferTable] = useState(false);
    const { transferTable } = UseTable();
    const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));
    const [selectedTable, setSelectedTable] = React.useState<ITableItem>();
    const [customerData, setCustomerData] = React.useState(false);
    const { toast } = useUi();
    const navigate = useNavigate()
    const tablemapAndCounterDivRef = React.useRef<HTMLDivElement | null>(null);
    const customerFormContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { openAccount } = UseAccount();

    const { resetOnBoarding, showTableTutorial, showCustomerFormTutorial, showGoToPainelTutorial } = UseTableMapTutorials(tablemapAndCounterDivRef, customerFormContainerRef);

    const handleTableCustomerData = useMemo(() => deviceConfig?.operation.methods.find((method) => method.name === "Pós-pago Comanda")?.customerIdentification, [deviceConfig]);

    useEffect(() => {
        if (currentOperator === undefined) navigate('/session');

        return () => {

        }
    }, [currentOperator, navigate])


    useEffect(() => {
        getLocalTableList();
        getTableList();
        getCommandsList();
    }, [getLocalTableList, getTableList, getCommandsList])

    const onClickCounter = useCallback(() => {
        navigate(`/catalog/ticket`);
    }, [navigate]);

    const onSubmitSearch = useCallback((value: string) => {
        setFilter(prev => ({ ...prev, keyword: value }));
    }, []);

    const onResetHandle = useCallback(() => {
        setFilter(prev => ({ ...prev, keyword: '' }));
    }, []);

    const onSubmitOpenAccount = useCallback(async (values?: ICustomerFormValue) => {
        try {
            if (selectedTable === undefined) return;
            if (currentOperator === undefined) return;
            if (device === undefined) return;
            await openAccount(selectedTable, currentOperator.id, values);
            navigate(`/catalog/table/${selectedTable.id}/${selectedTable.number}`);
        } catch (error) {
            console.error('error', error);
        }
    }, [currentOperator, device, navigate, openAccount, selectedTable]);

    const onClickTable = useCallback((table: ITableItem) => {
        if (table.openedAt) {
            navigate(`/catalog/table/${table.id}/${table.number}`);
        } else if (!table.openedAt && handleTableCustomerData?.some((item) => item.enabled)) {
            setSelectedTable(table);
            setCustomerData(true);
        } else if (!table.openedAt) {
            setSelectedTable(table);
            onSubmitOpenAccount();
        }
    }, [handleTableCustomerData, navigate, onSubmitOpenAccount]);

    const onClickTransferTable = useCallback(() => {
        setOpenTransferTable(true);
    }, []);
    const onClickCloseTransferTable = useCallback(() => {
        setOpenTransferTable(false);
    }, []);
    const onCommitransferTableHandle = useCallback(async (from: ITable[], to: ITable,) => {
        try {
            showLoading('@transferTable', 'Transferindo mesa')
            await transferTable(from, to);
            setOpenTransferTable(false);
            getTableList();
        } catch (error: any) {
            toast(error?.message ?? 'Erro ao transferir mesa', 'error');
        } finally {
            hideLoading('@transferTable')
        }
    }, [getTableList, toast, transferTable]);

    const filteredTableList = React.useMemo(() => tableList?.filter(table => table.number.includes(filter.keyword.trim())), [filter.keyword, tableList]);

    // useEffect(() => {
    //     tableList?.length && (tableList.every(x => !x.openedAt) ? showTableTutorial() : showGoToPainelTutorial());

    // }, [showGoToPainelTutorial, showTableTutorial, tableList]);

    return (
        <div id={styles.TableMapPage} >
            <Toolbar />
            <div className={styles.container} >
                <header className={styles.header} >
                    {isSystemType(SystemType.prepayTicket) &&
                        <div className={styles.counterContainer}>
                            <h2><img alt="ícone Balcão" src="/assets/img/counter-icon.svg" /><span>Balcão</span></h2>
                            <div className={styles.counter} onClick={onClickCounter}>
                                Balcão
                            </div>
                        </div>
                    }
                    <div className={styles.searchContainer}>
                        <SearchInput onSubmit={onSubmitSearch} reset={filter.keyword !== '' ? onResetHandle : undefined} />
                    </div>
                </header>
                {isSystemType(SystemType.pospayOrderPad) &&
                    <div ref={tablemapAndCounterDivRef} className={styles.tablesContainer}>
                        <div className={styles.tableListHeader}>
                            <h2><img alt="ícone Mesa" src="/assets/img/table-icon.svg" /><span>Mesas</span></h2><Button onClick={onClickTransferTable} ><Icon>move_down</Icon>Transferir mesas</Button>
                        </div>
                        {
                            <TablePicker
                                isLoading={isLoading}
                                tableList={filteredTableList ?? []}
                                onClickTable={onClickTable}
                            />
                        }
                    </div>
                }
                <Drawer open={customerData} anchor='right' onClose={() => setCustomerData(false)} onAnimationEnd={customerData ? showCustomerFormTutorial : undefined} >
                    <div ref={customerFormContainerRef}>
                        <CustomerForm onClose={() => setCustomerData(false)} onSubmit={onSubmitOpenAccount} handleCustomerData={handleTableCustomerData} />
                    </div>
                </Drawer>
                <Drawer open={openTransferTable} anchor='right' onClose={onClickCloseTransferTable}>
                    <TransferTableForm
                        onClose={onClickCloseTransferTable}
                        tableList={tableList} isLoadingTables={false}
                        onSubmit={onCommitransferTableHandle}
                    />
                </Drawer>
            </div >
            {process.env.NODE_ENV === 'development' && <Button style={{ position: 'fixed', bottom: 0 }} onClick={resetOnBoarding}>Reiniciar tutorial</Button>}
            {/* {process.env.NODE_ENV === 'development' && <Button style={{ position: 'fixed', bottom: 20 }} onClick={testePrint}>testPrint</Button>} */}
        </div >
    )
}
export default TableMapPage


declare module window {
    const Meep: any;
}
