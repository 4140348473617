import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './CancelOrderForm.module.scss'
import { IExtract, OrderExtract, OrderItemExtract } from '../../../../modules/consume/domain/models/Extract';
import { CancelOrderFormItem } from './CancelOrderFormItem';
import { Button, Drawer } from '@mui/material';
import ModalLayout from '../../../../components/ui/modalLayout/ModalLayout';
import ObservationInput from '../observationInput/ObservationInput';



export interface ICancelOrderItemSelectable extends OrderItemExtract {
    isChecked: boolean;
}
export interface ICancelOrderFormValue extends OrderExtract {
    items: ICancelOrderItemSelectable[];
}


export interface ICancelOrderFormProps {
    //propertys
    extract: IExtract;
    onClose: () => void;
    onSubmit: (extract: ICancelOrderItemSelectable[], annotation: string) => Promise<void>;

}
const CancelOrderForm: FC<ICancelOrderFormProps> = ({ extract, onClose, onSubmit }) => {


    const [selectableOrder, setSelectableOrder] = React.useState<ICancelOrderFormValue[]>([]);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [inputError, setInputError] = React.useState<string>();
    const [observation, setObservation] = React.useState<string>('');


    const divRef = React.useRef<HTMLDivElement>(null);

    const handleChangeObservation = useCallback((value: string) => {
        setObservation(value);
    }, []);
    const scrollInput = useCallback((ref: React.RefObject<HTMLInputElement | HTMLDivElement>, block: 'start' | 'center' = 'center') => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: block });
    }, [])



    const onCloseConfirmationModal = useCallback(() => {
        setOpenConfirmationModal(false);
    }, []);



    const onSubmitConfirmDrawerHandle = useCallback(async () => {
        try {

            await onSubmit(selectableOrder.flatMap(order => order.items.filter(item => item.isChecked)), observation);
            onCloseConfirmationModal();
            onClose();
        } catch (error) {
            console.log(error);
        }
    }, [observation, onClose, onCloseConfirmationModal, onSubmit, selectableOrder]);

    const extractToSelectable = (extract: IExtract) => {

        const separeItems = (items: OrderItemExtract[]) => {
            const itemsSelectable: ICancelOrderItemSelectable[] = [];
            items.forEach(item => {
                if (!item.isCanceled && !item.isTransfered) {
                    if (item.quantity === 1) {
                        itemsSelectable.push({ ...item, isChecked: false });
                    } else {
                        for (let i = 0; i < item.quantity; i++) {
                            itemsSelectable.push({ ...item, isChecked: false });
                        }
                    }
                }
            });
            return itemsSelectable;
        }

        return extract.orders.filter(item =>
            !item.isPayment &&
            !item.isCanceled &&
            !item.isTransfered &&
            !item.items.every(item => item.isCanceled))
            .map(order => ({ ...order, items: separeItems(order.items) }))
    };

    useEffect(() => {
        setSelectableOrder(extractToSelectable(extract));
    }, [extract]);


    const quantity = useMemo(() => selectableOrder.flatMap(item => item.items).filter(item => item.isChecked).length, [selectableOrder])

    const onChangeHandle = useCallback((item: ICancelOrderFormValue, checked: boolean) => {
        setSelectableOrder(prev => prev.map(order =>
            order.id === item.id ?
                { ...order, items: order.items.map(orderItem => ({ ...orderItem, isChecked: checked })) }
                : order
        ));
    }, []);

    const onChangeItemHandle = useCallback((item: ICancelOrderItemSelectable, checked: boolean) => {
        setSelectableOrder(prev => {
            const newValue = prev.map(order => (
                {
                    ...order, items: order.items.map(orderItem => orderItem.id === item.id ?
                        { ...orderItem, isChecked: checked }
                        :
                        orderItem)
                }
            ))
            return newValue
        })
    }, []);



    const cancelOrderList = useMemo(() => selectableOrder?.map(item => {

        // console.log('item: ', item.items.flatMap(item => item.name + " - " + item.isChecked))
        return <CancelOrderFormItem
            onChange={onChangeHandle}
            onChangeItem={onChangeItemHandle}
            selectableOrder={item}
        />
    }), [onChangeHandle, onChangeItemHandle, selectableOrder]);



    const validate = useCallback(() => {
        if (observation === '') {
            setInputError('Você deve informar uma anotação para cancelar o pedido');
            scrollInput(divRef);
            return false;
        }
        return true;
    }, [observation, scrollInput]);

    const onSubmitHandle = useCallback(() => {
        if (!validate()) return;
        setOpenConfirmationModal(true);
    }, [validate]);

    return (
        <>
            <ModalLayout onClose={onClose} title={'Cancelar pedidos'}>
                <div id={styles.CancelOrderForm} >
                    {cancelOrderList}
                    {/* {input} */}
                    <div ref={divRef}>
                        <ObservationInput error={inputError} onChange={handleChangeObservation} onFocusInput={scrollInput} />
                    </div>

                    <div className={styles.buttons} >
                        <Button onClick={onClose} className={styles.button} variant='outlined' >Voltar</Button>
                        <Button onClick={onSubmitHandle} className={styles.button} variant='contained' disabled={!quantity}>Cancelar {quantity} ite{quantity === 1 ? "m" : "ns"}</Button>
                    </div>
                </div>
            </ModalLayout>
            <Drawer anchor='right' open={openConfirmationModal}>
                <ModalLayout onClose={onCloseConfirmationModal} title={'Cancelar pedidos?'}>
                    <div id={styles.CancelOrderAlert} >
                        <p>
                            Você tem certeza que deseja cancelar {quantity} ite{quantity === 1 ? "m" : "ns"}?
                        </p>
                        <div className={styles.buttons}>
                            <Button onClick={onCloseConfirmationModal} className={styles.button} variant='outlined' >Voltar</Button>
                            <Button onClick={onSubmitConfirmDrawerHandle} className={styles.buttonCancel} variant='contained' >Cancelar {quantity} ite{quantity === 1 ? "m" : "ns"}</Button>
                        </div>
                    </div>
                </ModalLayout>
            </Drawer>
        </>
    )
}


export default CancelOrderForm



