
import React from 'react'
import { UiProvider } from './modules/ui/presentation/context/UiContext';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { HttpProvider } from './modules/api/presentation/HttpContext';
import AppRoutes from './routes/AppRoutes';
import { KeyboardProvider } from './components/_keyboard/KeyboardContext';
import TutorialCardProvider from './modules/onBoarding/presentation/components/TutorialCardProvider';
import LoadingProvider from './modules/ui/presentation/presentation/LoadingProvider';

const App = () => {
  return (
    <div style={{ backgroundColor: "", position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <UiProvider>
        <KeyboardProvider>
          <HttpProvider>
            <BrowserRouter>
              <AppRoutes />
              <TutorialCardProvider />
            </BrowserRouter>
          </HttpProvider>
        </KeyboardProvider>
        <LoadingProvider />
      </UiProvider >
    </div>
  );
}

export default App;
