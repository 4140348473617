import { v4 } from "uuid"
import { ICustomerFormValue } from "../../../../../pages/TableMap/components/customerForm/ICustomerFormValue"
import { IOrder } from "../../../../cart/domain/models/IOrder"
import { ConsumptionItemAdditionalRequest, ConsumptionItemObservationRequest, ConsumptionItemRequest, SyncOrderRequest } from "../../../../prePaidTickets/domain/dtos/SyncPrePaidTickets"
import { OrderStatus } from "../../../../prePaidTickets/domain/OrderStatus"
import { PaymentType } from "../../../domain/models/PaymentType"
import { ProductType } from "../../../../catalog/domain/models/ProductType"

const OrderToSyncOrderRequest = (values: {
    registerSessionId: string,
    localId: string,
    operatorId?: string,
    totalAmount: number,
    order: IOrder,
    discount?: number,
    notes?: string,
    orderStatus: OrderStatus,
    customer?: ICustomerFormValue
    paymentType: PaymentType,
    transactionData?: string
    status: number
    codePanel?: string
}): SyncOrderRequest => {


    const details = values.order.orderItems.map(item => {


        const additionals: ConsumptionItemAdditionalRequest[] = []
        const observation: ConsumptionItemObservationRequest[] = []

        item.orderComposition?.flatMap(item => item.compositionItems).forEach(compositionItem => {
            if (compositionItem.productId) {
                const additionalItem: ConsumptionItemAdditionalRequest = {
                    productId: compositionItem.productId,
                    selectedQuantity: compositionItem.quantity,
                    unitValue: compositionItem.price,
                    discountValue: compositionItem.price,
                }
                additionals.push(additionalItem)
            }
        })

        item.orderComposition?.forEach(composition => {
            composition.compositionItems.map(compositionItem => {
                if (!compositionItem.productId) {
                    const _composition: ConsumptionItemObservationRequest = {
                        id: compositionItem.id,
                        question: composition.description,
                        answer: compositionItem.description,
                    }
                    observation.push(_composition)
                }
            })
        })

        const detail: ConsumptionItemRequest = {
            ticketsIds: item.ticketIds ?? [...Array.from({ length: item.productType === ProductType.Kilo ? 1 : item.quantity }).map(() => v4())],
            productId: item.productId,
            productName: item.name,
            unitValue: item.price,
            // discountValue: item.discount,
            quantity: item.quantity,
            annotation: item.notes,
            additionals: additionals,
            observations: observation,
            optionsOldFormat: "[]", //todo: Verificar 
        }
        return detail
    })

    const [day, month, year]: number[] = values.customer?.birthDate ? values.customer.birthDate.split('/').map(Number) : [];
    const birthDate = new Date(year, month - 1, day);

    const response: SyncOrderRequest = {
        localId: values.localId,
        sessionRegistryPOSId: values.registerSessionId,
        operatorId: values.operatorId,
        totalAmount: values.totalAmount, //nullable
        discount: values.discount, //nullable
        details: details,
        orderId: values.order.id, //nullable
        orderStatus: values.orderStatus, //nullable
        orderDate: new Date(), //nullable
        transactions: [
            {
                paymentType: values.paymentType,
                acquirerType: 3,
                status: values.status, // rever STATUS TICKETsss
                amount: values.totalAmount,
                transactionDate: new Date(),
                transactionData: values.transactionData,
                
            }
        ],
        noteOrder: values.notes, //nullable}
        customerEstablishment: {
            name: values.customer?.name ?? "",
            telephone: values.customer?.phoneNumber,
            birthDate: birthDate,
            document: values.customer?.document,
            child: "",
            email: values.customer?.email,
        },
        codePanel: values.codePanel,
    }
    return response
}
export default OrderToSyncOrderRequest