import { create } from "zustand";
import { IAuthentication } from "../presentation/interface/IAuthentication";
import { IAuthenticationService } from "../domains/interfaces/IAuthenticationService";
import { CheckAuthenticationUseCase } from "../application/useCases/CheckAuthenticationUseCase";

export interface IUseAuthStore {
    auth?: IAuthentication;
    isLoadingAuth: boolean;
    setAuthetication: (auth: IAuthentication) => void;
    removeAuthetication: () => void;
    getAuth: (service: IAuthenticationService, deviceIdentifier: string, setToken?: (token: string) => void) => Promise<IAuthentication>;
}

const useAuthStore = create<IUseAuthStore>((set) => (
    {
        auth: undefined,
        isLoadingAuth: false,
        setAuthetication: (auth: IAuthentication) => {
            set({
                auth
            })
        },
        removeAuthetication: () => {
            return {
                authetication: null
            }
        },
        getAuth: async (service: IAuthenticationService, deviceIdentifier: string, setToken?: (token: string) => void) => {
            try {
                set({ isLoadingAuth: true });
                const auth = await CheckAuthenticationUseCase(service, deviceIdentifier);
                setToken?.(auth.token);
                set({ auth })
                return auth;
            } finally {
                set({ isLoadingAuth: false });
            }
        }
    }
));

export default useAuthStore;

export const token = useAuthStore.getState().auth?.token;