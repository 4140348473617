import { table } from "console";
import { IOrder } from "../../cart/domain/models/IOrder";
import { IDevice } from "../../device/interface/Device";
import { IOperator } from "../../operator/domains/models/Operator";
import { SyncOrderRequest } from "../../prePaidTickets/domain/dtos/SyncPrePaidTickets";

export interface ITicketPrintOrder {
	id: string;
	productId?: string;
	local: string;
	name: string;
	price: number;
	//quantity: number;
	number?: string;
	notes?: string;
	codePanel?: string;
	device: string;
	date?: string;
	operator: string;
	total?: number;
	index: number;
	paymentMethod: string;
	table?: string;
	compositions?: {
		descrpition: string;
		items: {
			name: string;
			quantity: number;
			price: number;
		}[]
	}[],
}


export const SyncOrderRequestToTicketPrintOrder = (syncOrderRequest: SyncOrderRequest, deviceName: string, localName: string, operatorName: string): ITicketPrintOrder[] => {
	const ticketPrintOrders: ITicketPrintOrder[] = syncOrderRequest.details.map(detail => {
		return {
			id: syncOrderRequest.localId,
			productId: detail.productId,
			local: localName,
			name: detail.productName,
			price: detail.unitValue,
			quantity: detail.quantity,
			notes: detail.annotation,
			password: '',
			device: deviceName,
			date: syncOrderRequest.orderDate?.toISOString(),
			operator: operatorName,
			total: syncOrderRequest.totalAmount,
			index: 0,
			paymentMethod: syncOrderRequest.transactions[0].paymentType.toString()
		}
	});
	return ticketPrintOrders;
}

export const orderToPrintTicket = (order: IOrder, device: IDevice, currentOperator: IOperator, table?: string, codePanel?: string): ITicketPrintOrder[] => {

	try {

		const tickets: ITicketPrintOrder[] = []
		order.orderItems.forEach((item, index) => {
			item.ticketIds?.forEach(ticketId => {
				tickets.push({
					id: ticketId,
					productId: item.productId,
					local: device.localName,
					name: item.name,
					price: item.price,
					//quantity: item.quantity,
					notes: item.notes,
					codePanel,
					device: device.deviceName,
					date: new Date().toISOString(),
					operator: currentOperator.name,
					index: index,
					paymentMethod: '',
					table: table,
					compositions: item.orderComposition?.map(composition => {
						return {
							descrpition: composition.description,
							items: composition.compositionItems.map(item => {
								return {
									name: item.description,
									quantity: item.quantity,
									price: item.price
								}
							})
						}
					})
				})
			})
		})
		return tickets;
	} catch (error) {
		console.log('error', error)
		throw error;
	}
}