import { GetCodeResponse } from '../../../modules/consume/domain/dtos/GetCodeResponse';
import { ICodePanelService } from '../../../modules/consume/domain/interfaces/ICodePanelService';
import { Api } from '../bases/Api';

const api = Api();

const CodePanelApi = (): ICodePanelService => {
  const getCodePanel = async (localId: string, sessionId?: string): Promise<GetCodeResponse> => {
    const response = await api.get<GetCodeResponse>(`PasswordPanel/NextOrderPasssoword/LocalClient/${localId}/Cashier/${sessionId}`, {
      headers: { disabledError: true }
    });
    return response.data;
  };

  return { getCodePanel };
}

export default CodePanelApi;