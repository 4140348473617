import React, { FC, useCallback } from 'react'
import InvoiceService from '../../../../services/api/invoice/InvoiceService';
import OrderLogRepository from '../../../../services/db/OrderRepository';
import EmitInvoiceUseCase from '../../application/useCases/EmitInvoiceUseCase';
import { PaymentType } from '../../../consume/domain/models/PaymentType';
import { IOrder } from '../../../cart/domain/models/IOrder';
import { ICustomerFormValue } from '../../../../pages/TableMap/components/customerForm/ICustomerFormValue';
import { toast } from 'react-toastify';
import UpdateInvoiceOrderLogUseCase from '../../../orderLog/application/UpdateInvoiceOrderLogUseCase';
import { Button, Icon, IconButton } from '@mui/material';
import InvoiceSucessToast from './InvoiceSucessToast';
import { IOrderLog } from '../../../orderLog/domain/IOrderLog';
import UseSocketPrinter from '../../../printer/presentation/hooks/UseSocketPrinter';
import UseOrderLog from '../../../../pages/orderLog/hook/UseOrderLog';

const UseInvoice = () => {

    const { printInvoice } = UseOrderLog();

    const emitInvoice = useCallback(async (order: IOrder, customer?: ICustomerFormValue, isManual?: boolean) => {
        const invoiceService = InvoiceService();
        const orderRepository = new OrderLogRepository();
        const response = await EmitInvoiceUseCase(
            invoiceService,
            orderRepository,
            {
                orderId: order.id,
                document: customer?.document,
                isManual: isManual ?? false,
            }
        )
        await UpdateInvoiceOrderLogUseCase(orderRepository, order.id, response);
    }, [])


    const onClick = (data?: IOrderLog) => {
        if(data){
            printInvoice(data)
        }
    }

    const emitBackgroundInvoice = (orderId: string, document: string, isManual?: boolean) => {
        const invoiceService = InvoiceService();
        const orderRepository = new OrderLogRepository();
        toast.promise(
            async () => {
                const response = await EmitInvoiceUseCase(
                    invoiceService,
                    orderRepository,
                    {
                        orderId: orderId,
                        document: document,
                        isManual: isManual ?? false,
                    }
                )
                return await UpdateInvoiceOrderLogUseCase(orderRepository, orderId, response);
            },
            {
                pending: 'Emitindo nota fiscal...',
                success: { render: ({data}) =><InvoiceSucessToast showPrinterButton onClick={()=>{onClick(data)}}/>, type: 'success' },
                // error: 'Erro ao emitir nota fiscal',
            },
            {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }


    return ({ emitBackgroundInvoice, emitInvoice });
}

export default UseInvoice