import { ICartItem } from '../../domain/models/ICart';
import { ProductType } from '../../domain/models/ProductType';

export const CalcTotalsCartUseCase = (prevCartItems: ICartItem[]) => {
  const calcTotals = (_prevCartItems: ICartItem[]) => {
    const totalPrice = _prevCartItems
      .map(item => (item.quantity) * item.totalPrice)
      .reduce((acumulado, atual) => atual + acumulado, 0);
    const totalQuantity = _prevCartItems.map(item => item.product.type === ProductType.Kilo ? 1 : item.quantity).reduce((acumulado, atual) => atual + acumulado, 0);
    console.log('[TOTAL:]', totalPrice);
    return { totalQuantity: totalQuantity, totalPrice: totalPrice };
  };

  return calcTotals(prevCartItems);
};
