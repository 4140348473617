import React, { FC } from 'react'
import styles from './InvoiceSucessToast.module.scss'
import { Button, Icon } from '@mui/material'
export interface IInvoiceSucessToastProps {
    //propertys
    onClick?: () => void
    showPrinterButton?: boolean
}
const InvoiceSucessToast: FC<IInvoiceSucessToastProps> = ({ onClick, showPrinterButton}) => {
    return (
        <div id={styles.InvoiceSucessToast} >
            Nota fiscal emitida com sucesso{showPrinterButton && <Button fullWidth variant='text' className={styles.printButton} onClick={onClick}><Icon>print</Icon> Clique aqui para imprimir</Button>}
        </div>
    )
}
export default InvoiceSucessToast