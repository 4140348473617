import crypto, { AES } from 'crypto-js';
import localforage from 'localforage';
import { StateStorage } from 'zustand/middleware';
const EncryptedStorage = () => {

    const secreKey: string = "M33PS3CR3TK3Y"
    const disabledEncryption: boolean = true;

    const decode = (value: string) => {
        return AES.decrypt(value, secreKey).toString(crypto.enc.Utf8);
    }
    const encrypt = (value: string) => {
        return AES.encrypt(value, secreKey).toString();
    }

    const getItem = async (key: string) => {
        const value = await localforage.getItem<string>(key);
        if (disabledEncryption) {
            return value;
        }
        if (value) {
            return AES.decrypt(value, secreKey).toString(crypto.enc.Utf8);
        }
        return null;
    }

    const setItem = async (key: string, value: string) => {
        if (disabledEncryption) {
            await localforage.setItem(key, value);
            return;
        }
        await localforage.setItem(key, AES.encrypt(value, secreKey).toString());
    }

    const removeItem = async (key: string) => {
        return await localforage.removeItem(key);
    }

    return {
        getItem,
        setItem,
        removeItem,
        decode,
        encrypt
    }

}

export default EncryptedStorage;


