import React, { FC, PropsWithRef, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import styles from './TutorialCard.module.scss'
import { Backdrop, Button, Popover } from '@mui/material';
import icon from './TutorialIcon.svg'


export interface TutorialCardOptions {
    action?: () => void,
    onClose?: () => void,
    title?: string | ReactNode,
    description?: string | ReactNode,
    buttonLabel?: string | ReactNode,
    enabledBackdrop?: boolean,
    disabledClose?: boolean,
    anchorOrigin?: {
        vertical: 'top' | 'center' | 'bottom' | number;
        horizontal: 'left' | 'center' | 'right' | number;
    },
    transformOrigin?: {
        vertical: 'top' | 'center' | 'bottom' | number;
        horizontal: 'left' | 'center' | 'right' | number;
    },
    content?: React.ReactNode
    arrowPosition?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'left-top' | 'left-center' | 'left-bottom' | 'right-top' | 'right-center' | 'right-bottom'
}
export interface TutorialCardRef {
    open: (element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions) => void
    close: () => void
}

export interface ITutorialCardProps {
    //propertys
    // tutorialCard: {
    //     element: HTMLButtonElement | HTMLDivElement | null;
    //     options: TutorialCardOptions;
    // } | null
}

const TutorialCard = forwardRef<TutorialCardRef, ITutorialCardProps>((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | HTMLDivElement | null>(null);
    const [options, setOptions] = React.useState<TutorialCardOptions | null>(null);
    const [lastzIndex, setLastzIndex] = React.useState<string>();


    const openCard = useCallback((element: HTMLButtonElement | HTMLDivElement | null, options: TutorialCardOptions) => {
        setAnchorEl(element);
        setLastzIndex(element?.style.getPropertyValue('z-index'));
        // element?.style.setProperty('z-index', '10000');
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setOptions(options);
    }, []);

    const closeCard = useCallback(() => {

        anchorEl?.style.setProperty('z-index', lastzIndex || '');
        setAnchorEl(null);
        options?.onClose?.();

    }, [anchorEl, lastzIndex, options]);



    useImperativeHandle(
        ref,
        () => {
            return {
                open: openCard,
                close: closeCard
            };
        },
        [openCard, closeCard],
    )





    const open = Boolean(anchorEl);

    const arrow = useMemo(() => {
        if (options?.arrowPosition === 'top-left')
            return <div className={styles.arrowTopLeft} />
        if (options?.arrowPosition === 'top-center')
            return <div className={styles.arrowTopCenter} />
        if (options?.arrowPosition === 'top-right')
            return <div className={styles.arrowTopRight} />
        if (options?.arrowPosition === 'bottom-left')
            return <div className={styles.arrowBottomLeft} />
        if (options?.arrowPosition === 'bottom-center')
            return <div className={styles.arrowBottomCenter} />
        if (options?.arrowPosition === 'bottom-right')
            return <div className={styles.arrowBottomRight} />
        if (options?.arrowPosition === 'left-top')
            return <div className={styles.arrowLeftTop} />
        if (options?.arrowPosition === 'left-center')
            return <div className={styles.arrowLeftCenter} />
        if (options?.arrowPosition === 'left-bottom')
            return <div className={styles.arrowLeftBottom} />
        if (options?.arrowPosition === 'right-top')
            return <div className={styles.arrowRightTop} />
        if (options?.arrowPosition === 'right-center')
            return <div className={styles.arrowRightCenter} />
        if (options?.arrowPosition === 'right-bottom')
            return <div className={styles.arrowRightBottom} />
        return null;
    }, [options?.arrowPosition]);

    return (<>
        <Popover
            id={styles.TutorialCard}
            open={open}
            anchorEl={anchorEl}
            marginThreshold={8}
            PaperProps={{
                className: styles.paper
            }}
            style={{ zIndex: 10000 }}
            anchorPosition={{ top: 8, left: 0 }}
            onClose={!options?.disabledClose ? closeCard : undefined}
            anchorOrigin={options?.anchorOrigin ?? {
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={options?.transformOrigin ?? {
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {arrow}
            {
                options?.content ??
                <>
                    <div className={styles.header}>

                        <span>{options?.title}</span><img src={icon} alt="icon" />
                    </div>
                    <div className={styles.container}>
                        {options?.description}
                    </div>
                    <div className={styles.action}>
                        {options?.action && <Button variant='contained' color='secondary' fullWidth onClick={options?.action}>{options?.buttonLabel ?? 'Entendi'}</Button>}
                    </div>
                </>
            }
        </Popover>
        <Backdrop open={open && !!options?.enabledBackdrop} onClick={closeCard} />
    </>
    );
})
export default TutorialCard