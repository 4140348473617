import { IDevice } from "../../../../device/interface/Device";
import { IOperator } from "../../../../operator/domains/models/Operator";
import { ISession } from "../../../../session/domain/ISession";
import { PayAccountRequest, PayAccountResponse } from "../../../domain/dtos/PayAccountRequest";
import { IConsumeService } from "../../../domain/interfaces/IConsumeService";
import { IExtract } from "../../../domain/models/Extract";
import { PaymentType } from "../../../domain/models/PaymentType";

const PayAccountUseCase = async (service: IConsumeService,
    values: {
        session: ISession;
        operator: IOperator;
        extract: IExtract;
        amount?: number;
        device: IDevice;
        paymentId: string;
        orderId: string;
        paymentType: PaymentType;
        serviceFeeEnabled?: boolean;
        installments?: number;
        discount?: number;
        discountReason?: string;
        variableServiceFee?: number;
    }): Promise<PayAccountResponse> => {
    const request: PayAccountRequest = {
        currentSessionId: values.session.id,
        amount: values.amount ?? (values.serviceFeeEnabled ? values.extract.totalAmount : values.extract.totalToPayWithoutFee),
        accountId: values.extract.accountId,
        operatorId: values.operator.id,
        localId: values.device.localId,
        equipamentId: values.device.id,
        serviceFeeEnabled: values.serviceFeeEnabled ?? false,
        paymentId: values.paymentId,
        orderId: values.orderId,
        paymentType: values.paymentType,
        installments: values.installments ?? 1,
        // transactionResponse: "",
        discount: 0,
        discountOperator: "",
        discountReason: "",
        // operatorName: "",
        success: true,
    };
    return await service.payAccount(request);
};

export default PayAccountUseCase;


