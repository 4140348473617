import React, { FC, useEffect } from 'react'
import styles from './LoginSession.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import { Button, Icon } from '@mui/material'
import { IOperator } from '../../../../modules/operator/domains/models/Operator';
import Keyboard from '../../../../components/_keyboard/Keyboard';
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext';
export interface ILoginSessionProps {
    //propertys
    hasSession: boolean;
    onSubmit: (operator: IOperator) => void;
    validatePassword: (password: string) => Promise<IOperator | undefined>;
}
const LoginSessionForm: FC<ILoginSessionProps> = ({ onSubmit, validatePassword, hasSession }) => {
    const operatorInputRef = React.useRef<HTMLInputElement>(null);
    const [error, setError] = React.useState<string>();
    const { disabledVirtualKeyboard } = useKeyboard()


    useEffect(() => {
        operatorInputRef.current?.focus();
    }, [operatorInputRef])

    const onSubmitOpenSessionPassword = React.useCallback(async () => {
        const _operator = await validatePassword(operatorInputRef.current?.value as string);
        if (_operator) {
            onSubmit(_operator);
        } else {
            setError('Senha inválida');
        }
    }, [onSubmit, validatePassword])


    return (
        <div id={styles.LoginSession} >
            <div className={styles.container} >
                <h2>{hasSession ? `Digite a senha pra entrar no terminal.` : `Digite a senha para abrir novo caixa.`}</h2>
                <KeyboardInput showSubmitButton onSubmit={onSubmitOpenSessionPassword} inputRef={operatorInputRef} password error={error} label={'Senha do operador'} />
                {!disabledVirtualKeyboard && <Keyboard onSubmit={onSubmitOpenSessionPassword} inputRef={operatorInputRef} />}
                {/* <Button onSubmit={onSubmitOpenSessionPassword} variant={'outlined'} size='large'><Icon>login</Icon>Acessar Caixa</Button> */}
            </div>
        </div>
    )
}
export default LoginSessionForm