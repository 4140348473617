import { ISocketService } from "../../../../services/webSocketMqtt/MqttService"
import { IPaymentCieloPosRequest } from "../dtos/PaymentCieloPosRequest"
import { IPaymentCieloPosResponse } from "../dtos/PaymentCieloPosResponse"

const PaymentCieloPosUseCase = async (socketService: ISocketService, hubKey: string, request: IPaymentCieloPosRequest): Promise<IPaymentCieloPosResponse> => {
    console.log( `CIELO/${hubKey}/${request.id}`)
    const response = await socketService.sendMessageAndWaitResponse<IPaymentCieloPosResponse>({
        requestTopic: `CIELO/${hubKey}/${request.id}`,
        data: request
    }, 120000);
    return response.data
}
export default PaymentCieloPosUseCase