import { FC } from 'react'
import { IExtract } from '../../../../../modules/consume/domain/models/Extract'
import { moneyMaskNumber } from '../../../../../services/utils/Money';
import styles from './ExtractOrders.module.scss'
import { Button, Icon } from '@mui/material';
import { Print } from '@mui/icons-material';
export interface IExtracOrdersProps {
    //propertys
    extract: IExtract;
    hideResume?: boolean;
    printExtract?: () => Promise<void>;
}
    

const ExtractOrders: FC<IExtracOrdersProps> = ({ extract, hideResume, printExtract }) => {
    return (
        <div id={styles.ExtractOrders} >
            <div className={styles.container}>
                <div className={styles.containerOrder}>
                    {extract.orders.map((item) => item.number)}
                    {extract.orders.map(order => (
                        <div key={order.id} className={styles.orderContent}>
                            <span className={styles.textData}>{new Date(order.createdAt + "z").toLocaleString('pt-BR')}</span>
                            {order.items.map(orderItem => (
                                <div key={orderItem.id} className={styles.orderItemContainer} style={{ textDecoration: orderItem.isCanceled ? "line-through" : 'unset' }}>
                                    <div className={styles.orderItemHeader}>
                                        <span className={styles.label} style={{ opacity: orderItem.isCanceled ? 0.3 : 1 }}>
                                            {orderItem.quantity}x {orderItem.name} {orderItem.isCanceled && "(Cancelado)"}
                                        </span>
                                        <span className={styles.value}>R${(orderItem.quantity * orderItem.totalValue).toFixed(2).replace('.', ',')}</span>
                                    </div>
                                    {
                                        !!orderItem?.additionals?.length && <div className={styles.additionalsContainer}>
                                            {orderItem.additionals.map((additional, index) => (
                                                <div key={index} className={styles.contentAddicional}>
                                                    <label className={styles.text} >{additional.name}</label>
                                                    <div>
                                                        {additional.items.map((additionalItems, _index) => (
                                                            <div key={_index} className={styles.contentAddicionalItem}>
                                                                <span className={styles.text}>
                                                                    {additionalItems.quantity}x {additionalItems.name}
                                                                </span>
                                                                <p className={styles.text}>
                                                                    {moneyMaskNumber(additionalItems.unitValue * additionalItems.quantity)}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            <div>
                                                {orderItem.observations.map(observations => (
                                                    <div key={observations.question} className={styles.contentAddicional}>
                                                        <label className={styles.text}>{observations.question}</label>
                                                        <p className={styles.text}>-{observations.response}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>}

                                </div>
                            ))}
                            <div className={styles.orderTotal}>
                                <label>TOTAL</label>
                                <label>R${order.totalAmount.toFixed(2).replace('.', ',')}</label>
                            </div>
                        </div>
                    ))}
                </div>

                {!hideResume && (
                    <div className={styles.resumeContainer}>
                        {printExtract && <Button className={styles.printButton} size='small' onClick={printExtract} startIcon={<Print />}>Imprimir</Button>}
                        <div className={styles.contentResume}>
                            <p className={styles.label}>Total consumido:</p>
                            <p className={styles.value}>{moneyMaskNumber(extract.totalAmount)}</p>
                        </div>
                        {!!extract.paidAmount && (
                            <div className={styles.contentResume}>
                                <p className={styles.label}>Total já pago:</p>
                                <p className={styles.value}>{moneyMaskNumber(extract.paidAmount)}</p>
                            </div>
                        )}
                        {extract.serviceFeeConfig.serviceFeePercentage > 0 ? (
                            <>
                                <div className={styles.contentResume}>
                                    <p className={styles.label}>
                                        Taxa de Serviço ({extract.serviceFeeConfig.serviceFeePercentage * 100}%):
                                    </p>
                                    <p className={styles.value}>{moneyMaskNumber(extract.serviceFeeConfig.serviceFeeAmount)}</p>
                                </div>
                                <div className={styles.contentResume}>
                                    <p className={styles.labelTotal}>Total a pagar:</p>
                                    <p className={styles.valueTotal}>{moneyMaskNumber(extract.totalToPayWithFee)}</p>
                                </div>
                            </>
                        ) : (
                            <div className={styles.contentResume}>
                                <p className={styles.labelTotal}>Total a pagar</p>
                                <p className={styles.valueTotal}>{moneyMaskNumber(extract.totalToPayWithFee)}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default ExtractOrders