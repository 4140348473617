import { ISessionRepository } from "../domain/interfaces/ISessionRepository";
import { ISessionService } from "../domain/interfaces/ISessionService"

const CloseCurrentSessionUseCase = async (service: ISessionService, request: { localId: string, sessionId: string, operatorId: string }) => {
    const response = await service.closeSession(
        request.localId,
        request.sessionId,
        request.operatorId
    )


    return (response);



}
export default CloseCurrentSessionUseCase