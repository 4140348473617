import { FC } from 'react'
import { IExtract } from '../../../../../consume/domain/models/Extract';
import { moneyMaskNumber } from '../../../../../../cross-cutting/moneyMask';
export interface IExtracOrdersProps {
    //propertys
    extract: IExtract;
    table?: string;
}

const ExtractOrdersPrintLayout: FC<IExtracOrdersProps> = ({ extract, table }) => {
    return (
        <div style={{
            width: '200px',
            padding: 32,
            fontSize: '12px',
            fontFamily: 'Arial, sans-serif',
            display: 'flex', flexDirection: 'column', flex: 1
        }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <h3 style={{ margin: 0 }}>Extrato</h3>
                    {extract.user?.name && <span style={{ fontSize: '12px', margin: 0 }}>Nome: {extract.user?.name ?? "Nome não informado"}</span>}
                    {table && <span style={{ fontSize: '12px', margin: 0 }}>Mesa: {table}</span>}
                </div>
                <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    {/*{extract.orders.map((item) => item.number)}*/}
                    {extract.orders.map(order => (
                        <div key={order.id} style={{ marginBottom: 8, borderBottom: '1px dashed black' }}>
                            <span style={{ fontSize: '12px', margin: 0 }}>{new Date(order.createdAt + "z").toLocaleString('pt-BR')}</span>
                            {order.items.map(orderItem => (
                                <div key={orderItem.id} style={{ textDecoration: orderItem.isCanceled ? "line-through" : 'unset', borderBottom: "1px dotted black" }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{ opacity: orderItem.isCanceled ? 0.3 : 1 }}>
                                            {orderItem.quantity}x {orderItem.name} {orderItem.isCanceled && "(Cancelado)"}
                                        </span>
                                        <span style={{ fontSize: '12px', margin: '4px 0' }}>R${(orderItem.quantity * orderItem.totalValue).toFixed(2).replace('.', ',')}</span>
                                    </div>
                                    {!!orderItem?.additionals?.length && (
                                        <div style={{ padding: 4, fontSize: '12px', border: '1px dotted #0002', borderRadius: 8 }}>
                                            {orderItem.additionals.map((additional, index) => (
                                                <div key={index}>
                                                    <label style={{ fontWeight: 500 }}>{additional.name}</label>
                                                    <div>
                                                        {additional.items.map((additionalItems, _index) => (
                                                            <div key={_index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <span>{additionalItems.quantity}x {additionalItems.name}</span>
                                                                <p style={{ margin: 0, color: '#5f5f5f' }}>{moneyMaskNumber(additionalItems.unitValue * additionalItems.quantity)}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            <div>
                                                {orderItem.observations.map(observations => (
                                                    <div key={observations.question}>
                                                        <label>{observations.question}</label>
                                                        <p style={{ margin: 0, color: '#5f5f5f' }}>-{observations.response}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px' }}>
                                <label>TOTAL</label>
                                <label>R${order.totalAmount.toFixed(2).replace('.', ',')}</label>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ fontSize: 14, borderTop: "1px solid black", bottom: 8 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <b style={{}}>Total consumido:</b>
                        <b style={{}}>{moneyMaskNumber(extract.totalAmount)}</b>
                    </div>
                    {!!extract.paidAmount && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <b style={{}}>Total já pago:</b>
                            <b style={{}}>{moneyMaskNumber(extract.paidAmount)}</b>
                        </div>
                    )}
                    {extract.serviceFeeConfig.serviceFeePercentage > 0 ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <b style={{}}>
                                    Taxa de Serviço ({extract.serviceFeeConfig.serviceFeePercentage * 100}%):
                                </b>
                                <b style={{}}>{moneyMaskNumber(extract.serviceFeeConfig.serviceFeeAmount)}</b>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <b style={{}}>Total a pagar:</b>
                                <b style={{ fontWeight: 'bold', }}>{moneyMaskNumber(extract.totalToPayWithFee)}</b>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <b style={{}}>Total a pagar</b>
                            <b style={{ fontWeight: 'bold', }}>{moneyMaskNumber(extract.totalToPayWithFee)}</b>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}
export default ExtractOrdersPrintLayout
