
import { IOrderLogRepository } from "../../../orderLog/application/interfaces/IOrderRepository"
import { IInvoiceService } from "../../domain/interfaces/IInvoiceService"
import { IEmitInvoiceRequest } from "../dtos/IInvoiceData"

const EmitInvoiceUseCase = async (invoiceService: IInvoiceService, orderLogRepository: IOrderLogRepository, request: IEmitInvoiceRequest) => {
    try {
        const response = await invoiceService.EmitInvoice(request);
        return (response)

    } catch (error) {
        throw new Error("Não foi possivel emitir a nota fiscal. Tente novamente pelo historico de pedidos.")
    }
}
export default EmitInvoiceUseCase