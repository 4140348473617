import React, { FC, useCallback } from 'react'
import styles from './OrderLog.module.scss'
import { IOrderLog } from '../../modules/orderLog/domain/IOrderLog'
import OrderLogRepository from '../../services/db/OrderRepository'
import OrderLogList from './components/OrderLogList'
import { Pagination } from '@mui/material'
import UseInvoice from '../../modules/invoice/presentation/hooks/UseInvoice'
import MqttService from '../../services/webSocketMqtt/MqttService'
import SendToSocketPrinterUseCase from '../../modules/printer/application/SendToSocketPrinterUseCase'
import UseDeviceStore from '../../modules/device/store/DeviceStore'
import { toast } from 'react-toastify'
import UseSocketPrinter from '../../modules/printer/presentation/hooks/UseSocketPrinter'
import InvoicePrinterLayout from '../../modules/printer/presentation/layoutPrinter/invoice/InvoicePrinterLayout'
import QRCode from 'qrcode'
import UseOrderLog from './hook/UseOrderLog'
import UseSessionStore from '../../modules/session/store/SessionStore'
export interface IOrderLogProps {
    //propertys
}
const OrderLogFragment: FC<IOrderLogProps> = () => {

    const [orders, setOrders] = React.useState<{ items: IOrderLog[], page: number, pageSize: number, totalPages: number }>();
    const { emitInvoice } = UseInvoice();
    const [receiptPrinterSector] = UseDeviceStore(state => [state.device, state.receiptPrinterSector])
    const { getOrders, printInvoice } = UseOrderLog();
    const [session] = UseSessionStore(state => [state.session])

    React.useEffect(() => {
        getOrders({ page: 1 }).then((orders) => {
            setOrders(orders);
        });
    }, [getOrders]);

    return (
        <div id={styles.OrderLog} >
            {orders &&
                <>
                    <OrderLogList
                        orders={orders?.items}
                        emitInvoice={emitInvoice}
                        printInvoice={printInvoice}
                        disabledPrinter={!receiptPrinterSector}
                        disabledEmitInvoice={!session?.emitInvoice}
                    />
                    <Pagination
                        count={orders.totalPages}
                        page={orders.page}
                        onChange={(event, page) => {
                            getOrders({ page: page }).then((orders) => {
                                setOrders(orders);
                            });
                        }}
                    />
                </>
            }
        </div>
    )
}
export default OrderLogFragment