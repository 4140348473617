import React, { FC } from 'react';
import styles from './PaymentOption.module.scss';
import { IExtract } from '../../../../modules/consume/domain/models/Extract';
import { Switch } from '@mui/material';
import { moneyFormater } from '../../../../services/utils/Money';

interface IServiceFeeOptionProps {
    extract: IExtract; payServiceFee: boolean; onCheckPayServiceFeeHandle: (checked: boolean) => void;
}
const ServiceFeeOption: FC<IServiceFeeOptionProps> = ({ extract, payServiceFee, onCheckPayServiceFeeHandle }) => {
    return <>
        {!!extract.serviceFeeConfig?.serviceFeePercentage && !!extract.serviceFeeConfig &&
            <div className={styles.paymentOption}>
                <span>
                    Taxa de serviço: ({extract.serviceFeeConfig?.serviceFeePercentage * 100}%) <b>{moneyFormater(extract.serviceFeeConfig?.serviceFeeAmount)}</b>
                </span>
                <Switch checked={payServiceFee} onChange={(_, value) => onCheckPayServiceFeeHandle(value)} />
            </div>}
    </>;
};

export default ServiceFeeOption