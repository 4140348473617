import { CircularProgress } from '@mui/material';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { v4 } from 'uuid';
import Toast, { ToastRef, typeToast } from '../components/Toast';
import styles from './UiContext.module.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from './ThemeContext';
import TutorialCardProvider from '../../../onBoarding/presentation/components/TutorialCardProvider';
const __DEV__ = true;


interface IUiContext {
  toast: (_message: string, _type?: typeToast, _icon?: string, _time?: number) => void;
  fullScreenToast: (_message: string, _type?: typeToast, options?: { icon?: string, time?: number, sound?: boolean, action?: () => void }) => void;
}

const UiContext = createContext<IUiContext>({} as IUiContext);

export interface ILoadItem {
  id?: string;
  message?: string;
}

export const UiProvider: FC<{ children: React.ReactNode; }> = ({ children }) => {


  const toastRef = useRef<ToastRef>(null);

  const toast = ((_message: string, _type?: typeToast, _icon?: string, _time?: number) => {
    toastRef.current?.show(_message, _type, _icon, _time);
  });

  const fullScreenToast = ((message: string, type?: typeToast, options?: { icon?: string, time?: number, sound?: boolean, action?: () => void }) => {
    toastRef.current?.showFullScreen(message, type, options?.icon, options?.time, options?.sound, options?.action);
  });

  return (
    <UiContext.Provider
      value={{
        toast,
        fullScreenToast
      }}>

      <ThemeProvider>
        {children}
        <div>
          <Toast ref={toastRef} />
        </div>
      </ThemeProvider >
    </UiContext.Provider >
  );
};



export const useUi = () => {
  const context = useContext(UiContext);
  return context;
};
