import React, { FC } from 'react'
import styles from './LoadingAuth.module.scss'
import { PORTAL_URL } from "../../services/api/bases/Constants"
import { Skeleton } from '@mui/material'
export interface ILoadingAuthProps {
    //propertys
}
const LoadingAuth: FC<ILoadingAuthProps> = () => {
    return (
        <div id={styles.LoadingAuth} >
            <header>
                <img alt="" src={'/assets/img/logo-black.svg'}></img>
            </header>
            <div className={styles.container} >
               
            </div>
        </div>
    )
}
export default LoadingAuth