import { FC, useCallback, useMemo } from 'react'
import styles from './TablePicker.module.scss'
import { ITableItem } from './ITableItem'

export interface ITableItemProps {
    //propertys
    table: ITableItem
    onClickTable: (table: ITableItem) => void
}

const TableItem: FC<ITableItemProps> = ({ table, onClickTable }) => {
    const onClick = useCallback(() => {
        onClickTable(table);
    }, [onClickTable, table])

    const tableStyleStatus = useMemo(() => {
        if (!table.openedAt) {
            return styles.tableItemFree;
        } else if (table.startClosingAt) {
            return styles.tableItemClosing;
        } else {
            return styles.tableItemBuzy;
        }
    }, [table]);



    const name = useMemo(() => table.customer?.name?.split(' ').filter((_, index, array) => index === 0 || index === array.length - 1).join(' '), [table]);

    return (
        <div
            className={tableStyleStatus}
            id={styles.TableItem}
            onClick={onClick}
        >
            {/* <div className={styles.tableId} >
                {table.id}
            </div> */}

            {
                name ?
                    <>
                        <span className={styles.tableDescriptionName}>
                            {table.number}
                        </span>
                        <span className={styles.tableDescription} style={{ fontSize: name ? "16px" : "" }}>
                            {name}
                        </span>
                    </>
                    :
                    <>
                        <span className={styles.tableDescription} style={{ fontSize: name ? "16px" : "" }}>
                            {/* {name ?? table.number} */}
                            {table.number}
                        </span>
                    </>
            }
            {/* <div className={styles.consume} >
                {table.consume}
            </div> */}
            <div className={styles.chair1 + " " + tableStyleStatus} />
            <div className={styles.chair2 + " " + tableStyleStatus} />
            <div className={styles.chair3 + " " + tableStyleStatus} />
            <div className={styles.chair4 + " " + tableStyleStatus} />
        </div>
    )
}
export default TableItem