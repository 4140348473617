import React, { FC, RefObject, useCallback, useEffect, useMemo } from 'react'
import { OnBoardingSteps } from '../../../modules/onBoarding/domain/enums/OnBoardingSteps';
import UseOnboardingStore from '../../../modules/onBoarding/store/OnBoardingStore';
import { closeTutorialCard, openTutorialCard } from '../../../modules/onBoarding/store/TutorialCardStore';
import { IDevice } from '../../../modules/device/interface/Device';
import UseDeviceStore from '../../../modules/device/store/DeviceStore';
import { count } from 'console';
import OnBoardingService from '../../../services/api/onBoarding/OnBoardingService';
import { PORTAL_URL } from "../../../services/api/bases/Constants";
import { get } from 'http';
import { click } from '@testing-library/user-event/dist/click';



const UseTableMapTutorials = (tablesDivRef: RefObject<HTMLDivElement>, customerFormContainerRef: RefObject<HTMLDivElement>) => {
    const { getOnBoarding, isLoading: loadingOnboarding, onFinishStep, hasOnboardingStep, resetSteps } = UseOnboardingStore();
    const [device] = UseDeviceStore(state => [state.device, state.isSystemType]);
    const onBoardingService = useMemo(() => OnBoardingService(), []);



    const showTableTutorial = useCallback(async () => {
        const hasStep = await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.ChooseTableOrCounter);
        if (!hasStep) {
            openTutorialCard({
                element: tablesDivRef?.current,
                options: {
                    title: `Escolha lançar na mesa ou balcão`,
                    description: 'Clique na mesa que deseja abrir para lançar pedidos ou lance diretamente no balcão.',
                    arrowPosition: 'top-center',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: device?.localId ?? '',
                            step: OnBoardingSteps.ChooseTableOrCounter,
                        })
                        closeTutorialCard();
                    },
                }
            })
            return true;
        }
        return false;
    }, [device?.localId, hasOnboardingStep, onBoardingService, onFinishStep, tablesDivRef])

    const showGoToPainelTutorial = useCallback(async () => {
        const clickedTable = (await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.CongratulationsOrderPlacedTable));
        const clickedTicket = (await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.CongratulationsOrderPlacedTicket));


        const hasStepKitchenTicket = await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.OnSendOrderTicket);
        const hasStepKitchenTable = await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.OnSendOrder)


        const tableKDS = !clickedTable && hasStepKitchenTable;
        const ticketKDS = !clickedTicket && hasStepKitchenTicket;

        const openTutorial = (step: OnBoardingSteps) => {
            openTutorialCard({
                element: tablesDivRef?.current,
                options: {
                    title: `Parabéns! Você realizou um pedido!`,
                    description: 'Agora, vamos ver esse pedido no Gestor de Pedidos. Por lá, você consegue acompanhar tudo!',
                    arrowPosition: 'top-center',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: device?.localId ?? '',
                            step: step
                        })
                        closeTutorialCard();
                    },
                    action: () => {
                        document.exitFullscreen();
                        window.parent.postMessage((JSON.stringify({ action: "go-to-painel" })), "*");
                        closeTutorialCard();
                        window.open(PORTAL_URL + "/private/meepFood/painel", '_blank');
                    },
                    buttonLabel: 'Acessar Gestor de Pedidos'
                }
            })
        return true;
        }
        if (tableKDS) {
            openTutorial(OnBoardingSteps.CongratulationsOrderPlacedTable);
            return true;
        }
        if (ticketKDS) {
            openTutorial(OnBoardingSteps.CongratulationsOrderPlacedTicket);
            return true;
        }
        return false;
    }, [device?.localId, hasOnboardingStep, onBoardingService, onFinishStep, tablesDivRef])

    useEffect(() => {
        return () => {
            closeTutorialCard();
        }
    }, [])


    const showTutorials = useCallback(async () => {

        if (await showTableTutorial()) {
            return;
        }
        if (await showGoToPainelTutorial()) {
            return;
        }

    }, [showGoToPainelTutorial, showTableTutorial])

    useEffect(() => {
        showTutorials();
    }, [showTutorials])

    const resetOnBoarding = useCallback(async () => {
        resetSteps(onBoardingService, device?.localId ?? '');
    }, [device?.localId, onBoardingService, resetSteps])

    const showCustomerFormTutorial = useCallback(async () => {
        const hasStep = await hasOnboardingStep(onBoardingService, device?.localId ?? '', OnBoardingSteps.CustomerInformationForTable);
        if (!hasStep) {
            openTutorialCard({
                element: customerFormContainerRef?.current,
                options: {
                    title: `Preencha informações de quem está na mesa`,
                    description: `Isso é útil para criar estratégias e conhecer mais o seu cliente. \n \n
                    #DicadaMeep: sempre que possível, vale a pena preencher! ✅`,
                    arrowPosition: 'top-right',
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    onClose: () => {
                        onFinishStep(onBoardingService, {
                            localId: device?.localId ?? '',
                            step: OnBoardingSteps.CustomerInformationForTable,
                        })
                        closeTutorialCard();
                    }
                }
            })
        }
    }, [customerFormContainerRef, device?.localId, hasOnboardingStep, onBoardingService, onFinishStep]);


    return ({
        showTableTutorial,
        showCustomerFormTutorial,
        resetOnBoarding,
        showGoToPainelTutorial,
        loadingOnboarding
    })
}

export default UseTableMapTutorials