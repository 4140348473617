import React, { FC } from 'react';
import styles from './PaymnetExtractResume.module.scss';
import { IExtract } from '../../../../../modules/consume/domain/models/Extract';
import { moneyFormater } from '../../../../../services/utils/Money';

interface IPaymnetExtractResumeProps {
    extract: IExtract; payServiceFee: boolean; totalToPay: number; partialAmount: number;
}
export const PaymnetExtractResume: FC<IPaymnetExtractResumeProps> = ({ extract, payServiceFee, totalToPay, partialAmount }) => {
    return <div className={styles.extractResume}>
        <b>Resumo da conta</b>
        <div className={styles.total}>
            <label>Subtotal:</label><span>{moneyFormater(extract.totalAmount)}</span>
        </div>
        {/* {!!extract.serviceFeeConfig.serviceFeePercentage && */}
        {!!extract.serviceFeeConfig &&
            <div className={styles.total} style={{ textDecoration: payServiceFee ? "" : "solid line-through var(--secondary-color) 2px" }}>
                <label>taxa de serviço:({extract.serviceFeeConfig?.serviceFeePercentage * 100}%)</label><span> + {moneyFormater(extract.serviceFeeConfig.serviceFeeAmount)}</span>
            </div>}
        <div className={styles.total}>
            <label>Total consumido:</label><span>{moneyFormater(extract.totalAmount)}</span>
        </div>
        <div className={styles.total}>
            <label>Total pago:</label><span>{moneyFormater(extract.paidAmount)}</span>
        </div>
        <div className={styles.total}>
            <label>Total restante:</label><span>{moneyFormater(totalToPay)}</span>
        </div>
        <div className={styles.partial}>
            <div className={styles.value}>
                <label>Valor a ser pago:</label><span>{moneyFormater(partialAmount)}</span>
            </div>
            {partialAmount !== totalToPay &&
                <div className={styles.remain}>
                    <label>Valor restante:</label><span>{moneyFormater((payServiceFee ? extract.totalToPayWithFee : extract.totalToPayWithoutFee) - partialAmount)}</span>
                </div>
            }
        </div>
    </div>;
};
