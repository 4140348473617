import { IDeviceConfigResponse, IDeviceResponse } from '../../../../modules/device/domain/dtos/DeviceResponse';

import { Api } from '../../bases/Api';
import { IUsuarioMeepPos } from './IUsuarioMeepPos';

const AnticorruptionDeviceService = () => {
  const getDeviceConfig = async (deviceId: string, mac: string) => {
    const api = Api();
    console.log("device token", api.defaults.headers.common)
    const reponseConfigMeep = await api.post<IUsuarioMeepPos>('/UsuarioMeepPOS/AtualizarDadosEquipamento', {
      IMEI: mac,
      UsuarioMeepPOSId: deviceId,
    });

    return {
      ...configTotemMeepToDevice(reponseConfigMeep.data),
    };
  };

  const oterUsuarioMeepPosPorId = async (id: string): Promise<IDeviceResponse> => {
    const api = Api();
    const response = await api.get<{
      id: string,
      nome: string,
      imei: string,
      tipoDispositivo: number
      localNome: string,
      localId: string
    }>(`/UsuarioMeepPOS/BuscarUsuarioMeepPosPorId/${id}`);
    const parsed: IDeviceResponse = {
      id: response.data.id,
      localId: response.data.localId,
      localName: response.data.localNome,
      deviceName: response.data.nome,
      identifier: response.data.imei,
    }
    return parsed;
  }

  return { getDeviceConfig, oterUsuarioMeepPosPorId };
};
export default AnticorruptionDeviceService;



export const configTotemMeepToDevice = (configMeep: IUsuarioMeepPos): IDeviceConfigResponse => {
  const _device: IDeviceConfigResponse = {
    configSystem: configMeep.configuracaoSistemasGestao?.map(configGestao => ({
      systemType: configGestao.tipoSistema.id,
      printType: configGestao.tipoImpressao.id,
      enableRemotePrint: configGestao.impressoraRemotaHabilitada,
    })) ?? [],
    orderPadType: configMeep.tipoComandaEletronica.id,
    localServerAddress: configMeep.enderecoServidorLocal,
    // hasSplitInvoice: configMeep.temSplitFiscal,
    // generateEletronicInvoice: configMeep.gerarNfce,
    // mustPrintEletronicInvoice: configMeep.deveImprimirNFCe,
    // barCodeMode: configMeep.somenteLeitorCodigoBarra,
    // enableBarCode: configMeep.aceitaLeitorCodigoBarra,
    // printType: configMeep.tipoImpressao,
    // addressLocalServer: configMeep.enderecoServidorLocal,
    // printCategoryInProductName: configMeep.imprimirCategoriaProduto,
    // wallpaperImageUrl: configMeep.papelDeParedeUrl,
    // customerField: configMeep.camposClienteEstabelecimento?.toLocaleLowerCase().split(';') ?? [],
    // customerFieldEnable: configMeep.identificarClienteEstabelecimento,
    // customerFieldDisabled: configMeep.desativarIdentificacaoCliente,
    // paymentVoucher: configMeep.formaPagamentoVoucher,
    // orderPad:
    //   (
    //     JSON.parse(configMeep.comandas) as {
    //       id: string;
    //       numero: string;
    //     }[]
    //   )?.map(item => ({id: item.id, number: item.numero})) ?? [],

    // serviceRate: configMeep.deveCobrarTaxaServico,
    // serviceRatePercent: configMeep.percentualTaxaServico,
    // messageOnPayment: configMeep.mensagemAposPagamento ?? 'Pedido realizado com Sucesso',
    // printReceipt: configMeep.estaImpressaoComprovanteHabilitado,
    // newPrinter: true,
    // paymentCashless: configMeep.cartaoRotativo,
    // weighingMachineMode: true,

    // paymentDebit: configMeep.cartaoCredito,
    // paymentCredit: configMeep.cartaoDebito,
    // enableSchedule: configMeep.habilitarAgendamento,
    // onlyNextTime: true,
    // enableInstallment: false,
    // hideZeroPrice: true, //TODO: Oculta valores zerados
    //configMeep.agendamentoApenasProximoHorario,
    // newPrinter: configMeep.imprimeCartaoRfid
    // orderToGo: configMeep.devePerguntarSePedidoEParaViagem,
  };
  return _device;
};