import { ISession } from "../../../modules/session/domain/ISession";
import { ICloseSessionResponse, IGetIsOpenedSessionResponse, ISessionRequest, ISessionResponse } from "../../../modules/session/domain/dtos/ISessionDtos";
import { ISessionService } from "../../../modules/session/domain/interfaces/ISessionService"
import { Api } from "../bases/Api";
import AntiCorruptionSession from "./antiCorruption/AntiCorruptionSession";


const api = Api();
const SessionApi = (): ISessionService => {
    const isOpened = async (localId: string): Promise<IGetIsOpenedSessionResponse> => {
        return await AntiCorruptionSession(api).possuiSessaoAberta({ localId });
    };

    const openNewSession = async (request: ISessionRequest): Promise<ISessionResponse> => {
        return await AntiCorruptionSession(api).abrirNovaSessao(request);
    };

    const getCurrentSession = async (request: ISessionRequest): Promise<ISessionResponse> => {
        return await AntiCorruptionSession(api).obterSessaoCorrente(request);
    };

    const closeSession = async (localId: string, sessionId: string, operatorId: string): Promise<ICloseSessionResponse> => {
        return await AntiCorruptionSession(api).fecharSessao({ localId, sessionId, operatorId });
    }

    const logoutSession = async (request: { localId: string, sessionId: string, registerSessionId: string, operatorId: string }): Promise<ICloseSessionResponse> => {
        return await AntiCorruptionSession(api).sairSessaoCorrente(request);
    }
    return {
        isOpened,
        openNewSession,
        getCurrentSession,
        closeSession,
        logoutSession
    };
};
export default SessionApi