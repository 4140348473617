
import { SystemType } from '../domain/enums/SystemType';

export enum InputTableMode {
  MAP = 1,
}
export enum OperatorMode {
  ONE = 1,
}
export enum OrderPadType {
  SHARED = 1,
  INDIVIDUAL = 2,
}

export interface IDevice {
  id: string;
  localId: string;
  localName: string;
  deviceName: string;
  identifier: string;


  //TemporaryFlags
  disabledVerification?: boolean;
  getDeviceConfigOnSession?: boolean;
}


export interface IDeviceConfig {
  configSystem: {
    systemType: SystemType;
  }[];
  orderPadType: OrderPadType;
  localServerAddress: string;
  inputTableMode?: InputTableMode;
  fromStorage?: boolean;
}
