import { FC } from 'react';
import { CatalogProvider } from '../../modules/catalog/context/CatalogContext';
import { CatalogPage } from './CatalogPage';



export const CatalogPageWithProvider: FC<{ ticket?: boolean; disabled?:boolean }> = ({ ticket, disabled }) => {
    return (
        <CatalogProvider>
            <CatalogPage ticket={ticket} disabled={disabled} />
        </CatalogProvider>
    );
};
