import { IGetHubByOwnerIdResponse } from "../../../modules/hub/application/dtos/IGetHubByOwnerIdResponse";
import { IHubService } from "../../../modules/hub/domain/IHubService";
import { Api } from "../bases/Api";

const HubService = (): IHubService => {
    const api = Api();
    const getHubByLocalId = async (localId: string): Promise<IGetHubByOwnerIdResponse> => {
        const response = await api.get<IGetHubByOwnerIdResponse>(`/Hub/GetKeyByOwnerId/${localId}`);
        return response.data;
    }
    const getPrinterSector = async (sectorId: string): Promise<any> => {
        const response = await api.get<any>(`/PrintingSector/${sectorId}`, { headers: { disabledError: true } });
        return response.data;
    }

    return ({
        getHubByLocalId,
        getPrinterSector
    })
}
export default HubService