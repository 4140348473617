import React, { FC, PropsWithChildren, PropsWithRef, forwardRef, useEffect, useImperativeHandle } from 'react'
import styles from './OperatorModalValidate.module.scss'
import KeyboardInput from '../../../../../components/_keyboard/_KeyboardInput';
import Keyboard from '../../../../../components/_keyboard/Keyboard';
import { Drawer, Icon, IconButton } from '@mui/material';
import UseValidateOperator, { IValidateOperatorResponse } from '../../UseValidateOperator';
import { useKeyboard } from '../../../../../components/_keyboard/KeyboardContext';

export interface IOperatorFormProps {
    //propertys
}

export interface IOperatorModalValidateRef {
    validate: () => Promise<IValidateOperatorResponse>;
    close: () => void;
}
const OperatorModalValidate = forwardRef<IOperatorModalValidateRef, PropsWithChildren<IOperatorFormProps>>(({ children }, ref: React.Ref<IOperatorModalValidateRef>) => {
    const { validateModalOpen, onOnSubmitPressValidate, validateOperator, onPressCloseValidateModal, error } = UseValidateOperator();
    const { disabledVirtualKeyboard } = useKeyboard();
    const operatorInputRef = React.useRef<HTMLInputElement>(null);


    const animationEndHandle = React.useCallback(() => {
        if (operatorInputRef.current && validateModalOpen) {
            operatorInputRef.current.focus();
        }
    }, [operatorInputRef, validateModalOpen])

    useImperativeHandle(ref, () => ({
        validate: validateOperator,
        close: onPressCloseValidateModal
    }), [onPressCloseValidateModal, validateOperator]);


    const onSubmitOpenSessionPassword = React.useCallback(async () => {
        onOnSubmitPressValidate(operatorInputRef.current?.value as string);
    }, [onOnSubmitPressValidate])


    return (
        <Drawer onAnimationEnd={animationEndHandle} anchor='right' open={validateModalOpen} onClose={() => onPressCloseValidateModal()}>
            <div id={styles.OperatorModalValidate} >
                <div className={styles.container} >
                    <h2>Confirmar operador</h2>
                    <IconButton className={styles.close} onClick={() => onPressCloseValidateModal()}><Icon>close</Icon></IconButton>
                    {children}
                    <KeyboardInput inputMode='numeric' onSubmit={onSubmitOpenSessionPassword} inputRef={operatorInputRef} password error={error} label={'Senha do operador'} showSubmitButton />
                    {!disabledVirtualKeyboard && <Keyboard onSubmit={onSubmitOpenSessionPassword} type='numeric' inputRef={operatorInputRef} />}
                </div>
            </div>
        </Drawer>
    )
})
export default OperatorModalValidate