import { ICancelOrderFormValue, ICancelOrderItemSelectable } from "../../../../../pages/catalog/components/cancelOrderForm/CancelOrderForm"
import { IDevice } from "../../../../device/interface/Device"
import { IOperator } from "../../../../operator/domains/models/Operator"
import { AccountCancelItemsRequest } from "../../../domain/dtos/AccountCancelItemsRequest"
import { IConsumeService } from "../../../domain/interfaces/IConsumeService"

const CancelAccountItemsUseCase = async (service: IConsumeService, values: { cancelOrder: ICancelOrderItemSelectable[], operator: IOperator, accountId: string, device: IDevice, annotation: string }) => {
    const request: AccountCancelItemsRequest = {
        localId: values.device.localId,
        accountId: values.accountId,
        items: values.cancelOrder.map(item => ({
            accountHistoryItemId: item.id,
            quantity: item.quantity
        })),
        operatorId: values.operator.id,
        annotation: values.annotation,
        equipamentId: values.device.id,
    }
    const response = await service.cancelAccountItems(request)
    return
}
export default CancelAccountItemsUseCase