function getCombinationOfNotes(valor: number): number[] {
    // Implementation goes here
    const moedas: number[] = [1, 2, 5, 10, 20, 50, 100, 200];
    let possiveisTrocos: (number)[] = [];
    let lastValue: number = -1;
    let i: number

    moedas.forEach((moeda, index) => {
        let trocoResto: number = 0
        let trocoRedondo: number = 0
        trocoResto = valor - Math.floor(valor / moeda);
        trocoRedondo = Math.floor(valor / moeda) * moeda;
        let value: number
        if (index === 0)
            for (i = 0; i < moedas.length; i++) {
                value = trocoRedondo + moedas[i]
                if (value > valor) {
                    if (value !== lastValue) {
                        lastValue = value;
                        if (value !== valor)
                            if (possiveisTrocos.findIndex(x => x === value) === -1)
                                possiveisTrocos.push(value);
                    }
                    break
                }
            }

        if (trocoResto > 0) {
            value = trocoRedondo + moeda
        }
        else {
            value = trocoRedondo
        }

        if (value !== lastValue) {
            lastValue = value;
            if (value !== valor)
                if (possiveisTrocos.findIndex(x => x === value) === -1)
                    possiveisTrocos.push(value);
        }
    })
    return possiveisTrocos
}


export { getCombinationOfNotes };
