
import { FC, useCallback, useMemo } from 'react'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import styles from './CatalogPage.module.scss'
import CartView from './components/cartView/CartView'
import ExtractResume from './components/extracResume/ExtractResume'
import UseCatalogPage from './UseCatalogPage'
import CatalogV2 from './components/catalogV2/CatalogV2'
import UseDimensions from '../../components/dimension/UseDimension'
import CartBar from './components/cartView/cartModal/CartBar'
import { Drawer, Icon, IconButton } from '@mui/material'
import PayAccountForm from '../TableMap/components/payAccountForm/PayAccountForm'
import { PaymentType } from '../../modules/consume/domain/models/PaymentType'
import { useCartStore } from '../../modules/cart/infra/store/CartStore'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { queryParamsJSON } from '../../cross-cutting/queryParamsJSON'
import PayTicketForm from './components/payTicketOrder/_PayTicketOrderForm'
import OrderPadForm from './components/orderPadForm/OrderPadForm'
import { OperationMode } from '../../modules/device/presentation/context/OperationMode'
import UseDeviceStore from '../../modules/device/store/DeviceStore'
import CancelOrderForm from './components/cancelOrderForm/CancelOrderForm'
import UseCatalogTutorials from './hooks/UseCatalogTutorials'
import ISimplePos from '../../modules/simplePos/model/ISimplePos'
import { ICustomerFormValue } from '../TableMap/components/customerForm/ICustomerFormValue'
export interface ICatalogPageProps {
    //propertys
    ticket?: boolean;
    disabled?: boolean;
}
export const CatalogPage: FC<ICatalogPageProps> = ({ ticket, disabled }) => {
    const { isMobile, width } = UseDimensions();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { showProductItemTutorialHandle, showSendOrderTutorialHandle } = UseCatalogTutorials(ticket);

    const {
        extract,
        orderPad,
        tag,
        number,
        onLoadingExtract,
        onClickBackHandle,
        sendOrderPosPaySharedOrderPadHandler,
        sendOrderPosPayIndividualOrderPadHandler,
        onSubmitPayment,
        closeAccountHandle,
        sendOrderTicketHandler,
        cancelAccoutOrderItemsHandler,
        disabledCart,
        printExtractHandler,
        receiptPrinterSector
    } = UseCatalogPage(ticket);

    const disabledPayment = false;

    const [cartItems, totals] = useCartStore(state => [state.cartItems, state.totals]);
    const [operationMode, simplePos] = UseDeviceStore(state => [state.operationMode, state.simplePos]);

    const onClickCloseAccountButtonHandle = useCallback(() => {
        closeAccountHandle();
    }, [closeAccountHandle])

    const onClickPayAccountButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openPayAccount: true }));
    }, [setSearchParams])

    const onClickClosePayAccountButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openPayAccount: undefined }), { replace: true });
    }, [setSearchParams])

    const onClickPayTicketButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openPayTicket: true }));
    }, [setSearchParams])

    const onClickCloseTicketButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openPayTicket: undefined }), { replace: true });
    }, [setSearchParams])

    const onClickOpenCartButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openCart: true }));
    }, [setSearchParams])


    const onClickCloseCartButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openCart: undefined }), { replace: true });
    }, [setSearchParams])

    const onClickOpenCancelOrderButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openCancelOrder: true }));
    }, [setSearchParams])

    const onClickCloseCancelOrderButtonHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openCancelOrder: undefined }), { replace: true });
    }, [setSearchParams])

    const OnClickSubmitPaymentForm = useCallback(async (paymentType: PaymentType, amount: number, payServiceFee?: boolean, simplePos?: ISimplePos | null) => {
        onSubmitPayment(paymentType, amount, payServiceFee, simplePos);
    }, [onSubmitPayment])

    const OnClickSubmitTicketPaymentForm = useCallback(async (paymentType: PaymentType, customerValues?: ICustomerFormValue, amount?: number, simplePos?: ISimplePos | null) => {
        sendOrderTicketHandler(paymentType, customerValues, simplePos ?? undefined);
    }, [sendOrderTicketHandler])

    const onClickReadOrderPad = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openReadOrderPad: true }));
    }, [setSearchParams])

    const onClickCloseReadOrderPadnHandle = useCallback(() => {
        setSearchParams(prev => queryParamsJSON(prev, { openReadOrderPad: undefined }), { replace: true });
    }, [setSearchParams])

    const onClickSendOrder = useCallback(() => {
        if (ticket) {
            onClickPayTicketButtonHandle()
        } else if (operationMode === OperationMode.TABLE) {
            sendOrderPosPaySharedOrderPadHandler();
        } else if (operationMode === OperationMode.ORDER_PAD) {
            if (!extract) {
                onClickReadOrderPad();
            } else {
                sendOrderPosPayIndividualOrderPadHandler();
            }
        }
    }, [extract, onClickPayTicketButtonHandle, onClickReadOrderPad, operationMode, sendOrderPosPayIndividualOrderPadHandler, sendOrderPosPaySharedOrderPadHandler, ticket]);

    const onSubmitGetExtractByOrderPadTag = useCallback((value: string) => {
        navigate(`/catalog/orderPad/${value}`, { replace: true });
        // onClickCloseReadOrderPadnHandle();
    }, [navigate])


    const callProductItemTutorial = useCallback((productItemRef: React.RefObject<HTMLDivElement>) => {
        showProductItemTutorialHandle(productItemRef);
    }, [showProductItemTutorialHandle])

    // // const image = useMemo(() => authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : "/assets/img/LogoMeep.svg", [authentication?.local])
    // const image = useMemo(() => authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : undefined, [authentication?.local])



    /* params to open Modals */
    const verticalCategory = useMemo(() => width < 1500, [width])
    const openPayAccount = useMemo(() => searchParams.get('openPayAccount') === 'true', [searchParams]);
    const openPayTicket = useMemo(() => searchParams.get('openPayTicket') === 'true', [searchParams]);
    const openReadOrderPad = useMemo(() => searchParams.get('openReadOrderPad') === 'true', [searchParams]);
    const openCart = useMemo(() => searchParams.get('openCart') === 'true', [searchParams]);
    const openCancelOrder = useMemo(() => searchParams.get('openCancelOrder') === 'true', [searchParams]);

    return (
        <div id={styles.CatalogPage} >
            <Toolbar onClickBack={onClickBackHandle} />
            <div className={styles.container} >
                <div className={styles.catalogListContainer} >
                    <CatalogV2 isMobile={verticalCategory} callTutorial={callProductItemTutorial} disabledCart={disabledCart || disabled} />
                    {/* <CatalogList /> */}
                </div>
                {!isMobile && !disabledCart &&
                    <div className={styles.cartContainer} >
                        {!ticket &&
                            <ExtractResume
                                onClickCancelItem={onClickOpenCancelOrderButtonHandle}
                                onLoading={onLoadingExtract}
                                number={number}
                                tag={tag}
                                orderPad={orderPad}
                                extract={extract}
                                onClickReadTag={onClickReadOrderPad}
                                printExtract={receiptPrinterSector && printExtractHandler}
                            />
                        }

                        <CartView
                            callTutorial={showSendOrderTutorialHandle}
                            extract={extract}
                            disabledSendButton={!extract || !cartItems.length}
                            onClickSendButton={onClickSendOrder}
                            onClickCloseAccountButton={onClickCloseAccountButtonHandle}
                            onClickPayAccountButton={onClickPayAccountButtonHandle}
                            disabledPayment={disabledPayment}
                        />
                    </div>
                }
                {
                    isMobile &&
                    <CartBar
                        callTutorial={showSendOrderTutorialHandle}
                        extract={extract}
                        className={styles.cartBar}
                        visible={!!extract || !!cartItems.length}
                        onClickSendButton={onClickOpenCartButtonHandle}
                        onClickCloseAccountButton={onClickOpenCartButtonHandle}
                        onClickPayAccountButton={onClickOpenCartButtonHandle}
                        disabledPayment={disabledPayment}
                    />
                }
                <Drawer anchor='right' open={openCart}
                    onClose={onClickCloseCartButtonHandle}
                >
                    <div className={styles.cartContainer} style={{ paddingTop: 50 }} >
                        <IconButton onClick={onClickCloseCartButtonHandle} className={styles.closeButton}><Icon >close</Icon></IconButton>
                        {
                            extract && !ticket &&
                            <ExtractResume
                                onClickCancelItem={onClickOpenCancelOrderButtonHandle}
                                onLoading={onLoadingExtract}
                                number={number}
                                tag={tag}
                                orderPad={orderPad}
                                extract={extract}
                                onClickReadTag={onClickBackHandle}
                                printExtract={printExtractHandler}

                            />
                        }
                        {<CartView
                            callTutorial={showSendOrderTutorialHandle}
                            disabledPayment={disabledPayment}
                            extract={extract}
                            disabledSendButton={!cartItems.length}
                            onClickSendButton={onClickSendOrder}
                            onClickCloseAccountButton={onClickCloseAccountButtonHandle}
                            onClickPayAccountButton={onClickPayAccountButtonHandle}
                        />}
                    </div>
                </Drawer>
                {extract &&
                    <Drawer anchor='right' open={openPayAccount} onClose={onClickClosePayAccountButtonHandle} >
                        <PayAccountForm
                            onClose={onClickClosePayAccountButtonHandle}
                            extract={extract}
                            onSubmit={OnClickSubmitPaymentForm}
                            simplePosList={simplePos}
                        />
                    </Drawer>
                }
                {cartItems &&
                    <Drawer anchor='right' open={openPayTicket} onClose={onClickCloseTicketButtonHandle} >
                        <PayTicketForm
                            onClose={onClickCloseTicketButtonHandle}
                            orderItems={cartItems}
                            amountToPay={totals.totalPrice}
                            onSubmit={OnClickSubmitTicketPaymentForm}
                            simplePosList={simplePos}
                        // simplePosList={[]}
                        />
                    </Drawer>
                }
                {
                    <Drawer anchor='right' open={openReadOrderPad} onClose={onClickCloseReadOrderPadnHandle} >
                        <OrderPadForm onClose={onClickCloseReadOrderPadnHandle} onSubmit={onSubmitGetExtractByOrderPadTag} />
                    </Drawer>
                }
                {
                    extract &&
                    <Drawer anchor='right' open={openCancelOrder} onClose={onClickCloseCancelOrderButtonHandle} >
                        <CancelOrderForm
                            onClose={onClickCloseCancelOrderButtonHandle}
                            extract={extract}
                            onSubmit={cancelAccoutOrderItemsHandler} />
                    </Drawer>
                }
            </div>
        </div>
    )
}
export default CatalogPage


