import { Drawer, Icon } from '@mui/material';
import React, { useRef, useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import styles from './Toast.module.scss'

export type typeToast = 'success' | 'warning' | 'error' | 'info';
interface ToastProps {
  theme?: {
    colors?: {
      warn?: string;
      erro?: string;
      info?: string;
      sucess?: string;
    };
  };
}
export interface ToastRef {
  show: (_message: string, _type?: typeToast, _icon?: string, _time?: number, sound?: boolean) => void;
  showFullScreen: (_message: string, _type?: typeToast, _icon?: string, _time?: number, sound?: boolean, action?: () => void) => void;
}

const Toast = forwardRef<ToastRef, ToastProps>(({ theme }, ref) => {
  const [message, setMessage] = useState('');
  const [type, setType] = useState<typeToast>('info');
  const [sound, setSound] = useState(false);
  const [showFullScreenToast, setShowFullScreenToast] = useState(false);

  useEffect(() => {
    if (showFullScreenToast && type === 'success' && sound) {
      new Audio('/assets/sounds/sucess.mp3').play();
    }
  }, [showFullScreenToast, sound, type])


  const timer = useRef<NodeJS.Timeout>()

  const color = React.useMemo(() => {
    switch (type) {
      case 'success':
        return theme?.colors?.sucess ?? '#1a9';
      case 'warning':
        return theme?.colors?.sucess ?? '#fa2';
      case 'info':
        return theme?.colors?.sucess ?? '#1ac';
      case 'error':
        return theme?.colors?.sucess ?? '#a30';
    }
  }, [type, theme]);

  const icon = React.useMemo(() => {
    switch (type) {
      case 'success':
        return "done";
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      case 'error':
        return 'erro';
    }
  }, [type]);

  const show = useCallback(
    (_message: string, _type: typeToast = 'info', _icon = 'info', _time = 3000) => {
      //implement
      toast(_message, { type: _type, autoClose: _time })
    },
    [],
  );

  const showFullScreen = useCallback(
    (_message: string, _type: typeToast = 'info', _icon = 'info', _time = 2000, sound?: boolean, action?: () => void) => {
      //implement
      // toast(_message, { type: _type, autoClose: _time });
      setMessage(_message);
      setType(_type);
      setShowFullScreenToast(true);
      if(sound !== undefined)
      setSound(sound);

      timer.current = setTimeout(() => {
        setShowFullScreenToast(false);
        clearTimeout(timer.current)
        action && action();
      }, _time)
    },
    [],
  );

  useEffect(() => {


    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])


  useImperativeHandle(ref, () => ({ show, showFullScreen }), [show, showFullScreen]);

  return (
    <div>
      <ToastContainer theme='colored' position='bottom-center' />
      <Drawer anchor='bottom' open={showFullScreenToast} >
        <div className={styles.fullscreenToast} style={{ backgroundColor: color }}>
          <div className={styles.icon}>
            <Icon>{icon}</Icon>
          </div>
          <div className={styles.text}>
            <span>
              {message}
            </span>
          </div>
        </div>
      </Drawer>
    </div>
  );
});

Toast.displayName = 'MyComponent';

export default Toast