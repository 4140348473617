import { IOrder } from "../../cart/domain/models/IOrder"
import { IOperator } from "../../operator/domains/models/Operator";
import { ISession } from "../../session/domain/ISession";
import { IOrderLog } from "../domain/IOrderLog";
import { IOrderLogRepository } from "./interfaces/IOrderRepository"

const AddOrderLogUseCase = async (repository: IOrderLogRepository, order: IOrder, operator: IOperator, session: ISession) => {
    try {
        const data: IOrderLog = {
            ...order,
            session,
            operator,
            createdAt: new Date()
        }
        const lastOrder = await repository.getLastOrder();
        if (lastOrder && lastOrder.session?.id !== session.id) {
            repository.clearOrderLogs();
        }
        await repository.createOrder(data);
    } catch (e) {
        console.log(e)
    }
}
export default AddOrderLogUseCase



