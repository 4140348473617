import React, { FC, useCallback } from 'react'
import PaymentCieloPosUseCase from '../../application/useCases/PaymentCieloPosUseCase'
import MqttService from '../../../../services/webSocketMqtt/MqttService'
import { IPaymentCieloPosRequest } from '../../application/dtos/PaymentCieloPosRequest'
import UseDeviceStore from '../../../device/store/DeviceStore'
import { v4 } from 'uuid'
const UsePaymentCieloPos = () => {

    const [device, hub, getHub, receiptPrinterSector, simplePos] = UseDeviceStore(state => [state.device, state.hub, state.getHub, state.receiptPrinterSector, state.simplePos])


    const paymentCieloPos = useCallback(async (deviceId: string, orderId: string, amount: number, simplePosId?: string) => {
        if (!device?.localId)
            throw new Error('Dispositivo não encontrado.')
        const mqttService = MqttService()
        const request: IPaymentCieloPosRequest = {
            id: v4(),
            orderId: orderId,
            deviceId: deviceId,
            amount: amount * 100,
            posId: simplePosId,
            receiptPrinter: receiptPrinterSector?.printerName,
        }
        var hub = await getHub(device.localId)
        if (hub.key) {
            const response = await PaymentCieloPosUseCase(mqttService, hub.key, request)
            return response;
        } else {
            throw new Error('Hub não encontrado atualize o dispositivo ou tente novamente.')
        }
    }, [device?.localId, getHub, receiptPrinterSector?.printerName])

    return ({ paymentCieloPos })
}

export default UsePaymentCieloPos