
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition';
import { useCart } from '../../../../modules/cart/presentation/contexts/CartContext';

import { IProduct } from '../../../../modules/catalog/domain/models/IProduct';
import { IProductComposition } from '../../../../modules/catalog/domain/models/IProductComposition';
import { ITotalsComposition } from '../productComposition/interfaces/ITotalsComposition';
import { ProductType } from '../../../../modules/catalog/domain/models/ProductType';

const UseProductItem = (product: IProduct, onClickAddProduct: (product: IProduct, quantity: number, compositions?: IOrderComposition[], notes?: string) => void, ref: React.RefObject<HTMLInputElement | HTMLDivElement>, scrollToProduct?: (inputRef: React.RefObject<HTMLInputElement | HTMLDivElement>, block?: 'start' | 'center') => void) => {

    const [quantity, setQuantity] = useState(1);
    const [observationField, setObservationField] = useState<string>()
    const [compositions, setCompositions] = useState<{ orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[] }>();
    const [disabledAddProduct, setDisabledAddProduct] = useState(true);
    const [openProduct, setOpenProduct] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    const [wheight, setWheight] = useState<number>(0)

    const onClickProduct = useCallback(() => {
        if (product.compositions?.length || product.type === ProductType.Kilo) {
            setOpenProduct(true);
            scrollToProduct && scrollToProduct(ref, 'start')
            setSelectedProduct(product)
        } else {
            onClickAddProduct(product, 1)
        }
    }, [onClickAddProduct, product, ref, scrollToProduct])


    useEffect(() => {
        if (!!compositions?.unvalidatedCompositions.length) {
            setDisabledAddProduct(true)
        } else {
            setDisabledAddProduct(false)
        }
    }, [compositions])


    const onClickAddQuantityHandle = useCallback(() => {
        setQuantity(prev => prev + 1);
    }, [])
    const onClickRemoveQuantityHandle = useCallback(() => {
        setQuantity(prev => prev > 1 ? prev - 1 : 1);
    }, [])

    const onClickAddProductHandle = useCallback(
        () => {
            if (product) {

                const _composition: IOrderComposition[] = [...(compositions?.orderComposition ? compositions.orderComposition : [])]
                const quantityToAdd = product.type === ProductType.Kilo ? (wheight - (product.tare ?? 0)) : quantity;
                onClickAddProduct(product, quantityToAdd, _composition, observationField);
                setQuantity(1);
                setWheight(0);
                setOpenProduct(false);
            }

        },
        [product, compositions?.orderComposition, wheight, quantity, onClickAddProduct, observationField],
    )
    const onChangeCompositionHandle = useCallback(
        (orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[]) => {
            setCompositions({ orderComposition, totals, unvalidatedCompositions })
        },
        [],
    )

    const handleChangeObservation = (value: string) => {
        setObservationField(value);
    };
    const handleSubmmitObservation = useCallback(() => {
        onClickAddProductHandle()

    },[onClickAddProductHandle])


    return (
        {
            product,
            compositions,
            quantity,
            onClickAddQuantityHandle,
            onClickRemoveQuantityHandle,
            onClickAddProductHandle,
            onChangeCompositionHandle,
            handleChangeObservation,
            handleSubmmitObservation,
            disabledAddProduct,
            onClickProduct,
            openProduct,
            selectedProduct,
            setOpenProduct,
            wheight,
            setWheight
        }
    )
}
export default UseProductItem
