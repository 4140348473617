import { useCallback } from "react";
import { IOrder } from "../../../cart/domain/models/IOrder";
import { IExtract } from "../../../consume/domain/models/Extract";
import { SystemType } from "../../../device/domain/enums/SystemType";
import { PrintTypeEnum } from "../../../device/interface/DeviceProfile";
import UseDeviceStore from "../../../device/store/DeviceStore";
import useOperatorStore from "../../../operator/store/OperatorStore";
import OrderTicketPrinterLayout from "../layoutPrinter/order/OrderTicketPrinterLayout";
import UseSocketPrinter from "./UseSocketPrinter";
import QRCode from "qrcode";
import { ITicketPrintOrder, orderToPrintTicket } from "../../models/ITicketPrintOrder";
import { v4 } from "uuid";
import { IOperator } from "../../../operator/domains/models/Operator";
import { IDevice } from "../../../device/interface/Device";
import { toast } from "react-toastify";
import ExtractOrdersPrintLayout from "../layoutPrinter/extract/extracResume/ExtractOrdersPrintLayout";
import { useShallow } from "zustand/react/shallow";


const UsePrinter = () => {

	const [device, deviceConfig, receiptPrinterSector] = UseDeviceStore(useShallow(state => [state.device, state.deviceConfig, state.receiptPrinterSector]));
	const [currentOperator] = useOperatorStore((state) => ([state.currentOperator]));
	const { sendBackgroundPrinter, getElementHtml } = UseSocketPrinter();


	const generateQR = async text => {
		return await QRCode.toDataURL(text, { width: 500 })
	}

	const printTicket = useCallback(async (tickets: ITicketPrintOrder[], systemType: SystemType) => {
		try {
			if (!device) {
				throw new Error("Nenhum dispositivo conectado")
			}
			if (!receiptPrinterSector) {
				return;
				//throw new Error("Nenhuma impressora configurada")
			}

			tickets.forEach(async (ticket) => {
				const sanitizedId = ticket.id.replace(/[^a-zA-Z0-9]/g, '');
				const qrCode = await generateQR(sanitizedId);
				const html = await getElementHtml(
					<OrderTicketPrinterLayout
						ticket={ticket}
						qrCode={qrCode}
					/>
				)
				console.log(html);
				await sendBackgroundPrinter(ticket.id, receiptPrinterSector?.printerName ?? "", html)
			})

		} catch (error: any) {
			throw new Error(error?.message ?? "Erro ao imprimir nota fiscal")
		}
	}, [device, getElementHtml, receiptPrinterSector, sendBackgroundPrinter])



	const printExtract = useCallback(async (extract: IExtract, table?: string) => {
		try {
			if (!device) {
				throw new Error("Nenhum dispositivo conectado")
			}
			if (!receiptPrinterSector) {
				throw new Error("Nenhuma impressora configurada")
			}
			if (!!extract?.orders.length) {
				const html = await getElementHtml(
					//<ExtractPrinterLayout
					//	extract={extract}
					//	device={device}
					//	currentOperator={currentOperator}
					///>
					<ExtractOrdersPrintLayout extract={extract} table={table} />
				)
				console.log(html);
				await sendBackgroundPrinter(extract.accountId, receiptPrinterSector?.printerName ?? "", html)
			}
		} catch (error: any) {
			throw new Error(error?.message ?? "Erro ao imprimir nota fiscal")
		}
	}, [device, getElementHtml, receiptPrinterSector, sendBackgroundPrinter])

	const printString = useCallback(async (text: string) => {
		try {
			if (!device) {
				throw new Error("Nenhum dispositivo conectado")
			}
			if (!receiptPrinterSector) {
				console.log("Nenhuma impressora configurada")
				return;
			}
			const formattedText = text.split('\n').map(line => `<div>${line}</div>`).join('');
			await sendBackgroundPrinter(v4(), receiptPrinterSector?.printerName ?? "", `<div style="padding: 0 16px;">${formattedText}</div>`)
		} catch (error: any) {
			throw new Error(error?.message ?? "Erro ao imprimir nota fiscal")
		}
	}, [device, receiptPrinterSector, sendBackgroundPrinter])

	const printTicketOrder = useCallback(async (order: IOrder, systemType: SystemType, device: IDevice, operator: IOperator, table?: string, codePanel?: string) => {
		const operationConfig = deviceConfig?.operation.methods.find(method => method.type === systemType);
		switch (operationConfig?.printType) {
			case PrintTypeEnum.TICKET || PrintTypeEnum.SIMPLE_RESUME:
				const printOrder = orderToPrintTicket(order, device, operator, table, codePanel);
				printTicket(printOrder, systemType);
				break;
			case PrintTypeEnum.RESUME:
				toast("Tipo de impressão Resumo não implementado");
				break;
			default:
				toast("Tipo de impressão não configurado");
				break;
		}
	}, [deviceConfig?.operation.methods, printTicket])



	const printPosPayment = useCallback(async (extract: IExtract, table: string, amount: number) => {
		const insertLastPaymentOnExtract = (oldExtract: IExtract, payment: {
			id: string;
			amount: number;
		}): IExtract => {
			const nweExtract: IExtract = {
				...oldExtract,
				totalAmount: oldExtract.totalAmount,
				paidAmount: oldExtract.paidAmount + payment.amount,
				totalToPayWithFee: oldExtract.totalToPayWithFee - payment.amount,
				totalToPayWithoutFee: oldExtract.totalToPayWithoutFee - payment.amount,
				orders: [
					//...oldExtract.orders,
					{
						id: payment.id,
						isPayment: true,
						isServiceFee: false,
						totalAmount: payment.amount,
						number: payment.id,
						items: [
							{
								id: v4(),
								name: 'Pagamento',
								totalValue: payment.amount,
								productId: v4(),
								isCanceled: false,
								isTransfered: false,
								//isChecked: false,
								quantity: 1,
								//isPaymentItem: true,
								//isServiceFee: false,
								printerName: '',
								additionals: [],
								observations: [],
								discount: 0,
								composition: ''
							},
						],
						createdAt: new Date(),
						isCanceled: false,
						isTransfered: false,
						discount: 0
					},
				],
			};
			return nweExtract;
		}
		const newExtract = insertLastPaymentOnExtract(extract, { id: v4(), amount });

		await printExtract(newExtract, table);
	}, [printExtract])

	return { printTicket, printExtract, printString, printTicketOrder, receiptPrinterSector, printPosPayment }
}



export default UsePrinter;