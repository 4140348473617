import { useCallback } from 'react';
import { TutorialCardOptions } from '../components/TutorialCard';
import UseTutorialCardStore from '../../store/TutorialCardStore';
const UseTutorialCard = () => {

    const [openTutorialCard, closeTutorialCard] = UseTutorialCardStore(state => [state.open, state.close]);

    return ({
        openTutorialCard,
        closeTutorialCard
    })
}

export default UseTutorialCard