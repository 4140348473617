
import { IOperator } from '../../domains/models/Operator';
import { IOperatorService } from '../../domains/interfaces/IOperatorService';

export const GetOperatorsUseCase = async (
  service: IOperatorService,
  localId: string,
): Promise<IOperator[]> => {
  let responseOperator: IOperator[] | null;
  responseOperator = await service.getAll(localId);
  return responseOperator;
};
