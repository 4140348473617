import { ValidateAccountPaymentRequest } from "../../../domain/dtos/ValidatePaymentRequest"
import { IConsumeService } from "../../../domain/interfaces/IConsumeService"
import { IExtract } from "../../../domain/models/Extract"

const ValidateAccountPaymentUseCase = async (service: IConsumeService, values: { localId: string, operatorId: string, extract: IExtract, amount: number, deviceId: string, serviceFeeEnabled?: boolean, variableServicerFee?: number }) => {
    const request: ValidateAccountPaymentRequest = {
        amount: values.amount,
        accountId: values.extract.accountId,
        operatorId: values.operatorId,
        localId: values.localId,
        equipamentId: values.deviceId,
        serviceFeeEnabled: values.serviceFeeEnabled,
        variableServiceFee: values.variableServicerFee,
    }
    await service.validatePaymentAccount(request);
}
export default ValidateAccountPaymentUseCase


