import { AxiosInstance } from "axios"
import { ISessaoPOS } from "./ISessaoPOS"
import { ICloseSessionResponse, IGetIsOpenedSessionResponse, ISessionResponse, ITableCardResponse } from "../../../../modules/session/domain/dtos/ISessionDtos"

const AntiCorruptionSession = (api: AxiosInstance) => {

    const obterSessaoCorrente = async (request: { localId: string, operatorId: string }): Promise<ISessionResponse> => {
        const response = await api.post<ISessaoPOS>(`/v2/SessaoPOS/AbrirSessaoCorrente?localClienteId=${request.localId}&operadorId=${request.operatorId}`)
        return SessaoToSession(response.data, request.localId);
    }

    const abrirNovaSessao = async (request: { localId: string, operatorId: string }): Promise<ISessionResponse> => {
        const response = await api.post<ISessaoPOS>(`/v2/SessaoPOS/AbrirNovaSessao?localClienteId=${request.localId}&operadorId=${request.operatorId}`)
        return SessaoToSession(response.data, request.localId);
    }
    const possuiSessaoAberta = async (request: { localId: string }): Promise<IGetIsOpenedSessionResponse> => {
        const response = await api.get<{ possuiSessaoAberta: boolean }>(`/SessaoPOS/PossuiSessaoAberta?localId=${request.localId}`)
        return { isOpened: response.data.possuiSessaoAberta };
    }
    const fecharSessao = async (request: { localId: string, sessionId: string, operatorId: string }): Promise<ICloseSessionResponse> => {
        const response = await api.post<{ relatorio: string }>(`/SessaoPOS/FecharSessao?localClienteId=${request.localId}&operadorId=${request.operatorId}&currentSessionId=${request.sessionId}`)
        return {
            report: response.data.relatorio
        }
    }
    const sairSessaoCorrente = async (request: { localId: string, sessionId: string, registerSessionId: string, operatorId: string }): Promise<ICloseSessionResponse> => {
        const response = await api.post<{ relatorio: string }>(`/SessaoPOS/SairSessao?localId=${request.localId}&registroSessaoPOSId=${request.registerSessionId}&currentSessionId=${request.sessionId}&dinheiroInformado=${0}`)
        return {
            report: response.data.relatorio
        }
    }

    const comandaToTableCard = (comanda: string): ITableCardResponse[] => {
        try {

            const comandas = JSON.parse(comanda) as { id: string, numero: string }[];

            const parsedResponse: ITableCardResponse[] = comandas.map(comanda => ({
                cardId: comanda.id,
                number: comanda.numero
            }))
            return parsedResponse;
        } catch (error) {
            return []
        }
    }


    const SessaoToSession = (sessao: ISessaoPOS, localId: string) => {


        // const deviceConfig: IDeviceProfile = {
        //     operation: {
        //         methods: sessao.configuracoesSistemaGestaoDoLocal.map((item) => ({
        //             type: item.TipoSistema.Id,
        //             name: item.TipoSistema.Descricao,
        //             printType: 3,
        //             enabled: true,
        //             customerIdentification: []
        //         }))
        //     },
        //     localId,
        //     id: '',
        //     name: '',
        //     description: '',
        //     terminal: {
        //         tableMode: 1,
        //         operatorMode: 1
        //     },
        //     printer: {
        //         remotePrinters: []
        //     }
        // }



        const parsedResponse: ISessionResponse = {
            id: sessao.sessaoPOSId,
            companyName: sessao.razaoSocial,
            document: sessao.CNPJ,
            registerSessionId: sessao.registroSessaoPOSId,
            openedAt: new Date(
                Number(sessao.sessaoDataInicioAno),
                Number(sessao.sessaoDataInicioMes),
                Number(sessao.sessaoDataInicioDia),
                Number(sessao.sessaoDataInicioHora),
                Number(sessao.sessaoDataInicioMinuto)
            ),
            tablesCards: sessao.comandas ? comandaToTableCard(sessao.comandas) : [],
            emitInvoice: sessao.gerarNfce,
            // deviceConfig: deviceConfig
            enabledCodePanel: sessao.mustEnableManagementPassword,
        }
        return parsedResponse
    }
    return ({ obterSessaoCorrente, abrirNovaSessao, possuiSessaoAberta, fecharSessao, sairSessaoCorrente })
}
export default AntiCorruptionSession