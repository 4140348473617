import { Button, Icon } from '@mui/material';
import { FC, useCallback, useMemo } from 'react'
import { ICartItem } from '../../../../modules/cart/domain/models/ICart';
import styles from './CartView.module.scss'
import { Empty } from '../../../../components/ui/empty/Empty';
import CartBar from './cartModal/CartBar';
import { IExtract } from '../../../../modules/consume/domain/models/Extract';
import ExtractOrders from '../extracResume/components/ExtractOrders';
import { useCartStore } from '../../../../modules/cart/infra/store/CartStore';
import { CartItem } from './CartItem';
import UseDeviceStore from '../../../../modules/device/store/DeviceStore';
import { ProductType } from '../../../../modules/catalog/domain/models/ProductType';

export interface ICartViewProps {
    //propertys
    disabledSendButton?: boolean;
    onClickSendButton?: (cartItem: ICartItem[]) => void;
    onClickCloseAccountButton?: () => void;
    onClickPayAccountButton?: () => void;
    extract?: IExtract;
    disabledPayment?: boolean;
    callTutorial: (type: "SEND_ORDER", ref) => void
}
const CartView: FC<ICartViewProps> = ({ disabledSendButton, onClickSendButton, onClickCloseAccountButton, extract, onClickPayAccountButton, disabledPayment, callTutorial }) => {
    const [cartItems, addCartItem, removeCartItem, clearCartItem, clearCart] = useCartStore(state => [state.cartItems, state.addCartItem, state.removeCartItem, state.clearCartItem, state.clearCart]);

    const onPressRemoveHandle = useCallback(
        (cartItem: ICartItem) => {
            if (cartItem.product.type === ProductType.Kilo || cartItem.product.type === ProductType.Variavel) {
                clearCartItem(cartItem.id)
            } else {
                removeCartItem(cartItem.id, 1)
            }
        },
        [clearCartItem, removeCartItem],
    )
    const onPressAddHandle = useCallback(
        (cartItem: ICartItem) => {
            addCartItem(cartItem.id, 1)
        },
        [addCartItem],
    )
    const onClearHandle = useCallback(
        () => {
            clearCart()
        },
        [clearCart],
    )

    const onPressRemoveAllHandle = useCallback(
        (cartItem: ICartItem) => {
            removeCartItem(cartItem.id, cartItem.quantity)
        },
        [removeCartItem],
    )

    const CartItemsList = useMemo(() => <div className={styles.container} >
        {
            cartItems.length > 0 ?
                cartItems?.map((cartItem) => <CartItem key={cartItem.id} cartItem={cartItem} onPressRemoveHandle={onPressRemoveHandle} onPressAddHandle={onPressAddHandle} onPressRemoveAllHandle={onPressRemoveAllHandle} />)
                :
                <div className={styles.cartEmpty}>
                    <Empty />
                </div>
        }
    </div >, [cartItems, onPressAddHandle, onPressRemoveAllHandle, onPressRemoveHandle])

    const ExtractItems = useMemo(() => <div className={styles.container}>
        {extract 
            ? <ExtractOrders hideResume extract={extract} />
            : (
                <div className={styles.cartEmpty}>
                    <img src='/assets/img/empty-cart.svg' alt=''/>
                    <p>Seu carrinho ainda está vazio</p>
                    <span>Adicione produtos para começar</span>
                </div>
            )
        }
    </div>, [extract])

    return (
        <div id={styles.CartView} >
            {!!cartItems.length ? <>
                <div className={styles.header} >
                    <div className={styles.title}><Icon color='primary'>shopping_cart</Icon>Carrinho</div>
                    {!!cartItems.length && <Button variant='text' color='inherit' onClick={onClearHandle} className={styles.clearCartButton}>
                        <Icon>close</Icon><span>Esvaziar carrinho</span>
                    </Button>}
                </div>
                {CartItemsList}
            </>
                :
                <>
                    <div className={styles.header} >
                        <div className={styles.title}><Icon color='primary'>receipt</Icon>Extrato</div>
                    </div>
                    {ExtractItems}
                </>
            }
            <div className={styles.cartContainer}>
                <CartBar
                    disabledPayment={disabledPayment}
                    callTutorial={callTutorial}
                    visible={!disabledSendButton || !!cartItems.length || !!extract}
                    onClickSendButton={() => onClickSendButton?.(cartItems)}
                    onClickCloseAccountButton={onClickCloseAccountButton}
                    extract={extract}
                    onClickPayAccountButton={onClickPayAccountButton}
                />
            </div>
        </div >
    )
}
export default CartView



