import React, { FC, useCallback, useEffect } from 'react'
import styles from './SessionPage.module.scss'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import { useSession } from '../../modules/session/presentation/hooks/UseSession'
import { IOperator } from '../../modules/operator/domains/models/Operator'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import OpenSessionForm from './components/openSession/OpenSessionForm'
import LoginSessionForm from './components/loginSession/LoginSession'
import OperatorModalValidate, { IOperatorModalValidateRef } from '../../modules/operator/presentation/components/operatorModalValidate/OperatorModalValidate'
import { CurrentSessionStatus } from './components/currentSession/CurrentSessionStatus'
import { OperationMode } from '../../modules/device/presentation/context/OperationMode'
import { InputTableMode } from '../../modules/device/interface/Device'
import UseDeviceStore from '../../modules/device/store/DeviceStore'
import useOperatorStore from '../../modules/operator/store/OperatorStore'
import { OperatorService } from '../../services/api/operator/OperatorService'
import { usePortalComunication } from '../catalog/hooks/usePortalComunication'
export interface ISessionPageProps {
    //propertys 
}
const SessionPage: FC<ISessionPageProps> = () => {


    const { session, openSessionHandler, closeSessionHandler, logoutSessionHandler, getSessionHandler, hasSession, isLoading } = useSession();
    const [device, deviceConfig] = UseDeviceStore(state => [state.device, state.deviceConfig]);
    const [hasPermission, loginOperator] = useOperatorStore(state => [state.hasPermission, state.loginOperator]);
    const navigate = useNavigate();
    const [operationMode,] = UseDeviceStore(state => [state.operationMode]);

    const validateOperatorModalRef = React.useRef<IOperatorModalValidateRef>(null);

    const onClickGoToCatalog = useCallback(() => {
        if (operationMode === OperationMode.TABLE) {
            if (deviceConfig?.terminal.tableMode !== InputTableMode.MAP) {
                navigate('/orderPad');
            } else {
                navigate('/tableMap');
            }
        } else if (operationMode === OperationMode.ORDER_PAD) {
            navigate('/orderPad');
        } else if (operationMode === OperationMode.CASHLESS) {
            toast('Modo Cashless não implementado, verifique a sua configuração no portal')
        } else if (operationMode === OperationMode.TICKET) {
            navigate('/catalog/ticket');
        } else {
            toast('Nenhuma operação detectada');
        }
    }, [deviceConfig?.terminal, navigate, operationMode]);

    // const onSubmitOpenSession = useCallback(async (_operator: IOperator) => {
    //     try {
    //         if (!hasSession && !session) {
    //             const response = await openSessionHandler(_operator);
    //             validateOperatorModalRef.current?.close();
    //             onClickGoToCatalog();
    //         }
    //     } catch {
    //         toast('Erro ao entrar no caixa');
    //     }
    // }, [session, hasSession, onClickGoToCatalog, openSessionHandler])


    const onSubmitLoginSessionPassword = useCallback(async (_operator: IOperator) => {
        try {
            if (hasSession) {
                await getSessionHandler(_operator);
                onClickGoToCatalog();
            } else {
                await openSessionHandler(_operator);
                onClickGoToCatalog();
            }
        } catch {
            toast('Erro ao entrar no caixa');
        }
    }, [hasSession, getSessionHandler, onClickGoToCatalog, openSessionHandler])


    const onSubmitCloseSessionPassword = useCallback(async () => {
        const validated = await validateOperatorModalRef.current?.validate()
        if (validated?.validated) {
            if (hasSession && session) {
                closeSessionHandler(session.id, validated.operator);
                validateOperatorModalRef.current?.close();
            }
        }
    }, [closeSessionHandler, session, hasSession])


    const loginOperatorHandle = useCallback(async (password: string) => {
        if (!device) {
            toast('Dispositivo não encontrado')
            return;
        }
        try {

            const service = OperatorService()
            const operator = await loginOperator(service, device.localId, password);
            if (operator) {
                return operator;
            } else {
                throw new Error('Operador não encontrado');
            }
        } catch (error) {
            toast('Operador ou senha inválidos');
            return undefined;
        }
    }, [device, loginOperator])

    const onSubmitRemoveSession = useCallback(async () => {
        try {
            if (hasSession && session) {
                if (hasSession && session) {
                    await logoutSessionHandler();
                    validateOperatorModalRef.current?.close();
                }
            } else {
                throw new Error('Sessão não encontrada');
            }

        } catch (error) {
            toast('Erro ao sair do caixa');
        }
    }, [session, hasSession, logoutSessionHandler])



    return (
        <div id={styles.SessionPage} >
            <Toolbar />
            <div className={styles.container} >
                <div className={styles.imageContainer}>
                    <img
                        src='/assets/img/session-bg.png'
                        alt="Imagem Caixa"
                    />
                </div>
                {isLoading ?
                    <div className={styles.sessionContainer}>
                        <div className={styles.loading}>
                            <h2> Carregando sessão...</h2>
                        </div>
                    </div>
                    :
                    <div className={styles.sessionContainer}>

                        {/* {
                                !hasSession && !session &&
                                <OpenSessionForm
                                    onSubmit={onSubmitOpenSession}
                                    validatePassword={validateOperatorModalRef.current?.validate}
                                />

                            } */}
                        {
                            !session &&
                            <LoginSessionForm
                                hasSession={hasSession}
                                onSubmit={onSubmitLoginSessionPassword}
                                validatePassword={loginOperatorHandle}
                            />
                        }

                        {
                            hasSession && session &&
                            <CurrentSessionStatus
                                hasPermission={hasPermission}
                                device={device}
                                currentSession={session}
                                onSubmitRemoveSession={onSubmitRemoveSession}
                                onSubmitCloseSessionPassword={onSubmitCloseSessionPassword}
                                onClickGoToCatalog={onClickGoToCatalog}
                            />
                        }
                    </div>
                }
            </div>
            {/* <OpenSessionAlet ref={openSessionAletRef} /> */}
            <OperatorModalValidate ref={validateOperatorModalRef} />
        </div>
    )
}
export default SessionPage

