import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './SplitPaymentOrderForm.module.scss'
import { ISplitPaymenItemSelectable } from './SplitPaymentOrderForm'
import { Checkbox, FormControlLabel, Icon, IconButton } from '@mui/material'
import { moneyMask } from '../../../../cross-cutting/moneyMask'
import { moneyFormater } from '../../../../services/utils/Money'
export interface ISplitPaymentOrderItemFormProps {
    //propertys
    item: ISplitPaymenItemSelectable
    onChange: (item: ISplitPaymenItemSelectable, checked: boolean, valueToPay: number) => void
}
const SplitPaymentOrderItemForm: FC<ISplitPaymentOrderItemFormProps> = ({ item, onChange }) => {


    const [quantityToPay, setQuantityToPay] = useState<number>(0);
    const [divisor, setDivisor] = useState<number>(1);

    useEffect(() => {
        if (item.quantity < quantityToPay) {
            setQuantityToPay(item.quantity);
            setDivisor(1);
            onChange(item, true, item.quantity);
        }
    }, [item, onChange, quantityToPay])


    const onAddQuantityHandle = useCallback(() => {
        if (quantityToPay + 1 > item.quantity) {
            return;
        }
        var newQuantity = ((quantityToPay + 1) / divisor);
        setQuantityToPay(quantityToPay + 1);
        onChange(item, true, newQuantity);
    }, [divisor, item, onChange, quantityToPay]);

    const onSubQuantityHandle = useCallback(() => {
        if (quantityToPay - 1 < 0) {
            return;
        }
        if (quantityToPay - 1 === 0) {
            setQuantityToPay(0);
            setDivisor(1);
            onChange(item, false, 0);
            return;
        }
        setQuantityToPay(quantityToPay - 1);
        var newQuantity = ((quantityToPay - 1) / divisor);
        onChange(item, true, newQuantity);
    }, [divisor, item, onChange, quantityToPay]);


    const onCheckHandle = useCallback((_, checked: boolean) => {
        if (checked && item.quantityToPay === 0) {
            setQuantityToPay(item.quantity);
            setDivisor(1);
            onChange(item, true, item.quantity);
            return;
        }
        setQuantityToPay(0);
        setDivisor(1);
        onChange(item, false, 0);
    }, [item, onChange]);


    const onChangeAddDivisorHandle = useCallback(() => {
        setDivisor(divisor + 1);
        onChange(item, true, quantityToPay / (divisor + 1));
    }, [divisor, item, onChange, quantityToPay]);

    const onChangeSubDivisorHandle = useCallback(() => {
        if (divisor - 1 < 1) {
            return;
        }
        setDivisor(divisor - 1);
        onChange(item, true, quantityToPay / (divisor - 1));

    }, [divisor, item, onChange, quantityToPay]);
    return (
        <div id={styles.SplitPaymentOrderItemForm} >
            <div className={styles.item}>
                <div className={styles.resume}>
                    <FormControlLabel
                        checked={item.quantityToPay > 0}
                        onChange={onCheckHandle}
                        control={<Checkbox />}
                        label={
                            <div className={styles.orderInfo}>
                                <label>{item.quantity} - {item.name} <b>{moneyFormater(item.quantity * item.totalValue)}</b></label>
                            </div>

                        }
                    />

                </div>

                {
                    quantityToPay > 0 &&
                    <>
                        {item.quantity > 1 && <div className={styles.values}>
                            <b>Quantidade({item.quantity}):</b>
                            <div className={styles.quantity}>
                                <IconButton size='small' onClick={onSubQuantityHandle}><Icon>remove</Icon></IconButton>
                                {quantityToPay}
                                <IconButton size='small' onClick={onAddQuantityHandle}><Icon>add</Icon></IconButton>
                            </div>
                        </div>}
                        <div className={styles.values}>
                            <b>Divisor:</b>
                            <div className={styles.quantity}>
                                <IconButton size='small' onClick={onChangeSubDivisorHandle}><Icon>remove</Icon></IconButton>
                                {divisor}
                                <IconButton size='small' onClick={onChangeAddDivisorHandle}><Icon>add</Icon></IconButton>
                            </div>
                        </div>
                    </>
                }
                <div className={styles.values}>
                    <b>Total:({moneyFormater(item.totalValue * item.quantity)})</b>
                    {
                        <b className={styles.valueToPay}>{moneyFormater(item.quantityToPay * item.totalValue)}</b>
                    }
                </div>
            </div>
        </div >
    )
}
export default SplitPaymentOrderItemForm