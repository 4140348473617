import { validate } from "uuid";
import { IInvoice, IInvoiceProductItem } from "../../../../modules/invoice/application/dtos/IInvoiceData";

export interface INfceItemResponse {
    razaosocial: string;
    cnpj: string;
    serie?: string;
    cliente?: string;
    status?: string;
    "valor-total"?: number;
    itens: INfceProdutoItemResponse[];
    sefaz: Sefaz;
    numero?: string;
    erro?: string;
}

export interface INfceProdutoItemResponse {
    Id: string;
    ProdutoNome: string;
    Quantidade: number;
    ValorUnitario: number;
    Desconto: number;
    NCM: string;
}


export interface Sefaz {
    codigo?: string;
    "url-danfe"?: string;
    "data-emissao"?: Date;
    mensagem?: string;
    chave?: string;
    protocolo?: string;
    erro?: string;
}

export interface IEmitirNfceRequest {
    pedidoId: string;
    documento?: string;
    ehManual: boolean;
}



export function convertNfceToInvoice(nfceItems: INfceItemResponse[]): IInvoice[] {
    const response =  nfceItems.map((nfceItem) => {
        const invoiceItem: IInvoice = {
            series: nfceItem.serie,
            customer: nfceItem.cliente,
            status: nfceItem.status,
            totalValue: nfceItem["valor-total"],
            sefaz: {
                code: nfceItem.sefaz.codigo,
                danfeUrl: nfceItem.sefaz["url-danfe"],
                emissionDate: nfceItem.sefaz["data-emissao"],
                message: nfceItem.sefaz.mensagem,
                key: nfceItem.sefaz.chave,
                protocol: nfceItem.sefaz.protocolo,
                error: nfceItem.sefaz.erro,
            },
            items: nfceItem.itens.map((nfceProductItem) => {
                const invoiceProductItem: IInvoiceProductItem = {
                    id: nfceProductItem.Id,
                    productName: nfceProductItem.ProdutoNome,
                    quantity: nfceProductItem.Quantidade,
                    unitPrice: nfceProductItem.ValorUnitario,
                    discount: nfceProductItem.Desconto,
                    ncm: nfceProductItem.NCM,
                };
                return invoiceProductItem;
            }),
            number: nfceItem.numero,
            error: nfceItem.erro,
            cnpj: nfceItem.cnpj,
            companyName: nfceItem.razaosocial,
        };
        validateNfceItemResponse(nfceItem);
        return invoiceItem;
    });
    return response;
}

/**
 boolean peloMenos1Sucesso = false;

for (RetornoNfce nf : retornoNfce) {
    if (nf.getSefaz() != null && nf.getSefaz().getProtocolo() != null && nf.getSefaz().getChave() != null)
        //validar se a chave dentro do sefaz tbm é difernte de null
        //se tiver null validar a mensagem que esta apresentando quando der erro e exibi-lo
        peloMenos1Sucesso = true;
}

if (peloMenos1Sucesso)
    pedidoPOS.setNfceStatus(NfceStatus.sucesso.getValue());
else {
    pedidoPOS.setNfceStatus(NfceStatus.erro.getValue());
}
caso der um erro != de 200 fazemos isso daqui

try {
    if (result.contains("Pedido ainda não foi sincronizado")) {
        erro = "Não foi possível gerar NFCE. Tentar novamente pelo historico.";
    } else {
        erro = "Não foi possível gerar NFCE. Status = " + httpStatus;
    }
} catch (Exception e) {
    erro = "Não foi possível gerar NFCE. Status = " + httpStatus;
}

 */


export const  validateNfceItemResponse = (nfceItem: INfceItemResponse) => {
  if (!nfceItem.sefaz || !nfceItem.sefaz.protocolo || !nfceItem.sefaz.chave) {
      throw new Error(nfceItem.erro || "Não foi possível gerar NFCE");
  }
}

const mockNfceItemResponse: INfceItemResponse = {
    serie: "serie",
    cliente: "cliente",
    status: "status",
    "valor-total": 100,
    itens: [
        {
            Id: "1234132412C34124453643",
            ProdutoNome: "TESTE PRODUTO",
            Quantidade: 1,
            ValorUnitario: 100,
            Desconto: 0,
            NCM: "10001",
        },
    ],
    sefaz: {
        codigo: "codigo",
        "url-danfe": "HTTP://GOOGLE.COM.BR",
        "data-emissao": new Date(),
        mensagem: "mensagem",
        chave: "chave",
        protocolo: "protocolo",
        erro: "",
    },
    numero: "numero",
    cnpj: "123123123123",
    erro: "",
    razaosocial: ""
};

export const SimulateNfceItemResponse = () => {
    return new Promise<{ data: INfceItemResponse[] }>((resolve, reject) => {
        resolve({ data: [mockNfceItemResponse ]});
        setTimeout(() => {
            resolve({ data: [mockNfceItemResponse ]});
        }, 0);
    })
}
