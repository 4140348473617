import React, { FC, RefObject, useCallback, useEffect } from 'react'
import styles from './CartBar.module.scss'
import { ICartItem } from '../../../../../modules/cart/domain/models/ICart';
import { Button } from '@mui/material';
import { IExtract } from '../../../../../modules/consume/domain/models/Extract';
import CartToast from '../../cartToast/CartToast';
import { useCartStore } from '../../../../../modules/cart/infra/store/CartStore';
import { IDevice } from '../../../../../modules/device/interface/Device';
import { OperationMode } from '../../../../../modules/device/presentation/context/OperationMode';
export interface ICartBarProps {
    //propertys
    operationMode?: OperationMode;
    disabledPayment?: boolean;
    onClickSendButton: (cartItem: ICartItem[]) => void;
    onClickCloseAccountButton?: () => void;
    onClickPayAccountButton?: () => void;
    onClick?: () => void;
    visible?: boolean;
    className?: string;
    extract?: IExtract;
    number?: string;
    device?: IDevice;
    callTutorial?: (type: "SEND_ORDER", ref: RefObject<HTMLDivElement>) => void
}
const CartBar: FC<ICartBarProps> = ({ onClickSendButton, onClickCloseAccountButton, onClickPayAccountButton, extract, className, visible, number, onClick, disabledPayment, callTutorial }) => {
    const [cartItems, totals] = useCartStore(state => [state.cartItems, state.totals]);

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const onClickSendButtonHandle = useCallback(() => {
        onClickSendButton(cartItems)
    }, [onClickSendButton, cartItems])

    const onClickCloseAccountButtonHandle = useCallback(() => {
        onClickCloseAccountButton?.()
    }, [onClickCloseAccountButton])

    const onClickPayAccountButtonHandle = useCallback(() => {
        onClickPayAccountButton?.()
    }, [onClickPayAccountButton]);

    const callTutorialHandler = useCallback(() => {
        if (totals.totalQuantity) {
            callTutorial?.("SEND_ORDER", containerRef)
        }
    }, [callTutorial, totals.totalQuantity])

    useEffect(() => {
        callTutorialHandler();
    }, [callTutorialHandler])


    return (
        <div id={styles.CartBar} ref={containerRef} className={`${(totals.totalQuantity || visible) ? styles.CartBarVisible : styles.CartBarHidden} ${className}`} onClick={onClick} >
            <CartToast />
            {!!cartItems.length &&
                <>
                    <div className={styles.resume}>
                        <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totals.totalPrice)}</b>
                        <span>Total de itens: {totals.totalQuantity}</span>
                    </div>
                    <div className={styles.buttons}>
                        <Button variant='outlined' color='primary' className={styles.outlined} disabled={!totals.totalQuantity} onClick={onClickSendButtonHandle}>
                            Enviar Pedido
                        </Button>
                    </div>
                </>
            }
            {onClickCloseAccountButton && extract && !extract?.totalToPayWithoutFee && !cartItems.length &&
                <>
                    <div className={styles.resume}>
                        <span>Total Pago:</span>
                        <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract?.paidAmount)}</b>
                    </div>
                    <div className={styles.buttons}>

                        <Button variant='contained' color='primary' disabled={!extract} classes={
                            {
                                root: styles.contained,
                                disabled: styles.disabled
                            }

                        } onClick={onClickCloseAccountButtonHandle}>
                            Finalizar Mesa
                        </Button>
                    </div>
                </>
            }
            {
                onClickCloseAccountButton && !!extract?.totalToPayWithoutFee && !cartItems.length && !disabledPayment &&
                <>
                    <div className={styles.resume}>
                        {number && <span>mesa: <b>{number}</b></span>}
                        <span><b>{extract.user?.name}</b></span>
                        <span>Consumo: <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract?.totalAmount)}</b></span>
                    </div>
                    <div className={styles.buttons}>

                        <Button variant='contained' color='primary' disabled={!extract} classes={
                            {
                                root: styles.contained,
                                disabled: styles.disabled
                            }
                        } onClick={onClickPayAccountButtonHandle}>
                            Efetuar pagamento
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}
export default CartBar