import { IOnBoardService } from "../domain/Interfaces/IOnBoardService";
import { IOnBoardingItem, IOnBoardingItemRequest } from "../domain/models/IOnBoardingItem";

export const GetOnboardingUseCase = async (onBoardingService: IOnBoardService, localId: string):Promise<IOnBoardingItem[]> => {
    return await onBoardingService.getOnBoarding(localId);
};
export const setOnBoardingUseCase = async (onBoardingService: IOnBoardService, onBoarding: IOnBoardingItemRequest) => {
    return await onBoardingService.setOnBoarding(onBoarding);
};
export const resetOnBoardingUseCase = async (onBoardingService: IOnBoardService, localId: string) => {
    return await onBoardingService.resetOnBoarding(localId);
};
