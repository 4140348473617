import React, { createContext, createRef, FC, PropsWithChildren, RefObject, useContext, useEffect, useRef, useState } from 'react';
import KeyboardModal from './_KeyboardModal';
import Keyboard, { IKeyboardProps } from './Keyboard';
import UseDimensions from '../dimension/UseDimension';

interface IKeyboardContext {
    configureKeyboard: React.Dispatch<React.SetStateAction<IKeyboardProps | undefined>>
    disabledVirtualKeyboard: boolean;
    setDisabledVirtualKeyboard: React.Dispatch<React.SetStateAction<boolean>>
    keyboardConfig?: IKeyboardProps;
}

const KeyboardContext = createContext<IKeyboardContext>({} as IKeyboardContext);

export const inputRef = createRef<boolean>();


export const KeyboardProvider: FC<PropsWithChildren> = ({ children }) => {

    const { isMobile, height } = UseDimensions();
    const [disabledVirtualKeyboard, setDisabledVirtualKeyboard] = useState(false);
    const [keyboardConfig, setKeyboardConfig] = useState<IKeyboardProps>();


    useEffect(() => {
        if (isMobile || height < 700) {
            setDisabledVirtualKeyboard(true);
        }
    }, [height, isMobile])




    return (
        <KeyboardContext.Provider value={{ configureKeyboard: setKeyboardConfig, keyboardConfig, disabledVirtualKeyboard, setDisabledVirtualKeyboard }}>
            <>{children}</>
            {<KeyboardModal disabledVirtualKeyboard={disabledVirtualKeyboard} inputRef={keyboardConfig?.inputRef} autoFocus={keyboardConfig?.autoFocus} >
                <Keyboard {...keyboardConfig} />
            </KeyboardModal>}
        </KeyboardContext.Provider>
    );
};


export const useKeyboard = () => {
    const context = useContext(KeyboardContext);
    return context;
};