import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import styles from './TransferTableForm.module.scss';
import { ITable } from '../../../modules/consume/domain/models/ITables';
import { Button, Checkbox, FormControl, FormControlLabel, Icon, Radio } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import SearchInput from '../../../components/searchInput/SearchInput';



export interface SelectTableListProps {
    //propertys
    tableList: ITable[];
    onChange?: (values: ITable[]) => void;
    multiple?: boolean;
    showArrow?: boolean
    title:string
}
const SelectTableList: FC<SelectTableListProps> = ({ tableList, onChange, multiple, showArrow, title}) => {

    const [selectedTables, setSelectedTables] = React.useState<ITable[]>([]);
    const [filteredTableList, setFilteredTableList] = React.useState<ITable[]>(tableList);
    const [filter, setFilter] = React.useState<string>('');

    const onFilterHandle = useCallback((value?: string) => {
        setFilter(value ?? '');
        if (!value || value === '') {
            setFilteredTableList(tableList);
            return;
        }
        const filtered = tableList.filter(table => table.number.toString().includes(value));
        setFilteredTableList(filtered);
    }, [tableList]);

    const onChangeHandle = useCallback((table: ITable) => {
        if (!multiple) {
            setSelectedTables([table]);
            onChange?.([table]);
            return;
        } else {

            const index = selectedTables.findIndex(selectedTable => selectedTable.id === table.id);
            if (index >= 0) {
                selectedTables.splice(index, 1);
            } else {
                selectedTables.push(table);
            }
            setSelectedTables([...selectedTables]);
            onChange?.(selectedTables);
            return;
        }
    }, [multiple, onChange, selectedTables]);

    const isChecked = useCallback((table: ITable) => {
        return selectedTables.findIndex(selectedTable => selectedTable.id === table.id) >= 0;
    }, [selectedTables]);

    const isCheckedStyle = useCallback((table: ITable): CSSProperties | undefined => {
        return isChecked(table) ? { color: "var(--primary-color)", borderColor: "var(--primary-color)", background: "var(--primary-color-200)", fontWeight: 700 } : undefined;
    }, [isChecked]);

    const quantitySelected = useMemo(() => {
        return !!selectedTables.length;
    }, [selectedTables]);

    return <div id={styles.SelectTableList}>
        <h2>{title}</h2>
        <SearchInput
            onSubmit={onFilterHandle}
            reset={onFilterHandle}
        />
        {filteredTableList?.map(table => (
            <div className={styles.tableItem}>
                {/* {<Icon className={isChecked(table) ? styles.iconLeftSelected : styles.iconLeft} color='primary' >east</Icon>} */}
                <Button size='small' style={isCheckedStyle(table)} variant='outlined' onClick={() => onChangeHandle(table)} className={styles.table} key={table.id}>
                    <div className={styles.tableNumber}>
                        {multiple ? <Checkbox size='small' color='primary' checked={isChecked(table)} /> : <Radio size='small' color='primary' checked={isChecked(table)} />}<label>{table.number}</label>
                    </div>
                </Button>
                {<Icon className={showArrow && isChecked(table) ? styles.iconSelected : styles.icon} color='primary' >east</Icon>}
            </div>
        ))}
        {quantitySelected}
    </div >;
};

export default SelectTableList;