import React, { FC, useCallback, useEffect } from 'react'
import styles from './DeviceSessionPage.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Icon, IconButton } from '@mui/material'
import UseDeviceNavigatorStore from '../../modules/device/store/DeviceNavigatorStore'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import UseDeviceStore from '../../modules/device/store/DeviceStore'
import axios, { Axios } from 'axios'
import { UseVerifyNavigator } from '../../modules/device/presentation/hooks/UseVerifyNavigator'
import { DEVICE_API, PORTAL_URL } from "../../services/api/bases/Constants"
import { KeyboardArrowRightOutlined, Terminal } from '@mui/icons-material'
import { usePortalComunication } from '../catalog/hooks/usePortalComunication'
import useAuthStore from '../../modules/authentication/stores/AuthStore'
import { hideLoading, showLoading } from '../../modules/ui/stores/UiStore'
export interface IDeviceSessionPageProps {
    //propertys
}
const DeviceNavigatorSessionPage: FC<IDeviceSessionPageProps> = () => {

    const { localId, deviceId } = useParams<{ localId: string, deviceId: string, navigatorSessionId: string }>();
    const [auth] = useAuthStore(state => [state.auth]);
    const { verifyNewDeviceHandlerAndRedirect } = UseVerifyNavigator();
    const { terminalId, solicitationId } = UseDeviceNavigatorStore(state => state);
    const { device } = UseDeviceStore(state => state);
    const navigator = useNavigate();

    const verifyHandler = useCallback(async () => {
        try {
            showLoading('@verifyNewDevice', 'Verificando dispositivo');
            if (localId && deviceId) {
                await verifyNewDeviceHandlerAndRedirect(localId, deviceId)
            }
    } finally {
            hideLoading('@verifyNewDevice');
        }
    }, [deviceId, localId, verifyNewDeviceHandlerAndRedirect])


    useEffect(() => {
        verifyHandler()
    }, [verifyHandler])

    const gotoSession = useCallback(() => {
        navigator('/session')
    }, [navigator])

    return (
        <div id={styles.DeviceSessionPage} >
            {/* <Toolbar></Toolbar> */}
            <img width={150} src="/assets/img/LogoMee.svg" alt="MEE LOGO" />
            <div className={styles.container} >

                {solicitationId && <div className={styles.tutorial} >
                    <h1>Para continuar, aprove esse dispositivo:</h1>
                    <p>A aprovação ocorre para que você tenha controle de quem está usando cada um dos seus terminais.</p>
                    <div className={styles.steps}>
                        <div className={styles.step}>
                            <div className={styles.image}>
                                <img src={require('./img/portal.png')} alt={'portal'} />
                            </div>
                            <h2>1. Aprove o acesso no Portal</h2>
                            <p>Clique no <b>botão ‘Aprovar’</b> que irá aparecer na parte superior do portal</p>
                        </div>
                        <div className={styles.step}>
                            <KeyboardArrowRightOutlined className={styles.nextStep} />
                        </div>
                        <div className={styles.step}>
                            <div className={styles.image}>
                                <img src={require('./img/terminal.png')} alt={'terminal'} style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)" }} />
                                <img src={require('./img/check.png')} alt={'check'} className={styles.check} />
                            </div>
                            <h2>2. Pronto!</h2>
                            <p>Depois de aprovado, você está pronto para começar</p>
                        </div>

                    </div>

                </div>}

                {terminalId && <div className={styles.terminalId} >
                    <h1>Conectado</h1>
                    {/* <div>Terminal: {terminalId}</div> */}
                    {localId && deviceId && !solicitationId && terminalId && <Button variant='contained' onClick={gotoSession} ><Icon>check</Icon>IR PARA TERMINAL</Button>}
                </div>}

                {<div className={styles.terminalId} >
                    <div><span>Seu dispositivo: <b>{device?.deviceName}</b></span>
                        {localId && deviceId && <IconButton onClick={() => window.location.reload()} size='small' ><Icon fontSize='small'>refresh</Icon></IconButton>}
                    </div>
                    <div><span>Local:<b> {device?.localName}</b></span></div>
                    {/* {<a href={PORTAL_URL} target='_blank' rel="noreferrer"><Button fullWidth variant='contained' color='secondary'>Aprovar</Button></a>} */}
                </div>}

                {solicitationId && localId && deviceId && process.env.NODE_ENV === 'development' && <Button style={{ position: 'absolute', bottom: 16, right: 16 }} variant='contained' onClick={() => aprove(solicitationId)} ><Icon>check</Icon>APROVAR DEV</Button>}


                {/* {localId && deviceId && <Button variant='text' onClick={verifyHandler} >Verificar</Button>} */}


                {localId && deviceId && <Button variant='text' onClick={verifyHandler} >Clique aqui caso já tenha aprovado</Button>}
                {/* {localId && deviceId && <Button variant='text' onClick={verify} >Solicitar acesso</Button>} */}
                {/*
                {localId && deviceId && process.env.NODE_ENV === 'development' && <Button variant='text' onClick={gotoSession} ><Icon>check</Icon>IR PARA TERMINAL DEV</Button>}
            {localId && deviceId && process.env.NODE_ENV === 'development' && <Button variant='text' onClick={() => { window.print(); }} ><Icon>check</Icon>PRINT TESTE DEV</Button>} */}
            </div>
            {process.env.NODE_ENV === 'development' && <div>
                <div>
                    Solicitation: {solicitationId}
                </div>
                <div>
                    Terminal: {terminalId}
                </div>
            </div>}
        </div>
    )


}
export default DeviceNavigatorSessionPage

const aprove = (navigatorSolicitationId: string) => {
    const raw = {
        "navigatorSolicitationId": navigatorSolicitationId,
        "navigatorName": "APROVADO PELO DEV"
    };



    axios.post({ DEVICE_API } + "/api/locals/49953f8a-8a69-4a82-bf3e-8f18396313bb/terminal/approve", raw)
        .then(response => response.data)
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}


