export enum OrderStatus {
    PROCESSANDO_PAGAMENTO = 1,
    FALHA_NO_PAGAMENTO = 2,
    PAGAMENTO_REALIZADO = 3,
    PEDIDO_CANCELADO = 4,
    PARCIALMENTE_CANCELADO = 5,
    AGUARDANDO_PAGAMENTO = 6,
    FALHA_NA_PAGSEGURO = 7,
    TRANSFERIDO = 8,
    PRODUCAO = 9,
    NAO_AUTORIZADO = 10,
    PRE_RECARGA = 11,
}