import { IDeviceService } from '../domain/interfaces/IDeviceService';
import { IDevice } from '../interface/Device';

const GetDeviceUseCase = async (
  deviceService: IDeviceService,
  deviceId: string,
): Promise<IDevice> => {
  const response = await deviceService.getDevice(deviceId);
  return { ...response, disabledVerification: false, getDeviceConfigOnSession: false };
};
export default GetDeviceUseCase;



