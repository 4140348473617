import { IDeviceResponse } from '../../../modules/device/domain/dtos/DeviceResponse';
import { IDeviceService } from '../../../modules/device/domain/interfaces/IDeviceService';
import AnticorruptionDeviceService from './anticorruption/AnticorruptionDeviceService';

import { Api } from '../bases/Api';
import { DEVICE_API } from "../bases/Constants";
import { DeviceProfileResponse } from '../../../modules/device/domain/dtos/DeviceProfileResponse';

const api = Api();
const baseUrl = DEVICE_API;

const DeviceService = (): IDeviceService => {
  const getDevice = async (deviceId: string): Promise<IDeviceResponse> => {
    const response = await AnticorruptionDeviceService().oterUsuarioMeepPosPorId(deviceId);
    return response;
  };

  // const getDevice = async (deviceId: string, macAddress: string): Promise<IDeviceResponse> => {
  //   const response = await AnticorruptionDeviceService().getDeviceConfig(deviceId, macAddress);
  //   return response;
  // };

  const getDeviceProfile = async (deviceId: string, localId: string): Promise<DeviceProfileResponse> => {
    const response = await api.get<DeviceProfileResponse>(`${baseUrl}/api/locals/${localId}/profiles/byDeviceId/${deviceId}`)
    return response.data;
  }
  const getDeviceProfileById = async (profileId: string, localId: string): Promise<DeviceProfileResponse> => {
    const response = await api.get<DeviceProfileResponse>(`${baseUrl}/api/locals/${localId}/profiles/${profileId}`)
    return response.data;
  }

  return { getDevice, getDeviceProfile, getDeviceProfileById };
};
export default DeviceService;
