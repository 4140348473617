import { IVerifyTerminalNavigatorSessionRequest, IVerifyTerminalNavigatorSessionResponse } from "../../../modules/device/domain/dtos/VerifyTerminalNavigatorSessionResponse"
import { IDeviceNavigator } from "../../../modules/device/domain/interfaces/IDeviceNavigator"
import { Api, } from "../bases/Api";
import { DEVICE_API } from "../bases/Constants";

const api = Api();
// const baseUrl = `http://localhost:50499`;
const baseUrl = DEVICE_API + "/api";

const DeviceNavigatorService = (): IDeviceNavigator => {
    const verifyTerminalNavigatorSession = async (request: IVerifyTerminalNavigatorSessionRequest): Promise<IVerifyTerminalNavigatorSessionResponse> => {
        const response = await api.get<IVerifyTerminalNavigatorSessionResponse>(`${baseUrl}/locals/${request.localId}/terminal/device/${request.deviceId}/navigatorSession/${request.navigatorSessionId}`);
        return (response.data);
    }
    const approve = async (localId: string, solicitationId: string) => {
        await api.post(`${baseUrl}/locals/${localId}/terminal/approve`, { navigatorSolicitationId: solicitationId, navigatorName: "Portal" });
    }

    return ({ verifyTerminalNavigatorSession, approve })
}
export default DeviceNavigatorService
