import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './OrderLogList.module.scss'
import { IOrderItemLog, IOrderLog } from '../../../modules/orderLog/domain/IOrderLog'
import { Button, CircularProgress, Icon, IconButton } from '@mui/material'
import { toast } from 'react-toastify'
import UseSocketPrinter from '../../../modules/printer/presentation/hooks/UseSocketPrinter'
import InvoicePrinterLayout from '../../../modules/printer/presentation/layoutPrinter/invoice/InvoicePrinterLayout'
export interface IOrderLogListProps {
    //propertys
    orders: IOrderLog[],
    emitInvoice?: (order: IOrderLog) => Promise<void>
    printInvoice?: (order: IOrderLog) => Promise<void>
    printOrder?: (order: IOrderLog) => void
    disabledPrinter?: boolean
    disabledEmitInvoice?: boolean
}
const OrderLogList: FC<IOrderLogListProps> = ({ orders, emitInvoice, printInvoice, printOrder, disabledPrinter, disabledEmitInvoice }) => {
    return (
        <div id={styles.OrderLogList} >
            {
                orders.map((order) => <OrderLogItem key={order.id} order={order} emitInvoice={emitInvoice} printInvoice={printInvoice} disabledPrinter={disabledPrinter}
                    disabledEmitInvoice={disabledEmitInvoice} />)
            }
        </div>
    )
}

export default OrderLogList

export interface IOrderLogItemProps {
    order: IOrderLog,
    emitInvoice?: (order: IOrderLog) => Promise<void>
    printInvoice?: (order: IOrderLog) => Promise<void>
    printOrder?: (order: IOrderLog) => void
    disabledPrinter?: boolean
    disabledEmitInvoice?: boolean
}
const OrderLogItem: FC<IOrderLogItemProps> = ({ order, emitInvoice, printInvoice, printOrder, disabledEmitInvoice, disabledPrinter }) => {


    const [showItems, setShowItems] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [invoiceImage, setInvoiceImage] = React.useState<string | null>(null)

    const [html, setHtml] = React.useState<string>('')
    const { getElementHtml } = UseSocketPrinter();
    const [emitedInvoice, setEmitedInvoice] = React.useState(!!order.invoice);
    useEffect(() => {
        setEmitedInvoice(!!order.invoice);
    }, [order.invoice]);


    const onClickEmiteInvoice = async () => {
        if (emitInvoice) {

            try {
                setIsLoading(true)
                await emitInvoice(order)
                setEmitedInvoice(true)
                toast.success('Nota fiscal emitida com sucesso');
            } catch {
                toast.error('Erro ao emitir nota fiscal');
            } finally {
                setIsLoading(false)
            }
        }
    }
    const onClickPrintInvoice = useCallback(async () => {
        if (printInvoice) {
            try {
                setIsLoading(true);
                await printInvoice(order)
            }
            finally {
                setIsLoading(false)
            }
        }
    }, [printInvoice, order])



    useEffect(() => {
        const generateImage = async () => {
            if (order.invoice) {
                const image = await getElementHtml(<InvoicePrinterLayout invoice={order.invoice[0]} />)
                setHtml(image)
            }
        }
        generateImage();
    }, [getElementHtml, order.invoice])

    // const onload = useCallback(() => {
    //     const generateImage = async () => {
    //         if (order.invoice) {
    //             const image = await htmlToBase64(<InvoicePrinterLayout invoice={order.invoice[0]} />)
    //             setInvoiceImage(image)
    //         }
    //     }
    //     generateImage();
    // }, [htmlToBase64, order.invoice])

    return (
        <div key={order.id} className={styles.order} >
            {isLoading &&
                <div className={styles.loading} >
                    <CircularProgress size={18} />
                </div>
            }
            <div className={styles.status}>
                {/* {order.invoice ?
                    <IconButton className={styles.invoiceEmited} onClick={onClickPrintInvoice}  ><Icon>receipt_long</Icon></IconButton>
                    :
                    <IconButton className={styles.invoiceNotEmited} onClick={onClickEmiteInvoice} ><Icon>receipt_long</Icon></IconButton>
                } */}
            </div>
            <div className={styles.orderHeader} >
                <div className={styles.orderDate} ><label>Criado em:</label><span>{new Date(order.createdAt).toLocaleString()}</span></div>
                <div className={styles.orderOperator} ><label>Operador: </label><span>{order.operator.name}</span></div>
                <div className={styles.orderId} ><label>ID: </label><span>#{order.id}</span></div>
            </div>
            {<div className={styles.orderItems} >
                <div>
                    <label>Itens: ({order.orderItems.length})</label>
                    {showItems ? <IconButton size='small' onClick={() => setShowItems(false)}><Icon fontSize='small'>expand_less</Icon></IconButton> : <IconButton size='small' onClick={() => setShowItems(true)}><Icon fontSize='small'>expand_more</Icon></IconButton>}
                </div>
                {
                    showItems && order.orderItems.map((orderItem) => <OrderItem key={orderItem.id} orderItem={orderItem} />)
                }
            </div>}
            {
                invoiceImage &&
                <img src={"" + invoiceImage} alt="Imagem do pedido" />
            }
            <div className={styles.actions}>

                {/* <Button onClick={onload} >Exibir nota</Button> */}

                {emitedInvoice ?
                    <Button disabled={isLoading || disabledPrinter} className={styles.invoiceEmited} onClick={onClickPrintInvoice} ><Icon>receipt_long</Icon> imprimir nota fiscal</Button>
                    :
                    <Button disabled={isLoading || disabledEmitInvoice} className={styles.invoiceNotEmited} onClick={onClickEmiteInvoice} ><Icon>receipt_long</Icon>  Emitir nota fiscal </Button>
                }
            </div>
            {/* {
                order.invoice &&
                <div className={styles.invoice} >
                    <InvoicePrinterLayout invoice={order.invoice[0]} />
                </div>
            }
            {
                html && <div dangerouslySetInnerHTML={{
                    __html: html
                }} ></div>
            }
            {html} */}
        </div >
    )
}

const OrderItem: FC<{ orderItem: IOrderItemLog }> = ({ orderItem }) => {
    return (
        <div key={orderItem.id} className={styles.orderItem} >

            <div className={styles.productName} >
                <span className={styles.quantity}>{orderItem.quantity} - </span>
                <span className={styles.productName} >{orderItem.name}</span>
            </div>
            <div className={styles.price} >{orderItem.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>

            {/* <div className={styles.actions}>
                <IconButton><Icon>print</Icon></IconButton>
            </div> */}
        </div>
    )
}