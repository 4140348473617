import React, { FC } from 'react'
import styles from './TableInput.module.scss'
import KeyboardInput from '../../../components/_keyboard/_KeyboardInput'
import { ITable } from '../../../modules/consume/domain/models/ITables';
import { on } from 'events';
import Keyboard from '../../../components/_keyboard/Keyboard';
import { Button, Icon } from '@mui/material';
export interface ITableInputProps {
    //propertys
    onSubmit: (number: string) => void;
    error?: string
    showOpenTableButton?: boolean
    onOpenTable?: () => void
}
const TableInput: FC<ITableInputProps> = ({ error, onSubmit, showOpenTableButton, onOpenTable }) => {

    const tableInputRef = React.useRef<HTMLInputElement>(null);

    const onSubmitHandle = (_, number: string) => {
        onSubmit(number);
    }

    return (
        <div id={styles.TableInput} >
            <div className={styles.container} >
                <KeyboardInput
                    error={error}
                    id='table'
                    inputRef={tableInputRef}
                    onSubmit={onSubmitHandle}
                    label='Mesa'
                />
                {showOpenTableButton && <Button variant='contained' size='large' onClick={onOpenTable}><Icon>add</Icon>Abrir mesa</Button>}
            </div>
            <Keyboard inputRef={tableInputRef} onSubmit={onSubmitHandle} />
        </div>
    )
}
export default TableInput