import React, { FC, useCallback, useState } from 'react'
import styles from './OrderPadPage.module.scss'
import Toolbar from '../../components/ui/toolbar/Toolbar';
import UseOrderPadPage from './UseOrderPadPage';
import { ITable } from '../../modules/consume/domain/models/ITables';
import { Drawer } from '@mui/material';
import { OperationMode } from '../../modules/device/presentation/context/OperationMode';
import { InputTableMode } from '../../modules/device/interface/Device';

import TableInput from './tableInput/TableInput';
import OrderPadInput from './orderPadInput/OrderPadInput';
import { useUi } from '../../modules/ui/presentation/context/UiContext';
import CustomerForm from '../TableMap/components/customerForm/CustomerForm';
import UseDeviceStore from '../../modules/device/store/DeviceStore';
import useOperatorStore from '../../modules/operator/store/OperatorStore';
import { useShallow } from 'zustand/react/shallow'
import { hideLoading, showLoading } from '../../modules/ui/stores/UiStore';
export interface OrderPadPageProps {
    //propertys
}

export interface IOrderPadFormValue {
    orderPadTag?: string,
    table?: ITable
}

const OrderPadPage: FC<OrderPadPageProps> = () => {

    const { tableList, onSubmitHandle, operationMode, getTableList, setSelectedTable, onSubmitOpenAccount } = UseOrderPadPage();
    const { toast } = useUi();
    // //Referencias dos inputs para o teclado
    // const operatorInputRef = React.useRef<HTMLInputElement>(null);
    const tableInputRef = React.useRef<HTMLInputElement>(null);
    const [openTableButton, setOpenTableButton] = useState<boolean>(false);
    const [device, deviceConfig] = UseDeviceStore(state => [state.device, state.deviceConfig]);
    const [openAccountForm, setOpenAccountForm] = useState<boolean>(false)

    const currentOperator = useOperatorStore(useShallow(state => state.currentOperator));

    //Estado de erros
    const [errors, setError] = useState<{ operator?: string, table?: string, orderPad?: string }>();



    const validateTable = useCallback(async (tableNumber: string) => {
        try {
            showLoading('@orderPadPage/validateTable', 'Validando mesa');
            const _tables = await getTableList();
            const _selectedTable = _tables?.find(table => table.number === tableNumber);
            if (_selectedTable) {
                if (!_selectedTable.openedAt) {
                    setError({ table: 'Mesa fechada' });
                    setOpenTableButton(true);
                    setSelectedTable(_selectedTable);
                    return false;
                }
                if (_selectedTable.startClosingAt) {
                    setError({ table: 'Mesa em fechamento' });
                    return false;
                }
                setError(prev => ({ ...prev, table: undefined }));

                setOpenTableButton(false);
                setSelectedTable(undefined);
                if (_tables) return true;
            }
            tableInputRef.current?.setSelectionRange(0, tableInputRef.current.value.length);
            setError({ table: 'Mesa inválida' });
            setOpenTableButton(false);
            return false;
        } catch (e) {
            toast('Erro ao validar mesa');
        } finally {
            hideLoading('@orderPadPage/validateTable');
        }
    }, [getTableList, setSelectedTable, toast]);


    const onSubmitTable = useCallback(async (value: string) => {
        if (await validateTable(value)) {
            const _table = tableList?.find(o => o.number === value);
            onSubmitHandle({ table: _table });
        }
    }, [validateTable, tableList, onSubmitHandle]);

    const onSubmitOrderPad = useCallback(async (value: string) => {
        onSubmitHandle({ orderPadTag: value });
    }, [onSubmitHandle]);





    return (
        <div id={styles.OperatorPage} >
            <Toolbar />
            <div className={styles.container} >
                {!!currentOperator && <div>Bem vindo(a) {currentOperator?.name}</div>}
                <div className={styles.form} >
                    {
                        operationMode === OperationMode.TABLE && deviceConfig?.terminal.tableMode !== InputTableMode.MAP &&
                        <TableInput
                            onSubmit={onSubmitTable}
                            error={errors?.table}
                            onOpenTable={() => setOpenAccountForm(true)}
                            showOpenTableButton={openTableButton}
                        />
                    }
                    {
                        operationMode === OperationMode.ORDER_PAD &&
                        <OrderPadInput
                            onSubmitHandle={onSubmitOrderPad}
                            error={errors?.orderPad}
                        />
                    }
                </div>
            </div>
            <Drawer open={openAccountForm} anchor='right' onClose={() => setOpenAccountForm(false)} >
                <CustomerForm onClose={() => setSelectedTable(undefined)} onSubmit={onSubmitOpenAccount} />
            </Drawer>
        </div>
    )
}
export default OrderPadPage

