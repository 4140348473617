import React, { FC, useCallback, useEffect } from 'react'
import styles from './WeightInput.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext'
import { WheighMaskNumber, WheighToFloat } from '../../../../cross-cutting/WheighMask'
export interface IWeightInputProps {
    //propertys
    onChange?: (value: number) => void
    onFocusInput?: (inputRef: React.RefObject<HTMLInputElement>) => void
    error?: string
    price?: number
    onSubmit?: () => void
}
const WeightInput: FC<IWeightInputProps> = ({ onChange, onFocusInput, error, price, onSubmit }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard()



    const onChangeHandle = useCallback((value: string) => {
        if (inputRef.current) {
            const _moneyValue = WheighToFloat(value)
            inputRef.current.value = WheighMaskNumber(_moneyValue)
            onChange?.(Number(_moneyValue.toFixed(2)))
        }
    }, [onChange])


    const onFocusHandle = React.useCallback(() => {
        configureKeyboard({
            inputRef,
            onChange: onChangeHandle,
            type: 'numeric',
            onSubmit
        })
        onFocusInput?.(inputRef)
    }, [configureKeyboard, onChangeHandle, onFocusInput, onSubmit])

    useEffect(() => {
        onFocusHandle();
    }, [onFocusHandle])
    useEffect(() => {
        onChange?.(0);
    }, [onChange])


    return (
        <>
            <div id={styles.WeightInput
            } >
                <KeyboardInput
                    error={error}
                    multiline
                    inputRef={inputRef}
                    onFocus={onFocusHandle}
                    autoFocus
                    endAdornment={'kg'}
                    label={'Peso'}
                    onChange={onChangeHandle}
                    onSubmit={onSubmit}
                />
            </div>
            {/* <KeyboardModal
                inputRef={inputRef}
            >
                <Keyboard
                    inputRef={inputRef}
                    type='qwerty'
                    onChange={onChange} />
            </KeyboardModal> */}
        </>
    )
}
export default WeightInput
