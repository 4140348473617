import { IVerifyTerminalNavigatorSessionRequest, IVerifyTerminalNavigatorSessionResponse } from "../../domain/dtos/VerifyTerminalNavigatorSessionResponse"
import { IDeviceNavigator } from "../../domain/interfaces/IDeviceNavigator"
import { IDeviceService } from "../../domain/interfaces/IDeviceService"

const VerifyTerminalNavigatorSessionUseCase = async (deviceService: IDeviceNavigator, localId: string, deviceId: string, navigatorSessionId: string): Promise<IVerifyTerminalNavigatorSessionResponse> => {
    const request: IVerifyTerminalNavigatorSessionRequest = {
        localId,
        deviceId,
        navigatorSessionId,
        navigatorUseAgent: navigator.userAgent
    }

    const response = await deviceService.verifyTerminalNavigatorSession(request)
    return (response)
}
export default VerifyTerminalNavigatorSessionUseCase


