import React, { FC, useCallback, useState } from 'react';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import { useUi } from '../../ui/presentation/context/UiContext';
import GetExtractUseCase from '../application/useCases/extract/GetExtractUseCase';
import { IExtract } from '../domain/models/Extract';
import { hideLoading, showLoading } from '../../ui/stores/UiStore';
const UseExtract = () => {
  const [extract, setOrderPad] = useState<IExtract>();
  const [onLoadingExtract, setOnLoadingExtract] = useState(false);
  const {  toast } = useUi();

  const getExtract = useCallback(
    async (orderPadId: string, localId: string) => {
      try {
        showLoading('@getExtract', 'Obtendo extrato');
        setOnLoadingExtract(true)

        const consumptionService = ConsumeApi();
        const response = await GetExtractUseCase(consumptionService, localId, orderPadId);
        setOrderPad(response);
        console.log('EXTRACT', response);
        if (response) {
          return response;
        } else {
          toast('Verifique se a comanda está aberta', 'warning');
        }

      } finally {
        hideLoading('@getExtract');
        setOnLoadingExtract(false)
      }
    },
    [toast],
  );

  return { getExtract, extract, onLoadingExtract };
};

export default UseExtract;
