import React, { FC, RefObject, useEffect, useRef } from 'react';
import styles from '../CatalogV2.module.scss';
import { IOrderComposition } from '../../../../../modules/cart/domain/models/IOrderComposition';
import { IProduct } from '../../../../../modules/catalog/domain/models/IProduct';
import { IProductCategory } from '../../../../../modules/catalog/domain/models/IProductCategory';
import ProductItem from '../../productItem/ProductItem';
import useOnScreen from '../../../../../components/onScreen/UseOnScreen';



interface ICategoryProps {
    category: IProductCategory;
    onClickAddProduct: (product: IProduct, quantity?: number, composition?: IOrderComposition[], notes?: string) => void;
    onVisible: () => void;
    onClickInputObservation?: (inputRef: RefObject<HTMLInputElement | HTMLDivElement>, block?: 'start' | 'center') => void;
    disabledCart?: boolean;
}


export const Category: FC<ICategoryProps> = ({ category, onClickAddProduct, onVisible, onClickInputObservation, disabledCart }) => {


    const ref = useRef<HTMLDivElement>(null);

    const isVisible = useOnScreen(ref);
    useEffect(() => {
        if (isVisible) {
            onVisible?.();
        }

    }, [isVisible, onVisible]);


    return <div className={styles.categoryContainer} id={category.id} ref={ref}>
        <div className={styles.categoryTitle}>{category.title}</div>
        <div className={styles.productsContainer}>
            {category?.products.length ? category?.products.map((product) => <ProductItem
                key={product.id}
                product={product}
                onClickAddProduct={onClickAddProduct}
                disabledCart={disabledCart}
                onClickEditObservation={onClickInputObservation} />
            )
                :
                <div className={styles.noProduct}>
                    <div className={styles.noProductText}>Nenhum produto encontrado</div>
                </div>}
        </div>
    </div>;


};