import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UseDeviceNavigatorStore from '../../store/DeviceNavigatorStore';
import UseDeviceStore from '../../store/DeviceStore';
import DeviceNavigatorService from '../../../../services/api/device/DeviceNavigatorService';
import EncryptedStorage from '../../../../services/localStorage/encryptedStorage/EncryptedStorage';
import { v4 } from 'uuid';
import { hideLoading, showLoading } from '../../../ui/stores/UiStore';
import { PORTAL_URL } from "../../../../services/api/bases/Constants";
import ApproveDeviceUseCase from '../../application/DeviceNavigator/ApproveDeviceUseCase';
import UseSessionStore from '../../../session/store/SessionStore';
import useOperatorStore from '../../../operator/store/OperatorStore';
import { OperationMode } from '../context/OperationMode';
import { InputTableMode } from '../../interface/Device';
import ClearOrderLogUseCase from '../../../orderLog/application/ClearOrderLogUseCase';
import OrderLogRepository from '../../../../services/db/OrderRepository';

export const UseVerifyNavigator = () => {

    const { verifyDevice, solicitationId, terminalId } = UseDeviceNavigatorStore();
    const [device, getDevice, clearDevice, operationMode, deviceConfig] = UseDeviceStore(state => [state.device, state.getDevice, state.clearDevice, state.operationMode, state.deviceConfig]);
    const { clearCurrentOperator, clearOperators, currentOperator } = useOperatorStore(state => ({ clearOperators: state.clearOperators, clearCurrentOperator: state.clearCurrentOperator, currentOperator: state.currentOperator}));
    const { session, clearSession } = UseSessionStore(state => ({ session: state.session, clearSession: state.clearSession }));
    const navigator = useNavigate();

    const goToNotFoundDevice = useCallback(() => {
        navigator('/notFoundDevice');
    }, [navigator]);

    const gotoSession = useCallback(() => {
        if (currentOperator === undefined) navigator('/session');
    }, [currentOperator, navigator]);

    const gotoNavigatorSessionPage = useCallback((localId: string, deviceId: string) => {
        navigator(`/navigator/${localId}/${deviceId}`);
    }, [navigator]);


    const clearOrderLog = useCallback(() => {
        const repository = new OrderLogRepository();
        ClearOrderLogUseCase(repository);
    },[])

    const resetCache = useCallback(() => {
        clearDevice();
        clearCurrentOperator();
        clearOperators();
        clearSession();
        clearOrderLog();
    }, [clearCurrentOperator, clearDevice, clearOperators, clearOrderLog, clearSession])


    useEffect(() => {
        console.log('device', device);
    }, [device]);
    useEffect(() => {
        console.log('session', session);
    }, [session]);


    const verifyNewDeviceHandlerAndRedirect = useCallback(async (localId: string, deviceId: string) => {
        const service = DeviceNavigatorService();
        const NavigatorSessionId = await EncryptedStorage().getItem("@NAVIGATOR");
        resetCache();
        await getDevice(deviceId);
        if (NavigatorSessionId !== null) {
            const response = await verifyDevice(service, localId, deviceId, NavigatorSessionId);
            if (response.terminalId) {
                gotoSession();
            }
        } else {
            const NavigatorSessionId = v4();
            await EncryptedStorage().setItem("@NAVIGATOR", NavigatorSessionId);
            const response = await verifyDevice(service, localId, deviceId, NavigatorSessionId);
            if (response.terminalId) {
                gotoSession();
            }
        }

    }, [getDevice, gotoSession, resetCache, verifyDevice]);

    const verifyNewDeviceHandler = useCallback(async (localId: string, deviceId: string) => {
        const service = DeviceNavigatorService();
        const NavigatorSessionId = await EncryptedStorage().getItem("@NAVIGATOR");
        resetCache();
        const device = await getDevice(deviceId);
        if (NavigatorSessionId !== null) {
            const response = await verifyDevice(service, localId, deviceId, NavigatorSessionId);
            return response;

        } else {
            const NavigatorSessionId = v4();
            await EncryptedStorage().setItem("@NAVIGATOR", NavigatorSessionId);
            const response = await verifyDevice(service, localId, deviceId, NavigatorSessionId);
            return response;
        }

    }, [getDevice, resetCache, verifyDevice]);

    const verifyCurrentDeviceHandler = useCallback(async () => {
        const service = DeviceNavigatorService();
        const navigatorSession = await EncryptedStorage().getItem("@NAVIGATOR");
        if (!device?.localId || !device?.id) {
            goToNotFoundDevice();
            return;
        }
        if (navigatorSession !== null) {
            const response = await verifyDevice(service, device?.localId, device.id, navigatorSession);
            if (!response?.terminalId) {
                gotoNavigatorSessionPage(device?.localId, device?.id);
                return;
            }
        } else {
            gotoNavigatorSessionPage(device?.localId, device?.id);
            return;
        }
    }, [device?.id, device?.localId, goToNotFoundDevice, gotoNavigatorSessionPage, verifyDevice]);


    const approveDeviceHandler = useCallback(async () => {
        try {
            if (device) {
                showLoading('@approveDevice', 'Verificando dispositivo');
                const service = DeviceNavigatorService();
                let _solicitationId = solicitationId;
                if (!_solicitationId) {
                    const navigatorId = await EncryptedStorage().getItem("@NAVIGATOR");
                    const response = await verifyDevice(service, device.localId, device.id, navigatorId ?? v4());
                    if (response.navigatorSolicitationId) {
                        _solicitationId = response.navigatorSolicitationId;
                    }
                }
                if (_solicitationId) {
                    await ApproveDeviceUseCase(service, device.localId, _solicitationId);
                }
                const navigatorId = await EncryptedStorage().getItem("@NAVIGATOR");
                const response = await verifyDevice(service, device.localId, device.id, navigatorId ?? v4());
                return response;
            }
        } finally {
            hideLoading('@approveDevice');
        }
    }, [device, solicitationId, verifyDevice]);












    return { verifyNewDeviceHandlerAndRedirect, verifyNewDeviceHandler, verifyCurrentDeviceHandler, approveDeviceHandler };
};

