import { IDevice } from "../../../../device/interface/Device";
import { IOperator } from "../../../../operator/domains/models/Operator";
import { ITicketPrintOrder } from "../../../models/ITicketPrintOrder";

interface Props {
    ticket: ITicketPrintOrder;
    width?: number;
    onLoad?: () => void;
    qrCode?: string;
}

const OrderTicketPrinterLayout: React.FC<Props> = ({ ticket, width, onLoad, qrCode }) => {
    return (
        <div
            style={{
                padding: '12px',
                overflow: 'visible',
                gap: '0',
                textAlign: 'center',
                zIndex: '1',
                width: width ? `${width}px` : '250px',
                fontFamily: 'Arial, sans-serif',
            }}
            onLoad={onLoad}
        >
            <div style={{ marginBottom: "8px", textAlign: "center" }}>{ticket.local}</div>

            {!!ticket.codePanel && <p style={{ borderBottom: "2px solid black", paddingBottom: "10px" }}>Senha: {ticket.codePanel}</p>}
            <div style={{ textAlign: "center" }}>
                <img src={qrCode} alt="QRCode" width={'90px'} />
            </div>
            {ticket.table && <div style={{ fontSize: "16px", textAlign: "center", display: 'flex', justifyContent: 'center', gap: 4, fontWeight: 'bold' }}>
                <div>mesa: </div>
                <div>{ticket.table}</div>
            </div>}
            <div style={{ margin: "10px 0", textAlign: "center", borderBottom: "2px dashed black", paddingBottom: "10px" }}>

                <div key={ticket.productId} style={{ fontSize: "16px" }}>
                    <div><b>{ticket.name}</b></div>
                    <div><b>{ticket.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></div>
                </div>
            <div style={{ fontSize: "12px", display: 'flex', flexDirection: 'column', margin: 16, gap: 4}}>
                    <div>
                        <b>Observações:</b>
                    </div>
                    {ticket.compositions?.map(composition => (

                        <div key={composition.descrpition} style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            textAlign: "left",
                            fontSize: "12px",
                            gap: 4
                        }}>
                            <b>{composition.descrpition}</b>
                            {composition.items.map(item => (
                                <div key={item.name} style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    flexDirection: "row",
                                    flex: 1
                                }}>
                                    <div>{item.quantity}x {item.name}</div>
                                    <div>{item.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                </div>
                            ))}
                        </div>
                    ))}

                    {
                        ticket.notes && <div style={{ fontSize: "12px" }}>
                            <b>Observações:</b>
                            <div>{ticket.notes}</div>
                        </div>
                    }
                </div>

                <div key={ticket.productId} style={{ fontSize: "9px" }}>
                    <div>Terminal: {ticket.device}</div>
                    {ticket.operator && <div>Operador: {ticket.operator}</div>}
                </div>
            </div>
        </div>
    );
};

export default OrderTicketPrinterLayout;