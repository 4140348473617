import { create } from "zustand";
import { ILoadItem } from "../presentation/context/UiContext";

interface IUiStore {
    loadCount: ILoadItem[];
    showLoading: (id: string, message?: string) => string;
    hideLoading: (id: string) => void;
}

const useUiStore = create<IUiStore>((set) => ({
    loadCount: [],
    showLoading: (id: string, message?: string) => {
        set(store => ({ loadCount: [...store.loadCount, { id, message }] }));
        return id;
    },
    hideLoading: (id: string) => {
        set(store => ({ loadCount: store.loadCount.filter(item => item.id !== id) }));
    },
    
}))
export default useUiStore;

export const showLoading = useUiStore.getState().showLoading;
export const hideLoading = useUiStore.getState().hideLoading;
