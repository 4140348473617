
import { IOrderLogRepository, IPaginatedOrderLog } from "../../modules/orderLog/application/interfaces/IOrderRepository";
import { IInvoiceLog, IOrderItemLog, IOrderLog } from "../../modules/orderLog/domain/IOrderLog";


class OrderLogRepository implements IOrderLogRepository {
    private readonly dbName = "MeepTerminalDB";
    private readonly storeName = "orders";

    constructor() {
        this.initDB();
    }
    public getLastOrder(): Promise<IOrderLog | null> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const request = objectStore.openCursor(null, "prev");
            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
                if (cursor) {
                    resolve(cursor.value);
                } else {
                    resolve(null);
                }
            };
            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    public clearOrderLogs(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.clear();
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    private initDB(): Promise<IDBDatabase> {

        return new Promise(async (resolve, reject) => {
            const request = indexedDB.open(this.dbName, 2);

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;
                const objectStore = db.createObjectStore(this.storeName, { keyPath: "id" });
                objectStore.createIndex("createdAt", "createdAt", { unique: false });
            };

            request.onsuccess = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;
                resolve(db);
            };

            request.onerror = (event) => {
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

    private async getObjectStore(mode: IDBTransactionMode): Promise<IDBObjectStore> {
        const db = await this.initDB();
        const transaction = db.transaction(this.storeName, mode);
        return transaction.objectStore(this.storeName);
    }

    createOrder(order: IOrderLog): Promise<IOrderLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.add(order);

            request.onsuccess = () => {
                resolve(order);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getOrder(orderId: string): Promise<IOrderLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const request = objectStore.get(orderId);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getOrders(pagination: { page: number, pageSize: number }): Promise<IOrderLog[]> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const orders: IOrderLog[] = [];
            const pageSize = pagination.pageSize;
            let cursorIndex = 0;
            const request = objectStore.index("createdAt").openCursor();
            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
                if (cursor) {
                    if (cursorIndex >= (pagination.page - 1) * pageSize && cursorIndex < pagination.page * pageSize) {
                        orders.push(cursor.value);
                    }
                    cursorIndex++;
                    cursor.continue();
                } else {
                    resolve(orders);
                }
            };
            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getOrdersPaginated(pagination: { page: number, pageSize: number, }): Promise<{ items: IOrderLog[], page: number, pageSize: number, totalPages: number }> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const orders: IOrderLog[] = [];
            const pageSize = pagination.pageSize;
            let cursorIndex = 0;
            const request = objectStore.index("createdAt").openCursor(null, "prev");
            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
                if (cursor) {
                    if (cursorIndex >= (pagination.page - 1) * pageSize && cursorIndex < pagination.page * pageSize) {
                        orders.push(cursor.value);
                    }
                    cursorIndex++;
                    cursor.continue();
                } else {
                    resolve({ items: orders, page: pagination.page, pageSize: pagination.pageSize, totalPages: Math.ceil(cursorIndex / pageSize) });
                }
            };
            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    updateOrder(order: IOrderLog): Promise<IOrderLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.put(order);

            request.onsuccess = () => {
                resolve(order);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    deleteOrder(orderId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.delete(orderId);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    createOrderItem(orderItem: IOrderItemLog): Promise<IOrderItemLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.add(orderItem);

            request.onsuccess = () => {
                resolve(orderItem);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getOrderItem(orderItemId: string): Promise<IOrderItemLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const request = objectStore.get(orderItemId);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getOrderItems(): Promise<IOrderItemLog[]> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readonly");
            const request = objectStore.getAll();

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    updateOrderItem(orderItem: IOrderItemLog): Promise<IOrderItemLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.put(orderItem);

            request.onsuccess = () => {
                resolve(orderItem);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    deleteOrderItem(orderItemId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request = objectStore.delete(orderItemId);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    updateInvoice(orderId: string, invoice: IInvoiceLog[]): Promise<IOrderLog> {
        return new Promise(async (resolve, reject) => {
            const objectStore = await this.getObjectStore("readwrite");
            const request: IDBRequest<IOrderLog> = objectStore.get(orderId);

            request.onsuccess = () => {
                let order = request.result;
                order.invoice = invoice;
                const updateRequest = objectStore.put(order);

                updateRequest.onsuccess = () => {
                    resolve(order);
                };

                updateRequest.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                };
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }
}

export default OrderLogRepository;