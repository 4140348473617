import { AxiosInstance } from 'axios';
import { GetSharedOrderPadsResponse } from '../../../modules/consume/domain/dtos/GetSharedOrderPadResponse';
import { ISharedOrderPad } from '../../../modules/consume/domain/models/SharedOrderPad';
import { GetTableItemResponse } from '../../../modules/consume/domain/dtos/GetTablesResponse';

const AnticorruptionConsumeApi = (api: AxiosInstance) => {
  const getSharedOrderOldApi = async (localId: string): Promise<GetSharedOrderPadsResponse[]> => {
    interface IComandasResponse {
      id: string;
      numero: string;
      abertoEm?: string;
      dataInicioFechamento?: string;
      tipo?: number;
      documento?: string;
      saldo?: number;
      status?: number;
    }

    const response = await api.get<
      IComandasResponse[]
    >(`dispositivo/${localId}/comandas/1`);
    return response.data.map(item => ({ id: item.id, number: item.numero }));
  };
  const getTablesOrderOldApi = async (localId: string): Promise<GetTableItemResponse[]> => {
    const response = await api.get<
      {
        id: string;
        numero: string;
        abertoEm?: string;
        dataInicioFechamento?: string;
        tipo?: number;
        documento?: string;
        saldo?: number;
        status?: any;
      }[]
    >(`dispositivo/${localId}/comandas/1`);
    return response.data.map(item => ({
      id: item.id,
      number: item.numero,
      openedAt: item.abertoEm,
      startClosingAt: item.dataInicioFechamento,
      type: item.tipo,
      consume: 0.00,
      document: item.documento,
      balance: item.saldo,
      status: item.status,
    }));
  };

  const openTable = ()=>{
    
  }

  return {
    getSharedOrderOldApi,
    getTablesOrderOldApi
  };
};
export default AnticorruptionConsumeApi;




//abertura de mesa 
// /api/CommandEletronic/OpenAccount
// @SerializedName("currentSessionId")
// private UUID currentSessionId;
// @SerializedName("localId")
// private UUID localId;
// @SerializedName("cardId")
// private UUID cardId;
// @SerializedName("cardTag")
// private String cardTag;
// @SerializedName("userAccount")
// private UserAccountRequest userAccount;
// @Nullable
// @SerializedName("order")
// private OrderRequest order;
// @SerializedName("minimumConsumption")
// private Double minimumConsumption;
// @SerializedName("consumptionLimit")
// @Nullable
// private Double consumptionLimit;
// @SerializedName("groupId")
// private UUID groupId;
// @SerializedName("equipmentName")
// private String equipmentName;
// @SerializedName("equipamentId")
// private UUID equipamentId;
// @SerializedName("operatorId")
// private UUID operatorId;
// @SerializedName("operatorName")
// private String operatorName;
