import React, { FC, useEffect } from 'react'
import { Tab, Tabs } from '@mui/material'
import styles from './CategoryListTab.module.scss'
interface ICategoryItem {
    title: string;
    id: string;
}

export interface ICategoryListTabProps {
    //propertys
    vertical?: boolean
    categories: ICategoryItem[];
    onChangeCategory: (id: string) => void;
    defaultValue?: string;
}
const CategoryListTab: FC<ICategoryListTabProps> = ({ categories, vertical, onChangeCategory, defaultValue }) => {
    const [value, setValue] = React.useState(!!categories.length && categories[0].id);
    useEffect(() => {
        categories.length && setValue(categories[0].id)
    }, [categories])


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        onChangeCategory(newValue)
    };
    return (
        <div className={vertical ? styles.verticalScroll : styles.horizontalScroll}>

            <Tabs
                classes={{
                    root: styles.tabsRoot,
                }}
                // style={{ width: vertical ? 300 : '100%' }}
                indicatorColor='primary'

                onChange={handleChange}
                variant='scrollable'
                // value={defaultValue}
                value={value}
                orientation={vertical ? "vertical" : "horizontal"}
                scrollButtons={"auto"}
            >
                {
                    categories.map((category, key) => (
                        <Tab
                            key={key}
                            label={category.title}
                            wrapped

                            value={category.id}
                            classes={{
                                root: styles.tabRoot,
                                selected: styles.tabSelected,
                            }} />
                    ))
                }
            </Tabs>
        </div>
    )
}
export default CategoryListTab