import { useCallback, useEffect } from 'react';
import AuthenticationService from '../../../../services/api/authentication/AuthenticationService';

import { useUi } from '../../../ui/presentation/context/UiContext';
import UseDeviceStore from '../../../device/store/DeviceStore';
import useAuthStore from '../../stores/AuthStore';
import { Api } from '../../../../services/api/bases/Api';
import { hideLoading, showLoading } from '../../../ui/stores/UiStore';



export const UseAuth = () => {
  const { auth, getAuth } = useAuthStore();
  const [device, getDeviceConfig, deviceConfig, getHub, getPrinterSector, getSimplePos, simplePos] = UseDeviceStore(
    (state) => [state.device, state.getDeviceConfig, state.deviceConfig, state.getHub, state.getReceiptPrinterSector, state.getSimplePos, state.simplePos]
  );

  const setToken = useCallback((token: string) => {
    Api().defaults.headers.common.Authorization = `Bearer ${token}`;
  }, []);

  const getAuthenticationHandle = useCallback(
    async (_identifier: string) => {
      try {
        showLoading('@authentication-server', 'Autenticando');
        if (_identifier) {
          const authService = AuthenticationService();
          await getAuth(authService, _identifier, setToken);
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideLoading('@authentication-server');
      }
      return () => { };
    }, [getAuth, setToken]);

  useEffect(() => {
    if (device) {
      getAuthenticationHandle(device.identifier);
    }
  }, [device, getAuthenticationHandle]);

  useEffect(() => {
    if (auth && device && !device.getDeviceConfigOnSession) {
      getDeviceConfig(device);
      getHub(device.localId);
    }
  }, [auth, device, getDeviceConfig, getHub])

  useEffect(() => {
    if (auth && deviceConfig?.printer?.receiptPrinterSectorId) {
      getPrinterSector(deviceConfig?.printer.receiptPrinterSectorId);
    }
  }, [auth, deviceConfig?.printer, getPrinterSector])

  useEffect(() => {
    if (auth && device) {
      getSimplePos(device?.localId, device?.id);
    }
  }, [auth, device, getSimplePos])

  return { auth }
};


