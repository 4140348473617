import React, { FC, useCallback, useEffect } from 'react'
import styles from './OrderPadInput.module.scss'
import KeyboardInput from '../../../components/_keyboard/_KeyboardInput';
import { useKeyboard } from '../../../components/_keyboard/KeyboardContext';
import { Button } from '@mui/material';
export interface IOrderPadInputProps {
    //propertys
    onSubmitHandle: (orderPadTag: string) => void;
    error?: string | undefined;
}
const OrderPadInput: FC<IOrderPadInputProps> = ({ onSubmitHandle, error }) => {

    const orderpadInputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard()
    const onSubmitInputHandle = useCallback((ref: React.RefObject<HTMLInputElement>, value: string) => {
        onSubmitHandle(value);
    }, [onSubmitHandle])

    useEffect(() => {
        orderpadInputRef.current?.focus();
        configureKeyboard({ type: 'qwerty', inputRef: orderpadInputRef, onSubmit: onSubmitInputHandle })
    }, [configureKeyboard, onSubmitInputHandle])

    const onSubmit = () => {
        onSubmitHandle(orderpadInputRef.current?.value as string);
    }

    return (
        <div id={styles.OrderPadInput} >
            <KeyboardInput
                error={error}
                id='orderPad'
                inputRef={orderpadInputRef}
                onSubmit={onSubmitInputHandle}
                label='Comanda'
            />
            <Button variant={'outlined'} onClick={onSubmit} size='large'>Confirmar</Button>
        </div>
    )
}
export default OrderPadInput