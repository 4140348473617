import { Theme, createTheme } from "@mui/material";

const secondary = "#32008e";
const primary = "#955CFF";
const text = "#5f5f5f";

export const themeMUI: Theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    allVariants: {
      color: text,
      textTransform: "none",
      fontFamily: `
       Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;`,
    },
  },
});
