import { PaymentType } from "../../consume/domain/models/PaymentType";
import { SystemType } from "../domain/enums/SystemType";
import { InputTableMode, OperatorMode } from "./Device";

export interface PaymentMethod {
    type: PaymentType;
    name: string;
    enabled: boolean;
}

export interface ServiceFee {
    value: number;
    enabled: boolean;
}

export interface Payment {
    serviceFee: ServiceFee;
    isShowChangeMoneyEnabled: boolean;
    isPaymentDivisorEnabled: boolean;
    methods: PaymentMethod[];
    customMethods: PaymentMethod[];
}

export interface CustomerIdentification {
    required: boolean;
    identification: {
        name: string;
        value: number;
    };
    enabled: boolean;
}


export enum PrintTypeEnum {
    TICKET = 1,
    RESUME = 2,
    NONE = 3,
    GROUPED_RESUME = 4,
    SIMPLE_RESUME = 5,
}

export interface OperationMethod {
    type: SystemType;
    name: string;
    printType: PrintTypeEnum;
    enabled: boolean;
    customerIdentification: CustomerIdentification[];
}

export interface Operation {
    methods: OperationMethod[];
}

export interface IDeviceProfile {
    id: string;
    name: string;
    localId: string;
    description: string;
    payment: Payment;
    operation: Operation;
    // products: {
    //     productListId: string;
    // };
    terminal: {
        tableMode: InputTableMode;
        operatorMode: OperatorMode;
    };
    printer?: {
        receiptPrinterSectorId?: string;
        remotePrinters: {
            ipAddress: string;
            name: string;
        }[];
    }
}
