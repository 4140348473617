import { IEmitInvoiceRequest } from "../../../modules/invoice/application/dtos/IInvoiceData";
import { IInvoiceService } from "../../../modules/invoice/domain/interfaces/IInvoiceService";
import { Api } from "../bases/Api";
import { IEmitirNfceRequest, INfceItemResponse, SimulateNfceItemResponse, convertNfceToInvoice } from "./anticorruption/INfceResponse";


var api = Api();
const InvoiceService: () => IInvoiceService = () => {
    const EmitInvoice = async (invoice: IEmitInvoiceRequest) => {
        const anticorruptionRequest: IEmitirNfceRequest = {
            pedidoId: invoice.orderId,
            documento: invoice.document,
            ehManual: invoice.isManual
        }

        const response = await api.post<INfceItemResponse[]>('/v2/NFCe/Emitir', anticorruptionRequest);

        // const response = await SimulateNfceItemResponse();
        return convertNfceToInvoice(response.data)

    }
    return ({ EmitInvoice })
}
export default InvoiceService   
