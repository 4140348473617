import { IGetSimplePosByDeviceIdResponse } from "../../../modules/simplePos/application/dtos/IGetSimplePosByDeviceIdResponse"
import { ISimplePosService } from "../../../modules/simplePos/application/interfaces/ISimplePosService"
import { Api } from "../bases/Api";
import { DEVICE_API } from "../bases/Constants";

const api = Api();
const SimplePosService = (): ISimplePosService => {
    const GetSimplePosByDeviceId = async (localId: string, deviceId: string): Promise<IGetSimplePosByDeviceIdResponse> => {
        const response = await api.get<IGetSimplePosByDeviceIdResponse>(`${DEVICE_API}/api/locals/${localId}/simplePos/device/${deviceId}`)
        return response.data;
    }

    return ({
        GetSimplePosByDeviceId
    })
}
export default SimplePosService