import React, { FC, RefObject, useCallback, useEffect, useState } from 'react'
import styles from './MoneyInput.module.scss'
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput'
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext';
import { getCombinationOfNotes } from '../../../../cross-cutting/SugestionNotesMoney';
import { Button } from '@mui/material';
import { moneyMaskNumber, moneyToFloat } from '../../../../cross-cutting/moneyMask';
export interface IPartialPaymentProps {
    //propertys
    onChange?: (value: number) => void,
    defaultValue?: number;
    maxValue?: number
    label?: string
    sugestion?: boolean
    onFocus?: (inputRef: RefObject<HTMLInputElement>) => void
}
const MoneyInput: FC<IPartialPaymentProps> = ({ onChange, maxValue, defaultValue, label, onFocus, sugestion }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard();
    const [combinations, setCombinations] = useState<number[]>();

    useEffect(() => {
        if (defaultValue) {
            if (inputRef.current) {
                inputRef.current.value = moneyMaskNumber(defaultValue);
                // inputRef.current.setSelectionRange(0, inputRef.current.value.length);
                const combination = getCombinationOfNotes(defaultValue);
                setCombinations(combination);

                console.log(combination)
            }
        }
    }, [defaultValue])


    const onChangeHandle = useCallback((value: string) => {
        if (inputRef.current) {
            const _moneyValue = moneyToFloat(value)
            let _value = maxValue && _moneyValue > maxValue ? maxValue : _moneyValue
            inputRef.current.value = moneyMaskNumber(_value)
            onChange?.(_value)

        }

    }, [maxValue, onChange])

    const onSubmit = useCallback(() => {
        inputRef.current?.blur();
    }, [])

    const onClickChangeSugestion = useCallback((_value: number) => {
        onChangeHandle(moneyMaskNumber(_value))
    }, [onChangeHandle])

    useEffect(() => {
        configureKeyboard({
            inputRef: inputRef,
            type: 'numeric',
            onChange: onChangeHandle,
            onSubmit: onSubmit,
        })
    }, [configureKeyboard, onChangeHandle, onSubmit])

    return (
        <>
            {sugestion &&
                <div className={styles.sugestionChange}>
                    {maxValue && <Button variant='text' className={styles.sugestionItem} onClick={() => onClickChangeSugestion(maxValue)}>{moneyMaskNumber(defaultValue)}</Button>}
                    {combinations?.map(item => <Button variant='text' className={styles.sugestionItem} onClick={() => onClickChangeSugestion(item)}>{moneyMaskNumber(item)}</Button>)}
                </div>
            }
            <KeyboardInput onFocus={() => onFocus?.(inputRef)} onChange={onChangeHandle} onSubmit={onSubmit} inputMode='numeric' inputRef={inputRef} label={label} />
        </>
    )
}
export default MoneyInput



