import React, { FC, useCallback, useEffect } from 'react';
import styles from './CancelOrderForm.module.scss'
import { ICancelOrderItemSelectable, ICancelOrderFormValue } from './CancelOrderForm';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import { moneyFormater } from '../../../../services/utils/Money';

interface CancelOrderFormItemProps {
    selectableOrder: ICancelOrderFormValue;
    onChange: (item: ICancelOrderFormValue, checked: boolean) => void;
    onChangeItem: (item: ICancelOrderItemSelectable, checked: boolean) => void;
}

export const CancelOrderFormItem: FC<CancelOrderFormItemProps> = ({ selectableOrder, onChange, onChangeItem }) => {

    const [checked, setChecked] = React.useState(false);

    const onChangeItemHandle = useCallback((item: ICancelOrderItemSelectable, checked: boolean) => {
        onChangeItem(item, checked);
    }, [onChangeItem]);

    const onChangeHandle = useCallback((event, checked: boolean) => {
        onChange(event, checked);
        setChecked(checked);
    }, [onChange]);


    useEffect(() => {
        if (selectableOrder.items.every(item => item.isChecked)) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [selectableOrder.items])


    return <div id={styles.CancelOrderFormItem}>
        <FormControlLabel
            disabled={selectableOrder.isCanceled || selectableOrder.isTransfered}
            checked={checked}
            onChange={(ev, checked) => onChangeHandle(selectableOrder, checked)}
            control={<Checkbox />}
            label={<div className={styles.header}>{new Date(selectableOrder.createdAt + "Z").toLocaleString()}</div>}
        />
        <div className={styles.itemList}>
            {selectableOrder.items.map(item => <div className={styles.item}>
                <FormControlLabel
                    disabled={selectableOrder.isCanceled || selectableOrder.isTransfered}
                    checked={item.isChecked}
                    onChange={(_, checked) => onChangeItemHandle(item, checked)}
                    control={<Checkbox />}

                    label={
                        <div className={styles.itemLabel}>
                            {item.quantity} - {item.name}
                            {/* {item.isChecked ? " - true" : " - false"} */}
                        </div>
                    }
                />
                <span>{moneyFormater(item.totalValue)}</span>
            </div>)}
        </div>
    </div>;
};
