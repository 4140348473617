import React, { FC, RefObject, useCallback, useState } from 'react';
import styles from './PaymentOption.module.scss';
import { Button, Drawer, Icon, Switch } from '@mui/material';
import MoneyInput from '../partialPaymentInput/MoneyInput';
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput';
import Calculator from '../../../catalog/components/calculator/Calculator';
import SplitPaymentOrderForm from '../../../catalog/components/splitPaymentOrderForm/SplitPaymentOrderForm';
import { IExtract } from '../../../../modules/consume/domain/models/Extract';

export interface IPartilaPaymentOption {
    onFocusInput: (inputEvent: RefObject<HTMLInputElement>) => void,
    amountToPay: number,
    total: number,
    extract: IExtract,
    onChange: (value: number) => void
}

const PartialPaymentOption: FC<IPartilaPaymentOption> = ({
    onFocusInput, amountToPay, onChange, total, extract
}) => {
    const [openCalculator, setOpenCalculator] = React.useState(false);
    const [openSpliter, setOpenSpliter] = React.useState(false);
    const [partialPaymentOpen, setPartialPayment] = useState(false);
    const [calculateResult, setCalculateResult] = useState<number>(amountToPay);


    const onCheckPartialPayment = useCallback((_, value: boolean) => {
        setPartialPayment(value);
        onChange(amountToPay);
        if (!value) {
            setCalculateResult(amountToPay);
        }
    }, [onChange, amountToPay]);


    const onChangePartialAmountHandle = useCallback((value: number) => {
        onChange(value);
        setCalculateResult(value);
        setOpenCalculator(false);
    }, [onChange]);

    const onClickCalculatorHandle = useCallback(() => {
        setOpenCalculator(true);
    }, []);

    const onClickSplitPaymentHandle = useCallback(() => {
        setOpenSpliter(true);
    }, []);

    const onSubmintSplitPaymentHandle = useCallback((value: number) => {
        onChangePartialAmountHandle(value);
        setOpenSpliter(false);
    }, [onChangePartialAmountHandle]);

    return <>
        <div className={styles.paymentOption}>
            <span>
                Pagamento parcial
            </span>
            <Switch checked={partialPaymentOpen} onChange={onCheckPartialPayment} />
        </div>
        {partialPaymentOpen &&
            <div className={styles.partialPayment}>
                <MoneyInput
                    onFocus={onFocusInput}
                    defaultValue={calculateResult}
                    maxValue={amountToPay}
                    onChange={onChangePartialAmountHandle} />
                <Button onClick={onClickCalculatorHandle} variant="outlined" ><Icon>calculate</Icon></Button>
                <Button onClick={onClickSplitPaymentHandle} variant="outlined" ><Icon>diversity_3</Icon></Button>
            </div>}
        <Drawer open={openCalculator} anchor='right' onClose={() => setOpenCalculator(false)}>
            <Calculator
                defaultValue={amountToPay}
                totalValue={total}
                onSubmitValue={onChangePartialAmountHandle}
                onClose={() => setOpenCalculator(false)}
            />
        </Drawer>
        <Drawer open={openSpliter} anchor='right' onClose={() => setOpenSpliter(false)}>
            <SplitPaymentOrderForm maxAmoutToPay={amountToPay} onSubmit={onSubmintSplitPaymentHandle} extract={extract} onClose={() => setOpenSpliter(false)} />
        </Drawer>

    </>;

};

export default PartialPaymentOption