
import OrderToSyncOrderRequest from '../../../consume/application/useCases/consume/OrderToSyncOrderRequest';
import { IOrder } from '../../../cart/domain/models/IOrder';
import { ICustomerFormValue } from '../../../../pages/TableMap/components/customerForm/ICustomerFormValue';
import { SyncOrderRequest } from '../../domain/dtos/SyncPrePaidTickets';
import { IPrePaidTicketsService } from '../../domain/interfaces/IPrePaidTicketsService';
import { OrderStatus } from '../../domain/OrderStatus';
import { PaymentType } from '../../../consume/domain/models/PaymentType';
import { IOperator } from '../../../operator/domains/models/Operator';

const SendTicketConsumeOrderUseCase = async (
  ConsputionApi: IPrePaidTicketsService,
  values: {
    localId: string,
    order: IOrder,
    totalAmount: number,
    operator: IOperator,
    registerSessionId: string,
    customer?: ICustomerFormValue,
    paymentType: PaymentType,
    transactionData?: string
    status: number;
    codePanel?: string;
  }
) => {
  const request: SyncOrderRequest = OrderToSyncOrderRequest(
    {
      registerSessionId: values.registerSessionId,
      localId: values.localId,
      order: values.order,
      totalAmount: values.totalAmount,
      operatorId: values.operator.id,
      orderStatus: OrderStatus.PAGAMENTO_REALIZADO,
      customer: values.customer,
      paymentType: values.paymentType,
      transactionData: values.transactionData,
      status: values.status,
      codePanel: values.codePanel,
    }
  );
  await ConsputionApi.syncOrder([request]);
  return values;
};
export default SendTicketConsumeOrderUseCase;
