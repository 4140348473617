import React, { FC, useEffect } from 'react'
import UseTable from '../../modules/consume/presentation/UseTable'
const UseTransferTable = () => {
    const { tableList, getTableList } = UseTable();

    useEffect(() => {
        getTableList();
    }, [getTableList])

    return ({ tableList })
}

export default UseTransferTable