
import { ISession } from "../domain/ISession"
import { ISessionService } from "../domain/interfaces/ISessionService"

const OpenSessionUseCase = async (service: ISessionService, localId: string, operatorId: string): Promise<ISession> => {
    const response = await service.openNewSession({
        localId,
        operatorId
    })
    return (response)
}
export default OpenSessionUseCase