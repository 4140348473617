import React, { FC, useMemo } from 'react'
import styles from './TablePicker.module.scss'
import TableItem from './TableItem'
import { ITableItem } from './ITableItem'
export interface ITablePickerProps {
    //propertys
    isLoading?: boolean
    tableList: ITableItem[]
    onClickTable: (table: ITableItem) => void
}
const TablePicker: FC<ITablePickerProps> = ({ tableList, onClickTable }) => {

    const tables = useMemo(() => <>{tableList.map((table, index) =>
        <TableItem key={table.id + index} table={table} onClickTable={onClickTable} />
    )}</>, [onClickTable, tableList])


    return (
        <div id={styles.TablePicker} >
            <div className={styles.container} >
                <div className={styles.tables} >
                    {tables}
                </div>
            </div>
        </div>
    )
}
export default TablePicker