import { IConsumeService } from '../../../domain/interfaces/IConsumeService';
import { ITableRepository } from '../../../domain/interfaces/tables/ITablesRepository';
import { ITable } from '../../../domain/models/ITables';

const GetCommandsUseCase = async (
  consumeServer: IConsumeService,
  tableRepository: ITableRepository,
  localId: string,
): Promise<ITable[]> => {
  const response = await consumeServer.getCommands(localId);
  tableRepository.saveTables(response);
  return response;
};

export default GetCommandsUseCase;
