import localforage from 'localforage';
import { ITableRepository } from '../../../modules/consume/domain/interfaces/tables/ITablesRepository';
import { ITable } from '../../../modules/consume/domain/models/ITables';

const TableLocalStorage = (): ITableRepository => {
  const key = '@TableLocalStorage';

  const saveTables = async (orderPads: ITable[]): Promise<void> => {
    localforage.setItem(key, orderPads);
  };
  const getTables = async (): Promise<ITable[] | null> => {
    const response = await localforage.getItem<ITable[]>(key);
    return response ? response : null;
  };


  return { saveTables, getTables };
};
export default TableLocalStorage;
