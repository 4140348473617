


export const WheighMask = (text: string) => {
};

export const WheighMaskNumber = (wheigh?: number) => {
    const formattedWheigh = wheigh ? wheigh.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0.000";
    return formattedWheigh;
};

export const WheighToFloat = (text: string) => {
    text = text.replace(/[^0-9]/g, "");
    text = text ? text : '0';
    return Number.parseFloat(text.replace(/[^0-9]/g, "")) / 1000;
};
