import { Button, Icon, IconButton } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { ICartItem } from '../../../../modules/cart/domain/models/ICart';
import styles from './CartView.module.scss';
import Image from '../../../../components/ui/image/Image';
import { ProductType } from '../../../../modules/catalog/domain/models/ProductType';

interface ICartItemProps {
    cartItem: ICartItem;
    onPressRemoveHandle: (cartItem: ICartItem) => void;
    onPressAddHandle: (cartItem: ICartItem) => void;
    onPressRemoveAllHandle: (cartItem: ICartItem) => void;
}
export const CartItem: FC<ICartItemProps> = ({ cartItem, onPressRemoveHandle, onPressAddHandle, onPressRemoveAllHandle }) => {


    const timeRef = React.useRef<number>();
    const [showRemoveAllButton, setShowRemoveAllButton] = React.useState<boolean>(false);
    const timerRef = React.useRef<NodeJS.Timer>();
    // const refButton = React.useRef<HTMLButtonElement>(false);
    const pressedButton = React.useRef<boolean>(false);



    const onMouseUpHandle = useCallback(() => {
        pressedButton.current = false;
        timerRef.current && clearTimeout(timerRef.current);
    }, [])

    const onMousedownHandle = useCallback(() => {
        console.log('keydown')
        timeRef.current = new Date().getTime();
        pressedButton.current = true;
        timerRef.current = setTimeout(() => {
            console.log('timeout')
            if (pressedButton.current) {
                onPressRemoveAllHandle(cartItem);
            }
        }, 2000);
    }, [cartItem, onPressRemoveAllHandle])

    return <div key={cartItem.id} className={styles.cartItem}>
        <div className={styles.main}>
            <div className={styles.cartItemContent}>
                <Image src={cartItem.thumbnailUrl} className={styles.img}></Image>
                <span>
                    {cartItem.name}
                </span>
                <b>
                    {
                        new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartItem.totalPrice * cartItem.quantity)
                    }
                </b>
            </div>
            {(cartItem.product.type === ProductType.Kilo || cartItem.product.type === ProductType.Variavel) ? <div className={styles.quantityContainer}>

                {cartItem.product.type === ProductType.Kilo &&
                    <div className={styles.valueQuantity} style={{ width: "unset" }}>{cartItem.quantity} kg</div>
                }
                <IconButton
                    // onDoubleClick={onKeydownHandle}
                    onMouseUp={onMouseUpHandle}
                    onMouseDown={onMousedownHandle}
                    onClick={() => onPressRemoveHandle(cartItem)}
                    className={styles.buttonQuantity}
                >
                    <Icon>close</Icon>
                </IconButton>
            </div> :
                <div className={styles.quantityContainer}>
                    <IconButton
                        // onDoubleClick={onKeydownHandle}
                        onMouseUp={onMouseUpHandle}
                        onMouseDown={onMousedownHandle}
                        onClick={() => onPressRemoveHandle(cartItem)}
                        className={styles.buttonQuantity}
                    >
                        <Icon>remove</Icon>
                    </IconButton>
                    <div className={styles.valueQuantity}>{cartItem.quantity}</div>
                    <IconButton
                        onClick={() => onPressAddHandle(cartItem)}
                        className={styles.buttonQuantity}
                    >
                        <Icon>add</Icon>
                    </IconButton>
                </div>}
        </div>
        {(cartItem.description || !!cartItem.orderComposition?.length) && <div className={styles.accordeon}>
            <div className={styles.description}>
                {cartItem.description}
            </div>
            <div className={styles.compositions}>
                {cartItem.orderComposition?.map((composition) => (
                    <div className={styles.composition}>
                        <label>{composition.description}</label>
                        {composition.compositionItems.map(
                            item => (
                                <span>{item.quantity} x {item.description} {item.totalPrice === 0 ? '' : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalPrice)}</span>
                            )
                        )}
                    </div>
                ))}
            </div>
            <div className={styles.description}>
                {cartItem.notes}
            </div>
        </div>}
    </div>;
};
