import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './CustomerForm.module.scss'
import { ICustomerFormValue } from './ICustomerFormValue';
import { Button, Icon, IconButton } from '@mui/material';
import { useKeyboard } from '../../../../components/_keyboard/KeyboardContext';
import KeyboardInput from '../../../../components/_keyboard/_KeyboardInput';
import UseTutorialCard from '../../../../modules/onBoarding/presentation/hooks/UseTutorialCard';
import { CustomerIdentification } from '../../../../modules/device/interface/DeviceProfile';

interface ICustomerFormErrors {
    name?: string;
    document?: string;
    documentType?: string;
    birthDate?: string;
    phoneNumber?: string;
    email?: string;
    identifier?: string;
}

export interface ICustomerFormProps {
    //propertys
    onSubmit: (values: ICustomerFormValue) => void
    onClose?: () => void
    title?: string
    handleCustomerData?: CustomerIdentification[]
}

const CustomerForm: FC<ICustomerFormProps> = ({ onSubmit, onClose, title = "Dados do Cliente", handleCustomerData }) => {
  const nameInputRef = useRef<HTMLInputElement>(null);
  const documentInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const documentTypeInputRef = useRef<HTMLInputElement>(null);
  const birthDateInputRef = useRef<HTMLInputElement>(null);
  const identifierInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedInputRef, setSelectedInputRef] = useState<React.RefObject<HTMLInputElement>>();
  const [errors, setErrors] = useState({} as ICustomerFormErrors);

  const { openTutorialCard, closeTutorialCard } = UseTutorialCard();

  const divRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    openTutorialCard({
      element: divRef.current,
      options: {
        title: 'Dados do Cliente',
        description: 'Preencha os campos para abrir uma conta',
        buttonLabel: 'Entendi',
        onClose: () => {
          closeTutorialCard();
        }
      }
    })
  }, [closeTutorialCard, openTutorialCard]);

  const { configureKeyboard } = useKeyboard();

  const validate = useCallback(async () => {
    let valid = true;
    let errorsData = {} as ICustomerFormErrors;

    if (!nameInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Nome" && item.required)) {
      errorsData.name = 'O nome é obrigatório';
      valid = false;
    }
    if (!documentInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "CPF" && item.required)) {
      errorsData.document = 'O CPF é obrigatório';
      valid = false;
    }
    if (!phoneNumberInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Telefone" && item.required)) {
      errorsData.phoneNumber = 'O telefone é obrigatório';
      valid = false;
    }
    if (!emailInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Email" && item.required)) {
      errorsData.email = 'O e-mail é obrigatório';
      valid = false;
    }
    if (!documentTypeInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Etiqueta" && item.required)) {
      errorsData.documentType = 'A etiqueta é obrigatória';
      valid = false;
    }
    if (!birthDateInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Data" && item.required)) {
      errorsData.birthDate = 'A data de nascimento é obrigatória';
      valid = false;
    }
    if (!identifierInputRef.current?.value && handleCustomerData?.find((item) => item.identification.name === "Identificador" && item.required)) {
      errorsData.identifier = 'O identificador é obrigatória';
      valid = false;
    }

    setErrors(errorsData);
    return valid;
  }, [handleCustomerData]);

  const onSubmitHandle = useCallback(async () => {
      const isValid = await validate();
      if (!isValid) return;

      const values: ICustomerFormValue = {
        name: nameInputRef.current?.value ?? "",
        document: documentInputRef.current?.value ?? "",
        birthDate: birthDateInputRef.current?.value ?? "",
        phoneNumber: phoneNumberInputRef.current?.value ?? "",
        email: emailInputRef.current?.value ?? "",
        identifier: identifierInputRef.current?.value ?? "",
        documentType: documentInputRef.current?.value ?? "",
      }
      onSubmit(values);
  }, [onSubmit, validate]);

  const onChangeFocusKeyboard = useCallback((ev: React.FocusEvent<HTMLInputElement, Element>) => {
    if (ev.target?.id === "name") {
        return setSelectedInputRef(nameInputRef);
    }
    if (ev.target?.id === "document") {
        return setSelectedInputRef(documentInputRef);
    }
    if (ev.target?.id === "phoneNumber") {
        return setSelectedInputRef(phoneNumberInputRef);
    }
    if (ev.target?.id === "email") {
        return setSelectedInputRef(emailInputRef);
    }
    if (ev.target?.id === "birthDate") {
        return setSelectedInputRef(birthDateInputRef);
    }
    if (ev.target?.id === "documentType") {
        return setSelectedInputRef(documentTypeInputRef);
    }
    if (ev.target?.id === "identifier") {
        return setSelectedInputRef(identifierInputRef);
    }
  }, [])

  useEffect(() => {
      configureKeyboard({
          type: 'qwerty',
          inputRef: selectedInputRef,
      })
  }, [configureKeyboard, selectedInputRef])

  useEffect(() => {
      if (!selectedInputRef?.current) {
          setSelectedInputRef(nameInputRef);
      }
  }, [nameInputRef, selectedInputRef]);

  const renderInput = (currentData: CustomerIdentification) => {
      switch (currentData.identification.value) {
        case 1:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='name'
              inputRef={nameInputRef}
              label='Nome'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => nameInputRef.current?.focus()}
              error={errors.name}
            />
          );
        case 2:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='document'
              inputRef={documentInputRef}
              label='Documento (CPF)'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => documentInputRef.current?.focus()}
              error={errors.document}
            />
          );
        case 3:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='phoneNumber'
              inputRef={phoneNumberInputRef}
              label='Telefone'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => phoneNumberInputRef.current?.focus()}
              error={errors.phoneNumber}
            />
          );
        case 4:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='email'
              inputRef={emailInputRef}
              label='E-mail'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => emailInputRef.current?.focus()}
              error={errors.email}
            />
          );
        case 5:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='birthDate'
              inputRef={birthDateInputRef}
              label='Data de nascimento'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => birthDateInputRef.current?.focus()}
              error={errors.birthDate}
            />
          );
        case 6:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='documentType'
              inputRef={documentTypeInputRef}
              label='Data de nascimento'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => documentTypeInputRef.current?.focus()}
              error={errors.documentType}
            />
          );
        case 7:
          return currentData.enabled && (
            <KeyboardInput
              key={currentData.identification.value}
              id='identifier'
              inputRef={identifierInputRef}
              label='Identificador'
              required={currentData.required}
              onFocus={onChangeFocusKeyboard}
              onSubmit={() => identifierInputRef.current?.focus()}
              error={errors.identifier}
            />
          );
        default:
          return null;
      }
  };

  return (
    <div id={styles.CustomerForm}>
      <div className={styles.container} >
        <IconButton className={styles.closeButton} onClick={onClose}><Icon>close</Icon></IconButton>
        <h1>{title}</h1>
        {handleCustomerData?.map((currentData) => renderInput(currentData))}
        <Button ref={submitButtonRef} size='large' variant='contained' onClick={onSubmitHandle}>Abrir Conta</Button>
      </div>
    </div>
  )
}

export default CustomerForm;