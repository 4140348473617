import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CatalogPageWithProvider } from '../pages/catalog/CatalogPageWithProvider'
import OrderPadPage from '../pages/_orderPad/OrderPadPage'
import TableMapPage from '../pages/TableMap/TableMapPage'
import SessionPage from '../pages/session/SessionPage'
import DeviceNavigatorSessionPage from '../pages/deviceNavigatorSession/DeviceNavigatorSessionPage'
import TransferTable from '../pages/transferTable/TransferTable'
import NofFoundDevicePage from '../pages/notFoundDevice/NofFoundDevicePage'
import { UseAuth } from '../modules/authentication/presentation/contexts/AuthenticationContext'
import LoadingAuth from '../pages/loadingAuth/LoadingAuth'
import DeviceNavigatorSessionIframePage from '../pages/deviceNavigatorSession/DeviceNavigatorSessionIframePage'
import DevPage from '../pages/DEV/DevPage'

export interface IRoutesProps {
  //propertys
}

const AppRoutes: FC<IRoutesProps> = () => {
  const { auth } = UseAuth();
  return (
    <Routes>
      {
        !auth ?
          <Route path={"/"} >
            {/* <Route path={"/"} element={<HomePage />} /> */}
            <Route path={"/navigator/:localId/:deviceId"} element={<DeviceNavigatorSessionPage />} />
            <Route path={"/iframe/:localId/:deviceId"} element={<DeviceNavigatorSessionIframePage />} />
            <Route path={"/notFoundDevice"} element={<NofFoundDevicePage />} />
            <Route path={"/*"} element={<LoadingAuth />} />
          </Route >
          :
          <Route path={"/"} >
            {/* <Route path={"/"} element={<HomePage />} /> */}
            {/*old*/}
            {/* <Route path={"/"} element={<OperatorAndOrderPadPage />} />
            <Route path={"/firstOpen"} element={<FirstOpenPage />} />
          <Route path={"/catalog/:operatorId/:tag/"} element={<CatalogPage />} /> */}
            {/*old*/}

            <Route path={"/navigator/:localId/:deviceId"} element={<DeviceNavigatorSessionPage />} />
            <Route path={"/iframe/:localId/:deviceId"} element={<DeviceNavigatorSessionIframePage />} />
            <Route path={"/session"} element={<SessionPage />} />
            <Route path={"/orderPad"} element={<OrderPadPage />} />
            <Route path={"/tableMap/"} element={<TableMapPage />} />
            <Route path={"/transferTable/"} element={<TransferTable />} />
            <Route path={"/catalog"} >
              <Route path={"/catalog/table/:tableId/:number"} element={<CatalogPageWithProvider />} />
              <Route path={"/catalog/table/:tableId/:number/open/:open"} element={<CatalogPageWithProvider />} />
              <Route path={"/catalog/orderPad/:tag"} element={<CatalogPageWithProvider />} />
              <Route path={"/catalog/ticket/"} element={<CatalogPageWithProvider ticket />} />
              <Route path={"/catalog/ticket/"} element={<CatalogPageWithProvider ticket />} />
            </Route>

            <Route path={"/notFoundDevice"} element={<NofFoundDevicePage />} />
            <Route path={"/*"} element={<NofFoundDevicePage />} />
          </Route>
      }
      {process.env.NODE_ENV === 'development' &&
        <Route path={"/dev"} element={<DevPage />} />
      }
    </Routes >
  )
}
export default AppRoutes