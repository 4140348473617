import { IDevice } from "../../../../device/interface/Device";
import { IOperator } from "../../../../operator/domains/models/Operator";
import { ISession } from "../../../../session/domain/ISession";
import { IConsumeService } from "../../../domain/interfaces/IConsumeService";
import { IExtract } from "../../../domain/models/Extract";



export const CloseAccountUseCase = async (service: IConsumeService, values: {
    session: ISession;
    operator: IOperator;
    extract: IExtract;
    device: IDevice;
}): Promise<void> => {
    const request = {
        currentSessionId: values.session.id,
        accountId: values.extract.accountId,
        operatorId: values.operator.id,
        localId: values.device.localId,
        equipamentId: values.device.id,
        operatorName: values.operator.name,
    };
    return await service.closeAccount(request);
};
