import QRCode from 'qrcode'
import React,{FC, useCallback} from 'react'
import UseDeviceStore from '../../../modules/device/store/DeviceStore';
import { IOrderLog } from '../../../modules/orderLog/domain/IOrderLog';
import UseSocketPrinter from '../../../modules/printer/presentation/hooks/UseSocketPrinter';
import InvoicePrinterLayout from '../../../modules/printer/presentation/layoutPrinter/invoice/InvoicePrinterLayout';
import OrderLogRepository from '../../../services/db/OrderRepository';
const UseOrderLog = () => {

    const [device, receiptPrinterSector] = UseDeviceStore(state => [state.device, state.receiptPrinterSector])

    const { sendBackgroundPrinter,  getElementHtml } = UseSocketPrinter();


    const getOrders = async (request: { page: number }) => {
        const repository = new OrderLogRepository()
        const orders = await repository.getOrdersPaginated({ page: request.page, pageSize: 10 })
        return orders;
    }

    const generateQR = async text => {
        return await QRCode.toDataURL(text, { width: 500 })
    }


    const printInvoice = useCallback(async (order: IOrderLog) => {
        try {
            if (!device) {
                throw new Error("Nenhum dispositivo conectado")
            }
            if (!receiptPrinterSector) {
                throw new Error("Nenhuma impressora configurada")
            }
            if (!!order.invoice?.length) {
                // const image = await htmlToBase64(<InvoicePrinterLayout invoice={order.invoice[0]} />)

                const qrCode = await generateQR(order.invoice[0].sefaz.key)
                const html = await getElementHtml(<InvoicePrinterLayout invoice={order.invoice[0]} qrCode={qrCode} />)
                console.log(html);
                await sendBackgroundPrinter(order.id, receiptPrinterSector?.printerName , html)
            }
        } catch (error: any) {
            throw new Error(error?.message ?? "Erro ao imprimir nota fiscal")
        }
    }, [device, getElementHtml, receiptPrinterSector, sendBackgroundPrinter])




    return ({getOrders,printInvoice})
}

export default UseOrderLog