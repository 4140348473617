import { IDevice } from "../../../../device/interface/Device"
import { IOperator } from "../../../../operator/domains/models/Operator"
import { TransferAccountsRequest } from "../../../domain/dtos/TransferAccountRequest"
import { IConsumeService } from "../../../domain/interfaces/IConsumeService"
import { ITable } from "../../../domain/models/ITables"

const TransferTableUseCase = async (service: IConsumeService, device: IDevice, operator: IOperator, from: ITable[], to: ITable) => {


    // var fromAccounts = from.map(item => {
    //     return service.getExtract(device.localId, item.id)
    // })
    // var toAccounts = service.getExtract(device.localId, to.id)

    // var fromAccountsResponse = await Promise.all(fromAccounts);
    // var toAccountsResponse = await toAccounts;



    const request: TransferAccountsRequest = {
        localId: device.localId,
        fromAccountId: [],
        fromCardId: from.map(item => item.id),
        // toAccountId: toAccountsResponse.accountId,
        toCardId: to.id,
        operatorId: operator.id,
        equipamentId: device.id
    }

    const reponse = await service.transferAccount(request);
    return (reponse)
}
export default TransferTableUseCase