import { create } from "zustand";
import { IOperator } from "../domains/models/Operator";
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware'
import EncryptedStorage from "../../../services/localStorage/encryptedStorage/EncryptedStorage";
import { OperatorPermissions } from "../domains/models/Permissions";
import { GetOperatorsUseCase } from "../application/useCases/GetOperatorsUseCase";
import { IOperatorService } from "../domains/interfaces/IOperatorService";

interface IOperatorStore {
    currentOperator?: IOperator;
    operators?: IOperator[];
    isLoadingOperators: boolean;
    isLoadingLoginOperator: boolean;
    hasPermission: (permission: OperatorPermissions) => boolean;
    setCurrentOperator: (operator?: IOperator) => void;
    clearCurrentOperator: () => void;
    setOperators: (operators?: IOperator[]) => void;
    getOperators: (service: IOperatorService, localId: string) => Promise<IOperator[]>;
    getOperatorByPassword: (password: string) => IOperator | undefined;
    loginOperator: (operatorSevice: IOperatorService, localId: string, password: string) => Promise<IOperator>;
    logoutOperator: () => Promise<void>;
    clearOperators: () => void;
    loginOperatorById: (operatorSevice: IOperatorService, localId: string, operatorId: string) => Promise<IOperator>;
}

const useOperatorStore = create<IOperatorStore>()(
    persist(
        (set, get) => ({
            currentOperator: undefined,
            setCurrentOperator: (currentOperator?: IOperator) => set(store => {
                console.log('setCurrentOperator', currentOperator)
                return ({ ...store, currentOperator })
            }),
            isLoadingOperators: false,
            isLoadingLoginOperator: false,
            clearCurrentOperator: () => set((store) => ({ ...store, currentOperator: undefined })),
            clearOperators: () => set((store) => ({ ...store, operators: undefined })),
            operators: undefined,
            hasPermission: (permission: OperatorPermissions) => {
                const currentOperator = get().currentOperator;
                if (!currentOperator) {
                    return false;
                }
                return currentOperator.papers.includes(permission);
            },
            setOperators: (operators?: IOperator[]) => set((store) => ({ ...store, operators })),
            getOperators: async (service: IOperatorService, localId: string) => {
                try {
                    set({ isLoadingOperators: true });
                    const response = await GetOperatorsUseCase(service, localId);
                    set({ operators: response });
                    return response;
                } finally {
                    set({ isLoadingOperators: false });
                }
            },
            getOperatorByPassword: (password: string) => {
                const operators = get().operators;
                if (operators?.length) {
                    const operator = operators.find((operator) => operator.password === password);
                    return operator;
                } else {
                    return undefined;
                }
            },
            loginOperator: async (operatorSevice: IOperatorService, localId: string, password: string) => {
                try {
                    set({ isLoadingLoginOperator: true });
                    var operator = get().getOperatorByPassword(password);
                    if (operator) {
                        get().setCurrentOperator(operator);
                        return operator;
                    }
                    await get().getOperators(operatorSevice, localId);
                    operator = get().getOperatorByPassword(password);
                    if (operator) {
                        get().setCurrentOperator(operator);
                        return operator;
                    } else {
                        throw new Error('Operador não encontrado');
                    }
                } finally {
                    set({ isLoadingLoginOperator: false })
                }
            },
            loginOperatorById: async (operatorSevice: IOperatorService, localId: string, operatorId: string) => {
                try {
                    set({ isLoadingLoginOperator: true });
                    const operators = await get().getOperators(operatorSevice, localId);
                    const operator = operators.find((operator) => operator.id === operatorId);
                    if (operator) {
                        set({ currentOperator: operator });
                        return operator;
                    } else {
                        throw new Error('Operador não encontrado');
                    }
                } finally {
                    set({ isLoadingLoginOperator: false })
                }
            },
            logoutOperator: async () => {
                get().setCurrentOperator(undefined);
            }
        }),
        {
            name: "operatorStore", // Name of the store for persistence
            storage: createJSONStorage<StateStorage>(EncryptedStorage), // Storage mechanism (e.g., localStorage)
        }
    )
);

export default useOperatorStore;