import React, { FC, PropsWithChildren, PropsWithRef, forwardRef, useCallback, useImperativeHandle } from 'react'
import styles from './DrawerOrderLog.module.scss'
import { Drawer } from '@mui/material';
import OrderLogFragment from '../../OrderLog';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import ModalLayout from '../../../../components/ui/modalLayout/ModalLayout';


export interface DrawerQueryRef {
    open: () => void;
    close: () => void;
}

export interface IDrawerOrderLogProps extends PropsWithChildren {
    //propertys
    title: string
    queryParam: string
    ref?: React.Ref<DrawerQueryRef>
}
const DrawerQuery: FC<PropsWithRef<IDrawerOrderLogProps>> = forwardRef(({ children, queryParam, title }, ref) => {


    const query = new URLSearchParams(window.location.search);
    const navigator = useNavigate();
    const { search } = useLocation();
    const openOrderDrawer = query.get(queryParam);


    React.useEffect(() => {
        if (openOrderDrawer) {
            console.log(queryParam, openOrderDrawer);
        }
    }, [openOrderDrawer, queryParam]);

    const close = useCallback(() => {
        const params = qs.parse(search, { ignoreQueryPrefix: true });
        delete params[queryParam];
        navigator({ search: qs.stringify(params) });
    }, [navigator, queryParam, search])
    const open = useCallback(() => {
        const params = qs.parse(search, { ignoreQueryPrefix: true });
        params[queryParam] = 'true';
        navigator({ search: qs.stringify(params) });
    }, [navigator, queryParam, search])

    useImperativeHandle(ref, () => ({
        open,
        close
    }), [close, open]);


    return (
        <Drawer anchor='right' id={styles.DrawerOrderLog} open={openOrderDrawer === 'true'} onClose={close}>
            <ModalLayout title={title} onClose={close} >
                {children}
            </ModalLayout>
        </Drawer>
    )
})
export default DrawerQuery