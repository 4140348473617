import {v4} from 'uuid';
import { IOrderItem } from '../../../../cart/domain/models/IOrderItem';
import {ConsumeOrderRequest} from '../../../domain/dtos/ConsumeOrderRequest';
import {IConsumeService} from '../../../domain/interfaces/IConsumeService';
import ParseCartOrderItemsToConsumeOrderRequest from './ParseCartOrderItemsToConsumeOrderUseCase';

const SendConsumeOrderUseCase = async (
  ConsputionApi: IConsumeService,
  localId: string,
  deviceId: string,
  orderPadId: string,
  accountId: string,
  orderItems: IOrderItem[],
  number: string,
  orderId: string = v4(),
  operatorId: string,
) => {
  const request: ConsumeOrderRequest = ParseCartOrderItemsToConsumeOrderRequest(
    localId,
    orderItems,
    accountId,
    orderPadId,
    deviceId,
    "", //TODO: reverorderNumber
    orderId,
    operatorId,
  );
  const response = await ConsputionApi.sendOrder(request);
  return request;
};

export default SendConsumeOrderUseCase;
