
import React, { FC, useEffect, useState } from 'react'
import { IProductComposition } from '../../../../../../modules/catalog/domain/models/IProductComposition'
import { useComposition } from '../../context/CompositionContext'
import BoxProductCompositionItem from '../boxProductCompositionItem/BoxProductCompositionItem'
import styles from './BoxProductComposition.module.scss'
export interface IBoxProductCompositionProps {
    //propertys
    productComposition: IProductComposition
}
const BoxProductComposition: FC<IBoxProductCompositionProps> = ({ productComposition }) => {

    const { orderCompositions, getTotalsComposition } = useComposition();

    const [disabledAllAdd, setDisabledAllAdd] = useState(false);

    const [total, setTotal] = useState(0);
    useEffect(() => {
        const totalComposition = getTotalsComposition(productComposition);
        const maxComposition = !!productComposition.max && (totalComposition.quantity >= productComposition.max);
        setTotal(totalComposition.quantity);
        if (maxComposition) {
            setDisabledAllAdd(true)
        } else {
            setDisabledAllAdd(false)
        }
    }, [getTotalsComposition, orderCompositions, productComposition])

    return (
        <div id={styles.BoxProductComposition} >
            <div className={styles.container} >
                <div className={styles.description}>
                    <h3> {productComposition.description}</h3>
                    {!!productComposition.min &&
                        (

                            <div className={productComposition.min - total > 0 ? styles.obrigatorio : styles.obrigatorioChecked}>Obrigatorio</div>
                            // {productComposition.min - total <= 0}
                            // <div className={styles.obrigatorio}><Icon>checked</Icon></div>
                        )
                    }
                </div>
                <div className={styles.compositionItems}>
                    {
                        productComposition.compositionItems.map((productCompositionItem) =>
                            <BoxProductCompositionItem
                                disabledAllAdd={disabledAllAdd}
                                productCompositionItem={productCompositionItem}
                                productComposition={productComposition}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default BoxProductComposition