import axios from "axios";
import { token } from "../../../modules/authentication/stores/AuthStore";
import { BASE_URL } from "./Constants";

// const BASE_URL = 'https://device-api.meep.cloud';
// const BASE_URL = process.env.REACT_APP_API_URL;
// export const DEVICE_API = process.env.REACT_APP_API_URL_DEVICE_CONFIG;
// export const DEVICE_API = 'http://localhost:50499';
// const BASE_URL = 'http://192.168.0.111:5000/Promptus.Meep.Server';
// const BASE_URL = 'http://localhostx/Promptus.Meep.Server';


// // //Local
// const BASE_URL = 'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = 'http://localhost:50499';
// export const PLANS_API = 'https://dev-plans-api.meep.cloud';
// export const PORTAL_URL = 'http://localhost:3000';

//dev
// const BASE_URL = 'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = 'https://app-dev-device-configuration-api.azurewebsites.net';
// export const PLANS_API = 'https://dev-plans-api.meep.cloud';
// export const PORTAL_URL = 'http://portal-dev.meep.cloud';

//prod
// const BASE_URL = 'https://portal-api.meep.cloud';
// export const DEVICE_API = 'https://device-configuration-api.meep.cloud';
// export const PLANS_API = 'https://plans-api.meep.cloud';
// export const PORTAL_URL = 'http://connect.meep.com.br';


// //LOCAL
// const BASE_URL = 'https://portal-api-dev.meep.cloud';
// export const DEVICE_API = 'https://app-dev-device-configuration-api.azurewebsites.net';


const api = axios.create({
  baseURL: BASE_URL + '/api',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});



export const Api = () => {
  return api;
};

export const LoginApi = axios.create({
  baseURL: BASE_URL + '/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    
  },
});
export const ApiLogin = () => {
  return LoginApi;
};
