import { IDeviceService } from '../domain/interfaces/IDeviceService';
import { IDevice } from '../interface/Device';
import { IDeviceProfile } from '../interface/DeviceProfile';

const GetDeviceProfileByDeviceIdUseCase = async (
  deviceService: IDeviceService,
  device: IDevice
): Promise<IDeviceProfile> => {
  const response = await deviceService.getDeviceProfile(device.id, device.localId);
  return { ...response };
};

export default GetDeviceProfileByDeviceIdUseCase;


