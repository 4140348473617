import React, { FC, useState } from 'react'
import styles from './Image.module.scss'
export interface IImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    //propertys
    hideImage?: boolean
}
const Image: FC<IImageProps> = (props) => {
    const [_src, setSrc] = useState(props.src);
    const [hideImage, setHideImage] = useState(props.hideImage);
    const [error, setError] = useState(false);
    return (
        error ? <div  {...props} className={`${props.className} ${styles.noImage}`} style={{ width: props.width, height: props.height }}  ></div> : <img {...props} id={styles.Image} src={_src} alt={''} onError={() => setError(true)} />
    )
}
export default Image