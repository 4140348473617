import React, { FC } from 'react';
import styles from './PaymentOrderResume.module.scss';
import { IExtract } from '../../../../../modules/consume/domain/models/Extract';
import { moneyFormater } from '../../../../../services/utils/Money';
import { IOrder } from '../../../../../modules/cart/domain/models/IOrder';
import { IOrderItem } from '../../../../../modules/cart/domain/models/IOrderItem';

interface IPaymentOrderResumeProps {
    orderItems: IOrderItem[]; totalToPay: number;
}
export const PaymentOrderResume: FC<IPaymentOrderResumeProps> = ({ orderItems, totalToPay }) => {
    return <div className={styles.orderResume}>
        <b>Resumo do pedido</b>

        {
            orderItems.map((item, index) => {
                return <div key={index} className={styles.total}>
                    <label>{item.quantity} - {item.name} ({moneyFormater(item.price)}):</label><span>{moneyFormater(item.price * item.quantity)}</span>
                </div>
            })
        }


        <div className={styles.partial}>
            <div className={styles.value}>
                <label>Valor a ser pago:</label><span>{moneyFormater(totalToPay)}</span>
            </div>
        </div>
    </div>;
};
