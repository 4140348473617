import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './CatalogV2.module.scss'
import { v4 } from 'uuid';
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition';
import { useCatalog } from '../../../../modules/catalog/context/CatalogContext';
import { IProduct } from '../../../../modules/catalog/domain/models/IProduct';
import { IProductCategory } from '../../../../modules/catalog/domain/models/IProductCategory';
import { IStore } from '../../../../modules/catalog/domain/models/IStore';
import CategoryListTab from '../categoryListTab/CategoryListTab';
import SearchInput, { ISearchInputRef } from '../../../../components/searchInput/SearchInput';
import { useCartStore } from '../../../../modules/cart/infra/store/CartStore';
import { Category } from './category/Category';
import UseDeviceStore from '../../../../modules/device/store/DeviceStore';
import { ProductType } from '../../../../modules/catalog/domain/models/ProductType';

export interface ICatalogV2Props {
    //propertys
    isMobile?: boolean;
    callTutorial?: (divRef: React.RefObject<HTMLDivElement>) => void;
    disabledCart?: boolean;
}
const CatalogV2: FC<ICatalogV2Props> = ({ isMobile, callTutorial, disabledCart }) => {
    const { catalog } = useCatalog();

    const { addProductCart } = useCartStore();

    const [selectedStore, setSelectedStore] = useState<IStore>();
    const [filteredStore, setFilteredStore] = useState<IStore>();

    // const [selectedCategory, setselectedCategory] = useState<IProductCategory>();
    const [featured, setFeatured] = useState<IProductCategory>();
    const [lastVisibleCategory, setLastVisibleCategory] = useState<IProductCategory>();
    const [disabledSound] = UseDeviceStore(state => [state.disbledSound]);

    const searchInputRef = useRef<ISearchInputRef>(null);


    const refDiv = useMemo(() => filteredStore?.seccionedProduct.map(() => React.createRef<HTMLDivElement>()), [filteredStore?.seccionedProduct]);
    // const navigate = useNavigate();

    useEffect(() => {
        if (catalog?.stores.length) {
            setSelectedStore(catalog.stores[0])
            setFilteredStore(catalog.stores[0])
        }
    }, [catalog]);

    // useEffect(() => {
    //     if (selectedStore?.seccionedProduct.length) {
    //         setselectedCategory(selectedStore.seccionedProduct[0])
    //     }
    // }, [selectedStore]);



    useEffect(() => {
        const productsFeatured = filteredStore?.seccionedProduct
            .flatMap(category => category.products)
            .filter((product) => product.isFeatured);
        if (productsFeatured?.length) {
            const _featured: IProductCategory = {
                id: v4(),
                title: "Mais Vendidos",
                imageUrl: "",
                products: productsFeatured ?? [],
            }
            setFeatured(_featured)
        }

    }, [filteredStore])




    // const onClickStoreHandle = useCallback((store: IStore) => {
    //     setSelectedStore(store)
    // }, [])

    useEffect(() => {
        if (refDiv?.length && refDiv?.[0] && refDiv[0].current && callTutorial) {
            callTutorial?.(refDiv[0]);
        }
    }, [callTutorial, refDiv])

    const onClickCategoryHandle = useCallback((id: string) => {
        const index = filteredStore?.seccionedProduct.findIndex(i => i.id === id) ?? null;
        if (index !== null) {
            refDiv?.[index]?.current?.scrollIntoView({ behavior: 'smooth', });
            console.log("ref: " + refDiv?.[index]?.current)
        }
    }, [refDiv, filteredStore?.seccionedProduct])



    const scrollInput = useCallback((ref: React.RefObject<HTMLInputElement | HTMLDivElement>, block: 'start' | 'center' = 'center') => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: block });
    }, [])


    const onClickAddProduct = useCallback((product: IProduct, quantity: number = 1, composition?: IOrderComposition[], notes?: string) => {
        !disabledSound && new Audio('/assets/sounds/beep.mp3').play();
        addProductCart(product, quantity, composition, notes);
    }, [addProductCart, disabledSound]);


    const categories = useMemo(() => <div className={styles.categoriesContainer} >
        {filteredStore?.seccionedProduct?.map((category, index) => <div ref={refDiv?.[index]}>
            <Category
                category={category}
                onClickAddProduct={onClickAddProduct}
                onVisible={() => setLastVisibleCategory(category)}
                onClickInputObservation={scrollInput}
                disabledCart={disabledCart}
            />
        </div>
        )}
    </div>, [filteredStore?.seccionedProduct, refDiv, onClickAddProduct, scrollInput, disabledCart]);


    const compareString = useCallback((a: string, b: string) => {
        const normalizeString = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return normalizeString(a).includes(normalizeString(b));
    }, [])



    const onsSubmitSearch = useCallback((value: string) => {
        if (selectedStore) {
            let byBarCode = false;
            const _filteredProducts = selectedStore?.seccionedProduct
                .flatMap(category => category.products)
                .filter(products => {
                    const findByName = compareString(products.name, value);
                    if (findByName) return true;
                    let findByBarCode = products.barCode?.toLowerCase() === value.toLowerCase();
                    const barcodeData = decodeBarcode(value);
                    if (barcodeData.productCode) {
                        findByBarCode = products.barCode?.toLowerCase() === barcodeData.productCode.toLowerCase();
                    }

                    if (findByBarCode) {
                        byBarCode = !!findByBarCode;
                    }
                    return findByBarCode || findByName;
                });
            const filtered: IProductCategory = {
                id: v4(),
                title: "Resultado da Pesquisa",
                imageUrl: "",
                products: _filteredProducts ?? [],
            }
            if (_filteredProducts && _filteredProducts.length === 1 && _filteredProducts[0].compositions?.length === 0 && byBarCode) {
                const isKilo = _filteredProducts[0].type === ProductType.Kilo;
                const isVariable = _filteredProducts[0].type === ProductType.Variavel;

                let barCodeData = decodeBarcode(value, isKilo);
                if (isKilo && barCodeData.weight) {
                    const quantity = barCodeData.weight ?? 1;
                    onClickAddProduct({
                        ..._filteredProducts[0],
                    }, quantity)
                    setFilteredStore(selectedStore);
                    searchInputRef.current?.clear();
                    searchInputRef.current?.blur();
                    return;
                }

                if (isVariable && barCodeData.price) {
                    const price = barCodeData.price ?? _filteredProducts[0].price ?? 0;
                    onClickAddProduct({
                        ..._filteredProducts[0],
                        price,
                    }, 1)
                    setFilteredStore(selectedStore);
                    searchInputRef.current?.clear();
                    searchInputRef.current?.blur();
                    return;
                }

                if (barCodeData.keyword) {
                    onClickAddProduct({
                        ..._filteredProducts[0],
                    }, 1)
                    setFilteredStore(selectedStore);
                    searchInputRef.current?.clear();
                    searchInputRef.current?.blur();
                    return;
                }
            }
            setFilteredStore(({ ...selectedStore, seccionedProduct: [filtered] }))
            searchInputRef.current?.focus();

        }
    }, [compareString, onClickAddProduct, selectedStore])


    const resetFilter = useCallback(() => {
        setFilteredStore(selectedStore)
    }, [selectedStore])


    return (
        <div id={styles.CatalogV2} style={{ flexDirection: isMobile ? "column" : "row" }} >
            {!isMobile &&
                <div className={styles.categoriesListContainer}>
                    <div className={styles.searchContainer}>
                        <SearchInput ref={searchInputRef} onSubmit={onsSubmitSearch} reset={resetFilter} />
                    </div>
                    {filteredStore &&
                        <CategoryListTab
                            vertical={true}
                            categories={filteredStore?.seccionedProduct}
                            // defaultValue={lastVisibleCategory?.id}
                            onChangeCategory={onClickCategoryHandle}
                        />
                    }
                </div>}
            {isMobile &&
                <div className={styles.categoriesListContainer}>
                    <div className={styles.searchContainer}>
                        <SearchInput ref={searchInputRef} onSubmit={onsSubmitSearch} reset={resetFilter} />
                    </div>
                    {filteredStore &&
                        <CategoryListTab
                            vertical={false}
                            categories={filteredStore?.seccionedProduct}
                            // defaultValue={lastVisibleCategory?.id}
                            onChangeCategory={onClickCategoryHandle}
                        />
                    }
                </div>}

            {
                categories
            }

            {/* <Drawer open={!!openProduct} anchor={'right'} onClose={() => setOpenProduct(undefined)}> */}
            {/* <Dialog open={!!openProduct} onClose={() => setOpenProduct(false)}>
                {selectedProduct &&
                  
                }
            </Dialog> */}
            {/* </Drawer> */}
        </div>

    )
}
export default CatalogV2




interface BarcodeData {
    productCode?: string;
    keyword?: string;
    weight?: number;
    price?: number;
}

function decodeBarcode(keyword: string, isWeight?: boolean): BarcodeData {
    if (keyword.length < 13)
        return { keyword };

    const prefix = keyword.substring(0, 1);
    // Se o código começa com "2", supomos que é um produto por peso
    if (prefix === "2") {
        if (isWeight) {
            const productId = keyword.substring(1, 7);  // Identificação do produto
            const weight = parseInt(keyword.substring(7, 12)) / 1000; // Peso em gramas (convertido para kg)
            return {
                productCode: productId,
                weight
            };
        } else {
            const productId = keyword.substring(1, 7);  // Identificação do produto
            const price = parseInt(keyword.substring(7, 12)) / 100; // Preço em centavos (convertido para valor decimal)
            return {
                productCode: productId,
                price
            };
        }
    }
    return {
        keyword
    };
}

// Exemplo de uso
const barcode = "2123456543215";  // Suponha que este seja um código de barras de um produto por peso
const decoded = decodeBarcode(barcode);
console.log(decoded);



